import axios from 'axios';
export async function listReports(startDate) {
    try {
        const { data } = await axios.get('/api/report/attribute', { params: { startDate: startDate } });
        return data;
    } catch (e) {
        const defaultErrMsg = 'There was an error getting the attribute reports.';
        const { response } = e;
        if (response) {
            const { status, data } = response;
            switch (status) {
                case 403:
                    throw 'Something went wrong! Please check your access.';
                default:
                    throw data;
            }
        } else {
            throw defaultErrMsg;
        }
    }
}
