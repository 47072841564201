/**
 * RouterUtils - Utilities for operating on vue-router objects.
 *
 * Objects should be passed as parameters so that this file can
 * be used from router.js, possibly before the router instance
 * is created.
 */

/**
 * Determines whether a route requires authentication.
 */
export function requiresAuth(route) {
    let requiresAuth = true; // more routes require authentication so make this the default
    const filtered = route.matched.filter((route) => {
        return route?.meta ? Object.prototype.hasOwnProperty.call(route.meta, 'requiresAuth') : false;
    });
    if (filtered.length > 0) {
        // The routes are matched parent then children so find the last route
        // with a requiresAuth property and consider that to override the parent
        // or default.
        requiresAuth = filtered[filtered.length - 1].meta.requiresAuth;
    }
    return requiresAuth;
}
