import { createApp } from 'vue';

const TmsModalPlugin = {
    install(app) {
        app.mixin({
            methods: {
                async tmsShowModal(ModalComponent, params) {
                    const modalApp = createApp(ModalComponent, params);

                    Object.assign(modalApp._context, app._context);

                    const response = await modalApp.mount('#modal-root').show();
                    modalApp.unmount();
                    return response;
                },
            },
        });
    },
};

export default TmsModalPlugin;
