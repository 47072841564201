<template>
    <div>
        <div id="footer">
            <ul class="footer-nav text-center">
                <li>&copy; {{ year }} T-Mobile. All rights reserved.</li>
                <li>
                    <a href="http://www.pushspring.com/termsofservice.html">Terms of Service</a>
                </li>
                <li>
                    <a href="https://www.t-mobile.com/privacy-center/privacy-notices/t-mobile-privacy-notice"
                        >Privacy Policy</a
                    >
                </li>
                <li>
                    <a href="https://www.t-mobile.com/advertising-solutions/opt-out">Opt Out</a>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'TmsSimpleFooter',
    data() {
        return {};
    },
    computed: {
        year() {
            return new Date().getFullYear();
        },
    },
});
</script>

<style lang="scss" scoped src="./TmsSimpleFooter.scss" />
