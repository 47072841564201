<template>
    <TmsDefaultModalLayout id="new-import-modal" :cancel-modal="cancelModal">
        <template #header>
            <h4 class="modal-title">New Import</h4>
        </template>
        <form name="importForm" role="form">
            <div class="form-group">
                <label>Path</label>
                <input
                    id="path"
                    v-model="log.file_path"
                    v-tms-focus
                    class="form-control"
                    placeholder="S3 path of file to import"
                    required
                />
            </div>
            <div class="form-group">
                <label>Key Type</label>
                <TmsDropdown v-model="log.key_type" :options="keyTypes" :title="safeDropDownTitle(log.key_type)" />
            </div>
            <div class="form-group">
                <label>Compression</label>
                <TmsCheckbox id="compressed" v-model="log.compressed" label="Gzip compressed files" />
            </div>
        </form>
        <template #footer>
            <TmsButton class="primary" text="Ok" :disabled="v$.$invalid" @click="addImport(log)" />
            <TmsButton class="tertiary" text="Cancel" @click="cancelModal()" />
        </template>
    </TmsDefaultModalLayout>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { defineComponent } from 'vue';

import { PartnerIngestion } from '@pushspring/ps-common/lib/constants-public';
import { TmsDefaultModalLayout, TmsModalMixin, TmsFlashMessageUtils } from '@pushspring/tms-client-common';

import { createImport } from '../utils/imports';

export default defineComponent({
    components: { TmsDefaultModalLayout },
    mixins: [TmsModalMixin],
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            log: {
                file_path: '',
                key_type: [],
                compressed: false,
            },
        };
    },
    computed: {
        //map keyType Options
        keyTypes() {
            return Object.keys(PartnerIngestion.KeyType).map((key, index) => {
                return {
                    id: index,
                    title: key,
                };
            });
        },
    },
    validations() {
        return {
            log: {
                file_path: {
                    required,
                },
            },
        };
    },
    methods: {
        //cancel button click
        cancelModal() {
            this.close();
        },
        //set dropdown title
        safeDropDownTitle(selection) {
            return selection[0]?.title || 'Select Key Type';
        },
        //add import
        async addImport(log) {
            TmsFlashMessageUtils.clear();
            try {
                const createObject = {
                    compressed: log.compressed,
                    file_path: log.file_path,
                    key_type: log.key_type[0].id,
                };
                if (await createImport(createObject)) {
                    this.close({
                        status: 'success',
                    });
                }
            } catch (e) {
                TmsFlashMessageUtils.replace('error', e);
            } finally {
                this.close();
            }
        },
    },
});
</script>
<style lang="scss" scoped>
#new-import-modal {
    .form-group {
        margin-bottom: 15px;
        label {
            color: $black;
            font-weight: $font-weight-regular;
            margin-bottom: 9px;
        }
        .tms-checkbox {
            line-height: 28px;
        }
    }
}
</style>
