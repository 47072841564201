<template>
    <div class="reports">
        <div class="page-header-controls">
            <span class="flex-grow-1" />
            <!-- Dropdown -->
            <TmsDropdown
                v-model="dropDownSelection"
                :options="dropDownOptions"
                :disabled="isApiKeyError"
                :title="safeDropdownTitle(dropDownSelection)"
                @change="dropDownChange(dropDownSelection[0].key)"
            />
            <!-- Export Reports-->
            <TmsButton class="teritary" type="submit" :disabled="!isResults || isApiKeyError" @click="exportCSV">
                Export</TmsButton
            >
        </div>

        <!-- Used when has loading state -->
        <div v-if="status.reportsLoading" v-tms-spinner="{ width: 3, scale: 2 }" />
        <!-- Report List -->
        <div v-else-if="isResults && !status.reportsLoading">
            <AudienceList :reports="reports" :col-settings="colSettings" @sort-changed="columnChange" />
        </div>

        <!-- Else No Reports-->
        <div v-else>
            <h6>No reports found!</h6>
        </div>
    </div>
</template>
<script>
import csvLine from 'csv-line';
import { default as dayjs } from 'dayjs';
import { default as relativeTime } from 'dayjs/plugin/relativeTime';
import { defineComponent } from 'vue';

import { TmsFlashMessageUtils, downloadFile } from '@pushspring/tms-client-common';

import { getApiKey } from '../../manage/utils/apikeys';
import AudienceList from '../components/AudienceList.vue';
import { listReports } from '../utils/audience';

dayjs.extend(relativeTime);

const dropDownOptions = [
    { id: 0, key: 'week', title: 'Created: Last 7 Days' },
    { id: 1, key: '24hours', title: 'Created: Last 24 Hours' },
];

export default defineComponent({
    name: 'AudiencePage',
    components: { AudienceList },

    data() {
        return {
            reports: [],
            apiKey: '',
            dropDownOptions,
            startDate: '',
            originalReports: [],
            dropDownSelection: [],
            dropDownSelected: '',
            status: {
                reportsLoading: false,
            },
            colSettings: { column: 'name', order: 'asc' },
        };
    },
    computed: {
        //check if show results
        isResults() {
            return this.reports.length > 0;
        },
        //check if apikey has error
        isApiKeyError() {
            return this.apiKey === 'error';
        },
    },
    async created() {
        //check key for accessing api
        try {
            TmsFlashMessageUtils.clear();
            await getApiKey();
        } catch (e) {
            this.apiKey = 'error';
            TmsFlashMessageUtils.replace('error', 'There was an error accessing an api key.');
            return;
        }
        //when we load the page then set query params
        this.startDate = this.$route.query?.startDate
            ? this.$route.query?.startDate
            : this.startDate.replace(/\s/g, '');

        //otherwise set query params to default
        if (Object.keys(this.$route.query).length === 0) {
            this.$router.push({
                query: {
                    startDate: 'week',
                },
            });
            this.dropDownSelected = 'Created: Last 7 Days';
        } else {
            this.dropDownSelected = this.dropDownOptions.find((option) => {
                return option.key === this.startDate;
            }).title;
        }
        //load all reports
        this.getReports();
    },
    methods: {
        //dropdown change
        dropDownChange(startDate) {
            if (this.$route.query.startDate !== startDate) {
                this.$router.replace({ query: { startDate: startDate } });
            }
            //when dropdown change then get all reports
            this.getReports();
        },

        //get all reports
        async getReports() {
            this.status.reportsLoading = true;
            TmsFlashMessageUtils.clear();
            //set startDate
            this.startDate = this.$route.query?.startDate || this.startDate;
            //convert startDate
            switch (this.startDate) {
                case 'week':
                    this.startDate = dayjs().subtract(7, 'days');
                    break;

                case '24hours':
                    this.startDate = dayjs().subtract(24, 'hours');
                    break;

                default:
                    this.startDate = dayjs().subtract(7, 'days');
            }

            try {
                this.reports = await listReports(this.startDate);
                this.originalReports = this.reports;
            } catch (e) {
                TmsFlashMessageUtils.replace('error', e);
            } finally {
                this.status.reportsLoading = false;
            }
        },

        //set dropdown title
        safeDropdownTitle(selection) {
            return selection[0] ? selection[0]?.title : this.dropDownSelected || 'Created: Last 7 Days';
        },

        //filter results based on column settings
        filtered({ column, order }) {
            let filtered = this.reports;
            const compare = (a, b) => (a[column] ?? '')?.toString().localeCompare((b[column] ?? '')?.toString());
            if (order === 'desc') {
                filtered = filtered.sort((a, b) => compare(b, a));
            } else {
                filtered = filtered.sort(compare);
            }
            return filtered;
        },

        //if column order change then filter results
        columnChange(settings) {
            this.colSettings.order = settings.order;
            this.colSettings.column = settings.column;
            this.reports = this.filtered(this.colSettings);
        },

        //export CSV
        async exportCSV() {
            TmsFlashMessageUtils.clear();
            const csv = csvLine();
            let csvData =
                'Created At,Name,Organization,Device Count,Exported,Lookalike,Included Attributes,Contributing Partners\r\n';
            this.reports.forEach((line) => {
                csvData +=
                    csv.encode([
                        line.created_at,
                        line.name,
                        line.company_name,
                        line.device_count,
                        line.sent === true ? 'Yes' : 'No',
                        line.lookalike === true ? 'Yes' : 'No',
                        line.partnerAttributeNames.join(','),
                        line.contribution_name,
                    ]) + '\r\n';
            });
            const blob = new Blob([csvData], { type: 'text/csv' });
            downloadFile(blob, 'custom_audience_report.csv');
            TmsFlashMessageUtils.replace('success', 'Custom Audience csv being downloaded.');
        },
    },
});
</script>
<style lang="scss" scoped>
.reports {
    .tms-button {
        margin-left: 8px;
    }

    h6 {
        text-align: center;
        font-size: 22px;
        padding: 10px;
    }
    .dropdown {
        &:deep() {
            .dropdown-menu {
                right: 0;
                left: auto;
            }
        }
    }
}
</style>
