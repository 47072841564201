/**
 * All of the navigation links in the left nav layout.
 *
 * Some of the routes point to non-existent VueJS routes and will
 * be redirected back to the old site as a way of integrating the
 * two sites together. These will slowly go away as we migrate
 * pages over to VueJS.
 *
 * Add selectedRoutes and excludedRoutes where appropriate for multiple pages under
 * the same nav item.
 *
 * Partnertron specific properties:
 */

import type { NavLink } from '@pushspring/tms-client-common';

import { IconRegistry } from '@pushspring/common-ui/icons-core';
import { fthrClipboard, fthrSettings } from '@pushspring/common-ui/icons-feather';

IconRegistry.add(fthrClipboard, fthrSettings);

export const navLinks: NavLink[] = [
    {
        id: 'leftNavManage',
        title: 'Manage',
        route: '/manage',
        iconName: fthrSettings.name,
        children: [
            {
                id: 'leftNavAttributes',
                title: 'Attributes',
                route: '/manage/attributes',
            },
            {
                id: 'leftNavImports',
                title: 'Imports',
                route: '/manage/import',
            },
            {
                id: 'leftNavSharing',
                title: 'Sharing',
                route: '/manage/sharing',
            },
        ],
    },
    {
        id: 'leftNavReport',
        title: 'Report',
        route: '/report',
        iconName: fthrClipboard.name,
        children: [
            {
                id: 'leftNavCustomAudiences',
                title: 'Custom Audiences',
                route: '/report/audience',
            },
            {
                id: 'leftNavAttributes',
                title: 'Attributes',
                route: '/report/attribute',
            },
            {
                id: 'leftNavHistorical',
                title: 'Historical',
                route: '/report/historical',
            },
        ],
    },
];
