import { AuthStorage } from '../AuthStorageService';

import { _CLEAR_ALL, _SET_ALL } from './actionTypes';
import { _FULL_NAME, _TOKEN, _TOKEN_EXPIRATION, _USER_PROFILE } from './getterTypes';
import {
    _CLEAR_TOKEN,
    _CLEAR_TOKEN_EXPIRATION,
    _CLEAR_USER_PROFILE,
    _SET_TOKEN,
    _SET_TOKEN_EXPIRATION,
    _SET_USER_PROFILE,
} from './mutationTypes';

// type a _ by pressing option + o

const storage = new AuthStorage();
export default {
    namespaced: true,
    state: {
        profile: null,
        token: null,
        tokenExpiration: null,
    },
    getters: {
        [_USER_PROFILE]({ profile }) {
            return profile ?? storage?.userProfile;
        },
        [_TOKEN]({ token }) {
            return token ?? storage?.token;
        },
        [_TOKEN_EXPIRATION]({ tokenExpiration }) {
            return tokenExpiration ?? storage?.tokenExpiration;
        },
        [_FULL_NAME](_state, { [_USER_PROFILE]: userProfile }) {
            let fullName;
            if (userProfile?.first_name && userProfile?.last_name) {
                fullName = userProfile.first_name + ' ' + userProfile.last_name;
            } else {
                fullName = userProfile?.email;
            }
            return fullName;
        },
    },
    actions: {
        [_SET_ALL]({ commit }, { token, userProfile, tokenExpiration }) {
            commit(_SET_TOKEN, token);
            commit(_SET_USER_PROFILE, userProfile);
            commit(_SET_TOKEN_EXPIRATION, tokenExpiration);
        },
        [_CLEAR_ALL]({ commit }) {
            commit(_CLEAR_TOKEN);
            commit(_CLEAR_USER_PROFILE);
            commit(_CLEAR_TOKEN_EXPIRATION);
        },
    },

    mutations: {
        [_SET_USER_PROFILE](state, userProfile) {
            state.profile = userProfile;
            storage.userProfile = userProfile;
        },
        [_SET_TOKEN](state, token) {
            state.token = token;
            storage.token = token;
        },
        [_SET_TOKEN_EXPIRATION](state, tokenExpiration) {
            state.tokenExpiration = tokenExpiration;
            storage.tokenExpiration = tokenExpiration;
        },
        [_CLEAR_TOKEN](state) {
            state.token = undefined;
            storage.remove('token');
        },
        [_CLEAR_USER_PROFILE](state) {
            state.userProfile = undefined;
            storage.remove('userProfile');
        },
        [_CLEAR_TOKEN_EXPIRATION](state) {
            state.tokenExpiration = undefined;
            storage.remove('tokenExpiration');
        },
    },
};
