import { hasAccess } from '@pushspring/auth/utils';

import { USER_PROFILE } from './store/getterTypes';

export const authorizedRolesDirective = (app: any): void => {
    app.directive('authorized-roles', {
        bind: (el: HTMLElement, binding: any) => {
            const { value } = binding;
            const authorizedRoles: string[] = typeof value === 'string' ? [value] : value;
            if (authorizedRoles) {
                const { appRoles } = binding.instance.$store.getters[USER_PROFILE];
                if (!hasAccess(appRoles, authorizedRoles)) {
                    el.style.display = 'none';
                }
            }
        },
    });
};
