import type { Icons } from './icon-types';
import type { PropType } from 'vue';

import { computed, defineComponent, h } from 'vue';

import { IconRegistry } from './icon-registry';

// type Size = 'Small' | 'Medium' | 'Large';
// const sizeMap: Record<Size, string> = {
//     Small: '16px',
//     Medium: '24px',
//     Large: '32px',
// };

interface Props {
    name: Icons;
    title?: string;
    size?: string;
}

export const TmsIcon = defineComponent({
    name: 'TmsIcon',
    inheritAttrs: false,
    props: {
        name: {
            type: String as PropType<Icons>,
            required: true,
        },
        title: {
            type: String,
            default: undefined,
        },
        /** Deprecated added for ease of migration */
        size: {
            type: String,
            default: undefined,
        },
    },
    setup(props: Props, { attrs }) {
        const svg = computed((): SVGElement | undefined => {
            const svgString = IconRegistry.get(props.name);
            if (svgString) {
                const svgEl = getSvgElFromString(svgString);
                if (props.title) {
                    setTitle(svgEl, props.title);
                }
                return svgEl;
            } else {
                return undefined;
            }
        });

        const extraAttrs: Record<string, unknown> = {};
        if (props.size) {
            extraAttrs.style = getSizeStyle(props.size);
        }

        return () =>
            // could potentially use TSX
            h('svg', {
                // attributes that were part of the original svg tag
                ...getSvgAttrs(svg.value),
                // attributes added to tms-icon component
                ...attrs,
                // special props these will override any attributes that were part of the original svg tag
                ...extraAttrs,
                // since we have the title prop the attribute does not fall through so add it explicitly
                title: props.title,
                // svg content
                innerHTML: svg.value?.innerHTML,
            });
    },
});

/**
 * Create or edit the `<title>` element of an SVG
 */
function setTitle(svg: SVGElement, title: string) {
    const titleTags = svg.getElementsByTagName('title');
    if (titleTags.length) {
        titleTags[0].textContent = title;
    } else {
        const titleEl = document.createElementNS('http://www.w3.org/2000/svg', 'title');
        titleEl.textContent = title;
        svg.insertBefore(titleEl, svg.firstChild);
    }
}

function getSizeStyle(size: string) {
    return { width: size, height: size };
}

/**
 * Creates an SVGElement from a string containing the raw svg text
 */
function getSvgElFromString(svgString: string): SVGElement {
    const parser = new DOMParser();
    const doc = parser.parseFromString(svgString, 'image/svg+xml');
    const svgEl = doc.getElementsByTagName('svg')[0];
    return svgEl;
}

/**
 *  Converts the attributes of an SVGElement to an object
 */
function getSvgAttrs(svgEl?: SVGElement): Record<string, unknown> {
    const svgAttrs: Record<string, unknown> = {};
    const attrs = svgEl?.attributes;
    if (!attrs) {
        return svgAttrs;
    } else {
        for (let i = attrs.length - 1; i >= 0; i--) {
            svgAttrs[attrs[i].name] = attrs[i].value;
        }
        return svgAttrs;
    }
}
