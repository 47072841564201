<template>
    <div class="tms-toggle-switch switch" :class="$attrs.class">
        <input v-bind="$attrs" :id="id" v-model="proxyChecked" class="slider" type="checkbox" />
        <label v-if="$slots.default" class="switch-label" :for="id"><slot /></label>
    </div>
</template>

<script>
let instanceCount = 0;
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'TmsToggleSwitch',
    inheritAttrs: false,
    props: {
        id: {
            type: String,
            default: function () {
                return `tms-toggle-switch-${++instanceCount}`;
            },
        },
        modelValue: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue', 'change'],
    computed: {
        proxyChecked: {
            get() {
                return this.modelValue;
            },
            set(val) {
                this.$emit('update:modelValue', val);
                // emit change for backwards compatibility
                this.$emit('change', val);
            },
        },
    },
});
</script>

<style lang="scss" scoped>
@use 'sass:math';
@import '../styles/design-language-variables';

// because the root element font size is still 16 but the standard font size
// is 14 and I want the switch to be able to scale using ems with the current
// font size and dont want really long decimals using this scale-ratio variable
$scale-ratio: math.div(16, 7);

$switch-width: 1.6875em * $scale-ratio;
$switch-height: 1em * $scale-ratio;
$switch-padding: 0.09375em * $scale-ratio;
$switch-slider-diameter: 0.8125em * $scale-ratio;

$switch-color: $grey-59 !default;
$switch-checked-color: $black !default;
$switch-active-color: $grey-80 !default;
$switch-disabled-color: $grey-20 !default;

$switch-slider-color: $white !default;
$switch-slider-disabled-color: $grey-05 !default;

.switch {
    display: inline-flex;
    align-items: center;

    .slider {
        appearance: none;

        width: $switch-width;
        height: $switch-height;
        border-radius: 0.5 * $switch-height;
        padding: $switch-padding;

        background-color: $switch-color;

        cursor: pointer;
        transition: 0.1s;

        &:before {
            display: flex;
            align-items: center;
            justify-content: center;
            content: '';
            height: $switch-slider-diameter;
            width: $switch-slider-diameter;
            border-radius: 0.5 * $switch-slider-diameter;
            background-color: $switch-slider-color;
            transition: 0.1s;
        }

        &:disabled {
            background-color: $switch-disabled-color;
            cursor: not-allowed;
            &:before {
                background-color: $switch-slider-disabled-color;
            }
            &:checked {
                background-color: $switch-disabled-color;
                cursor: not-allowed;
                &:before {
                    background-color: $switch-slider-disabled-color;
                }
            }
        }

        &:checked {
            background-color: $switch-checked-color;
            &:before {
                // shift the slide to the right
                transform: translateX($switch-width * 0.407407407407407);
            }
            &:active {
                &:before {
                    // move the lider partially to the left
                    transform: translateX($switch-width * 0.277777777777778);
                }
            }
        }
        &:focus {
            box-shadow: 0 0 0.0625em $black;
        }

        &:active {
            background-color: $switch-active-color;
            &:before {
                // stretch the slider
                width: $switch-width * 0.611111111111111;
            }
        }
    }
    .switch-label {
        margin-left: 0.5em;
    }
}
</style>
