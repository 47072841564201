import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import { plugin as VueTippy } from 'vue-tippy';

// since we also use v-tippy directly in some components leaving this in place for compatibility, in the future we could changing the name
// and making this an actual tms plugin for a popup/context

/**
 *
 * @param {import('vue').App} app the Vue application to register tippy plugin into
 */
export function setupTippy(app) {
    app.use(VueTippy, {
        directive: 'tippy',
        defaultProps: {
            arrow: true,
            allowHTML: true,
            theme: 'light',
        },
    });
}
