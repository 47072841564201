import type { RouteRecordRaw } from 'vue-router';

import Attributes from '../manage/views/Attributes.vue';
import Imports from '../manage/views/Imports.vue';
import Shares from '../manage/views/Shares.vue';

export const manageRoutes: RouteRecordRaw[] = [
    {
        path: '',
        redirect: { name: 'Attributes' },
    },
    {
        path: 'attributes',
        name: 'Attributes',
        component: Attributes,
    },
    {
        path: 'import',
        name: 'Imports',
        component: Imports,
    },
    {
        path: 'sharing',
        name: 'Shares',
        component: Shares,
    },
];
