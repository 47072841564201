<!--
    Generic message dialog. Buttons should have the primary action on the right, i.e.
    last element in array. The first button element will be used as the action for the
    X button so it should normally be the cancel action. If there is only one button
    then it will be used as the close action.
-->
<template>
    <DefaultModalLayout id="message-modal" :cancel-modal="cancelModal">
        <template v-if="title" #header>
            <h4 class="modal-title">{{ title }}</h4>
        </template>

        <div>
            {{ message }}
        </div>

        <div v-if="cta" class="cta">
            {{ cta }}
        </div>

        <template #footer>
            <TmsButton
                v-for="(option, index) in options"
                :key="index"
                :class="option.classes"
                :text="option.text"
                @click="respond(option.response)"
            />
        </template>
    </DefaultModalLayout>
</template>

<script>
import { defineComponent } from 'vue';

import TmsButton from '../TmsButton/TmsButton.vue';

import DefaultModalLayout from './DefaultModalLayout.vue';
import modalMixin from './TmsModalMixin';

export default defineComponent({
    components: { DefaultModalLayout, TmsButton },
    mixins: [modalMixin],
    props: {
        title: {
            type: String,
            default: '',
        },
        message: {
            type: String,
            default: '',
        },
        cta: {
            type: String,
            default: '',
        },
        options: {
            type: Array,
            default: () => [
                {
                    text: 'Cancel',
                    response: 'cancel',
                    classes: 'tertiary',
                },
                {
                    text: 'OK',
                    response: 'ok',
                    classes: 'primary',
                },
            ],
        },
    },
    data() {
        return {};
    },
    methods: {
        cancelModal() {
            this.close({ response: this.options[0].response });
        },
        respond(response) {
            this.close({ response: response });
        },
    },
});
</script>

<style lang="scss" scoped>
.cta {
    margin-top: 15px;
}
.modal-footer {
    div.tms-button {
        min-width: 80px;
    }
}
</style>
