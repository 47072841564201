<!--
    A TMS styled button that can include an image (or SVG) and/or text,
    along with different popovers for enabled and disabled states.

    We do not support cssOverride anymore as it wasn't being used and
    was limited to the img or svg elements. Replaced with a innerClass
    property to inject a class onto the button element. From there the
    img and svg elements can be styled.

    FIXME: This is mostly API compatible with the old ps-button button
    but it isn't very flexible when it comes to styling as the classes
    on the div override any classes injected with innerClass.

    Current theme based on Bootstrap 5.
-->

<template>
    <div v-tippy="{ content: popoverText }" class="tms-button" v-bind="rootAttrs">
        <!--
        We need a wrapper element to allow popovers to work in Bootstrap 3 and 4 when
        the component is disabled. The disabled state is on the wrapper element so
        that we can style the button but not prevent all events.

        We do not support disabling the component using the disabled class
        anymore. The only way to disable the button is through the property.
        -->
        <button
            v-bind="$attrs"
            class="btn btn-default"
            :class="[{ 'icon-only': !hasText, 'in-use': inUse }, innerClass ? innerClass : '']"
            :type="type"
            :disabled="computedDisabled"
            :autofocus="autofocus"
            @click="handleClick"
            @show="() => popoverText !== undefined"
        >
            <!-- spinner -->
            <div v-if="showBusy" :class="getSpinnerClass" />

            <!-- icon -->
            <div v-if="iconSrc && isIconSrcAnSvg" class="icon" :class="[strokeSvg ? 'stroke-svg' : '', name]">
                <InlineSvg :src="iconSrc" />
            </div>

            <img v-if="iconSrc && !isIconSrcAnSvg" class="icon" :class="name" :src="iconSrc" />

            <slot name="icon" />

            <!-- in use indicator -->
            <span v-if="inUse && hasText && !hasIcon" class="inuse-indicator">∙</span>

            <!-- text -->
            <span v-if="displayText" class="text">{{ displayText }}</span>

            <span v-if="$slots.default" class="text">
                <slot name="default" />
            </span>
        </button>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import InlineSvg from 'vue-inline-svg';
import { directive } from 'vue-tippy';

export default defineComponent({
    name: 'TmsButton',
    directives: {
        tippy: directive,
    },
    components: {
        InlineSvg,
    },
    inheritAttrs: false,
    props: {
        // Trying out a condensed format for props with only the two required fields
        manualBusy: { type: Boolean, default: false },
        autoBusy: { type: Boolean, default: false },
        autoTimeout: { type: Number, default: 5000 },
        disabled: { type: Boolean, default: false },
        disabledHint: { type: String, default: null },
        hint: { type: String, default: null },
        href: { type: String, default: null },
        iconSrc: { type: String, default: null },
        // trap class here and don't pass along with $attrs
        class: { type: String, default: null },
        innerClass: { type: String, default: null },
        inUse: { type: Boolean, default: false },
        name: { type: String, default: null },
        strokeSvg: { type: Boolean, default: false },
        text: { type: String, default: null },
        type: { type: String, default: 'button' },
        autofocus: { type: Boolean, default: undefined },
        to: { type: [String, Object], default: null },
        // FIXME: once tailwind / new design system is implemented just use a standard button in tms-dropdown
        //tms-dropdown props
        dataBsToggle: { type: String, default: undefined },
        dataBsDisplay: { type: String, default: undefined },
        ariaHaspopup: { type: String, default: undefined },
        ariaExpanded: { type: String, default: undefined },
    },
    emits: ['click'],
    data() {
        return {
            busyTimerId: null,
            showBusy: false,
        };
    },
    computed: {
        rootAttrs() {
            let dropDownProps = {};

            if (!this.computedDisabled) {
                dropDownProps = {
                    'data-bs-toggle': this.dataBsToggle,
                    'data-bs-display': this.dataBsDisplay,
                    'aria-haspopup': this.ariaHaspopup,
                    'aria-expanded': this.ariaExpanded,
                };
            }

            return {
                class: this.class,
                ...dropDownProps,
            };
        },
        computedDisabled() {
            return this.disabled || this.busyTimerId;
        },
        displayText() {
            return this.text;
        },
        hasIcon() {
            return this.iconSrc || this.$slots.icon;
        },
        hasText() {
            return this.text || this.$slots.default;
        },
        isIconSrcAnSvg() {
            return typeof this.iconSrc === 'string' && this.iconSrc.endsWith('svg');
        },
        popoverText() {
            let popoverText = null; // setting to undefined causes tippy to not updated content

            if (this.disabled && this.disabledHint) {
                popoverText = this.disabledHint;
            } else if (this.hint) {
                popoverText = this.hint;
            } else if (!this.text && this.name) {
                popoverText = this.name.toUpperCase();
            }

            return popoverText;
        },
        getSpinnerClass() {
            return {
                spinner: this.busyTimerId || this.showBusy,
            };
        },
    },
    watch: {
        manualBusy: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.showBusy = true;
                } else {
                    this.clearTimer();
                    this.showBusy = false;
                }
            },
        },
    },
    beforeUnmount() {
        this.clearTimer();
    },
    methods: {
        handleClick(event) {
            // FIXME: while this works it can lead to bad html / accessibility and console errors on renav to same page
            // Router Links
            if (this.to) {
                event.stopImmediatePropagation();
                this.$router.push(this.to);
                return;
            }

            // Anchor Links
            if (this.href) {
                event.stopImmediatePropagation();
                window.location.href = this.href;
                return;
            }

            if (this.autoBusy) {
                this.showBusy = true;
                this.busyTimerId = setTimeout(() => {
                    this.busyTimerId = null;
                    this.showBusy = false;
                }, this.autoTimeout);
            }

            this.$emit('click', event);
        },
        clearTimer() {
            if (this.busyTimerId) {
                clearTimeout(this.busyTimerId);
                this.busyTimerId = null;
            }
        },
    },
});
</script>

<style lang="scss" scoped>
@import '../styles/design-language-variables';
@import './_variables.scss';

$space-between-children: 4px;

@mixin button-styling($background, $border, $font, $icon-color: $font) {
    // use the same color for the icon as the font unless overridden) {
    .btn {
        color: $font;
        background-color: $background;
        border-color: $border;

        .icon svg {
            fill: $icon-color;
            vertical-align: baseline;
        }

        .icon.stroke-svg svg {
            stroke: $icon-color;
        }
    }
}

.tms-button {
    margin: 0;
    padding: 0;
    display: inline-block;
    background-color: transparent;
    border: 0;

    .btn {
        transition:
            border 0.25s linear 0s,
            color 0.25s linear 0s,
            background-color 0.25s linear 0s;
        padding: 7px 12px;
        width: 100%;
        height: 32px;

        border-width: 1px;
        border-style: solid;
        border-radius: 5px;

        font-size: 10px;
        font-weight: $font-weight-semi-bold;
        text-shadow: none;
        line-height: 20px;
        vertical-align: middle;
        white-space: nowrap;

        display: inline-flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        box-shadow: none;

        & > *:last-child {
            margin-right: 0px;
        }

        .text {
            display: inline-flex;
            font-family: $default-font-stack;
            font-size: 14px;
            font-weight: $font-weight-medium;
            text-transform: capitalize;
        }

        * + * {
            margin-left: $space-between-children;
        }

        .icon {
            height: 16px;
            display: inline-block;

            svg {
                width: 21px;
                height: 100% !important;
            }
        }

        &.icon-only {
            width: 35px;
            padding-left: 0px;
            padding-right: 0px;

            .icon {
                margin-right: 0;
            }
        }

        &.in-use {
            border: 1px solid #222 !important;

            .inuse-indicator {
                font-size: 24px;
                margin-top: -2px;
                & + .text {
                    margin-left: 4px; // intentionally different from default spacing of children
                }
            }
        }
    }

    // No specific colors for each button type just layering opacity
    .btn[disabled] {
        cursor: not-allowed;
        opacity: 0.5;
        pointer-events: auto;

        .text,
        .icon,
        svg,
        .inuse-indicator {
            opacity: 0.5;
        }
    }

    &.icon-right {
        .btn {
            flex-direction: row-reverse;

            * + * {
                margin-left: 0;
                margin-right: $space-between-children;
            }
        }
    }

    // Tertiary and default look the same. Just keeping the style for backwards compability
    &,
    &.tertiary {
        @include button-styling($redesign-button-background, $redesign-button-border, $redesign-button-font);
    }

    &:not(.btn[disabled]),
    &:not(.btn[disabled]).tertiary {
        &:focus,
        &:visited,
        &:hover {
            @include button-styling(
                $redesign-button-highlight-background,
                $redesign-button-highlight-border,
                $redesign-button-highlight-font
            );
        }

        &:active {
            @include button-styling(
                $redesign-button-active-background,
                $redesign-button-active-border,
                $redesign-button-active-font
            );
        }
    }

    // primary theme
    &.primary {
        @include button-styling(
            $primary-redesign-button-background,
            $primary-redesign-button-border,
            $primary-redesign-button-font
        );

        .btn {
            text-shadow: none;
        }
    }

    &:not(.btn[disabled]).primary {
        &:focus,
        &:visited,
        &:hover {
            @include button-styling(
                $primary-redesign-button-highlight-background,
                $primary-redesign-button-highlight-border,
                $primary-redesign-button-highlight-font
            );
        }

        &:active {
            @include button-styling(
                $primary-redesign-button-active-background,
                $primary-redesign-button-active-border,
                $primary-redesign-button-active-font
            );
        }
    }

    // secondary theme
    &.secondary {
        @include button-styling(
            $secondary-redesign-button-background,
            $secondary-redesign-button-border,
            $secondary-redesign-button-font
        );
    }

    &:not(.btn[disabled]).secondary {
        &:focus,
        &:visited,
        &:hover {
            @include button-styling(
                $secondary-redesign-button-highlight-background,
                $secondary-redesign-button-highlight-border,
                $secondary-redesign-button-highlight-font
            );
            outline: none;
        }

        &:active {
            @include button-styling(
                $secondary-redesign-button-active-background,
                $secondary-redesign-button-active-border,
                $secondary-redesign-button-active-font
            );
            outline: none;
        }
    }

    // magenta theme
    &.magenta {
        @include button-styling($magenta-button-background, $magenta-button-border, $magenta-button-font);
    }

    &:not(.btn[disabled]).magenta {
        &:focus,
        &:visited,
        &:hover {
            @include button-styling(
                $magenta-redesign-button-highlight-background,
                $magenta-redesign-button-highlight-border,
                $magenta-redesign-button-highlight-font
            );
            outline: none;
        }

        &:active {
            @include button-styling(
                $magenta-redesign-button-active-background,
                $magenta-redesign-button-active-border,
                $magenta-redesign-button-active-font
            );
            outline: none;
        }
    }

    // spinner
    @keyframes rotation {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(359deg);
        }
    }

    .spinner {
        height: 20px;
        width: 20px;
        //margin-right: 4px;
        display: inline-flex;
        opacity: 1;
        filter: alpha(opacity=100);
        animation: rotation 0.7s infinite linear;
        border: 4px solid rgba(0, 0, 0, 0.2);
        border-top-color: #9e9e9e;
        border-radius: 100%;
        transition: 0.3s all ease;
    }
}
</style>
