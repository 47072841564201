import { addNamespace } from './addNamespace';

const Actions = {
    SET_ALL: 'SET_ALL',
    CLEAR_ALL: 'CLEAR_ALL',
};

// for internal store use only
export const { SET_ALL: _SET_ALL, CLEAR_ALL: _CLEAR_ALL } = Actions;

export const { SET_ALL, CLEAR_ALL } = addNamespace(Actions);
