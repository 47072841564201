<template>
    <div class="import-list">
        <!-- Import List table -->
        <table class="table full-width">
            <thead>
                <tr>
                    <th scope="col">
                        <TmsColumnHeader
                            :column-settings="colSettings"
                            column-name="import_log_id"
                            @column-change="columnChange"
                            >Id</TmsColumnHeader
                        >
                    </th>
                    <th scope="col">
                        <TmsColumnHeader
                            :column-settings="colSettings"
                            column-name="file_path"
                            @column-change="columnChange"
                            >Path</TmsColumnHeader
                        >
                    </th>
                    <th scope="col">
                        <TmsColumnHeader
                            :column-settings="colSettings"
                            column-name="key_type"
                            @column-change="columnChange"
                            >Key Type</TmsColumnHeader
                        >
                    </th>
                    <th scope="col" class="align-right">
                        <TmsColumnHeader
                            :column-settings="colSettings"
                            column-name="unique_devices"
                            @column-change="columnChange"
                            >Unique Devices</TmsColumnHeader
                        >
                    </th>
                    <th scope="col" class="align-right">
                        <TmsColumnHeader
                            :column-settings="colSettings"
                            column-name="total_rows"
                            @column-change="columnChange"
                            >Total Rows</TmsColumnHeader
                        >
                    </th>
                    <th scope="col">
                        <TmsColumnHeader
                            :column-settings="colSettings"
                            column-name="status"
                            @column-change="columnChange"
                            >Status</TmsColumnHeader
                        >
                    </th>
                    <th scope="col">
                        <TmsColumnHeader
                            :column-settings="colSettings"
                            column-name="errors"
                            @column-change="columnChange"
                            >Errors</TmsColumnHeader
                        >
                    </th>
                    <th scope="col">
                        <TmsColumnHeader
                            :column-settings="colSettings"
                            column-name="created_at"
                            @column-change="columnChange"
                            >Created</TmsColumnHeader
                        >
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(log, index) in importList" :key="index">
                    <td>
                        {{ log.import_log_id }}
                    </td>
                    <td>
                        {{ log.file_path }}
                    </td>
                    <td>
                        {{ log.key_type }}
                    </td>
                    <td class="devices">
                        {{ formattedNumber(log.unique_devices) }}
                    </td>
                    <td class="rows">
                        {{ formattedNumber(log.total_rows) }}
                    </td>
                    <td>
                        {{ log.status }}
                    </td>
                    <td>
                        {{ log.errors }}
                    </td>
                    <td>
                        {{ shortDateTimeDisplay(log.created_at) }}
                    </td>
                </tr>
            </tbody>
        </table>
        <TmsPagination
            :current-page="currentPage"
            :total-items="imports.length"
            :items-per-page="itemsPerPage"
            :show-items-per-page="showItemsPerPage"
            @current-page-change="currentPageChange"
            @page-size-change="pageSizeChange"
        />
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import { shortDateTime, formattedNumber } from '@pushspring/tms-client-common';

export default defineComponent({
    name: 'ImportList',
    props: {
        /**
         * @type {{new (): Import[]}}
         */
        imports: {
            type: Array,
            required: true,
        },
        /**
         * @type {{{ column: string; order: string; }}
         */
        colSettings: {
            type: Object,
            required: true,
        },
    },
    emits: ['sort-changed'],
    data() {
        return {
            offset: 0,
            itemsPerPage: 25,
        };
    },
    computed: {
        currentPage() {
            return Math.ceil(this.offset / this.itemsPerPage) + 1;
        },

        showItemsPerPage() {
            return this.imports.length > 24;
        },
        /** @returns { Import[] } */
        importList() {
            const start = this.currentPage * this.itemsPerPage - this.itemsPerPage;
            const end = this.currentPage * this.itemsPerPage;
            const filteredImports = this.imports.filter((item, index) => index >= start && index <= end);
            return filteredImports;
        },
    },
    methods: {
        /**
         * @param {{ order: string; column: string; }} e
         */
        columnChange(e) {
            this.$emit('sort-changed', e);
        },
        //set offset
        currentPageChange({ currentPage }) {
            this.offset = (currentPage - 1) * this.itemsPerPage;
        },
        //set limit when page size change
        pageSizeChange({ itemsPerPage }) {
            this.itemsPerPage = itemsPerPage;
        },
        //shortdate time display
        shortDateTimeDisplay(value) {
            return value ? shortDateTime(value) : '';
        },
        formattedNumber,
    },
});
</script>
<style lang="scss" scoped>
.import-list {
    thead,
    tbody,
    tr,
    td,
    tr {
        vertical-align: middle;
    }
    .devices,
    .rows {
        text-align: right;
    }
    .align-right {
        width: 90px;
        min-width: 90px !important;
        text-align: right;
    }
}
</style>
