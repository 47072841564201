import { createApp } from 'vue';

import { ClientCommonPlugin, setupTippy, TmsModalPlugin } from '@pushspring/tms-client-common';

import App from './App.vue';
import { registerGlobalComponents } from './components/registerGlobal';
import { registerGlobalDirectives } from './directives/registerGlobal';
import router from './router';
import store from './store';
import clientConfig from './utils/clientConfig';
import { ConfigService } from './utils/ConfigService';

import 'bootstrap';
import './utils/axiosInterceptors';

import 'vue-select/dist/vue-select.css';
import './styles/index.css';
import './styles/main.scss';

const app = createApp(App);

setupTippy(app);

app.use(TmsModalPlugin);
app.use(ClientCommonPlugin);

registerGlobalComponents(app);
registerGlobalDirectives(app);

// Get the client config from the server and initialize the provider
// before bootstrapping the app.
const config = new ConfigService();

(async () => {
    const remoteConfig = await config.load('/api/clientConfig', clientConfig);
    // backwards compatibility
    Object.assign(clientConfig, remoteConfig);

    app.use(router);
    app.use(store);
    app.mount('#app');
})();
