<template>
    <div id="file-picker">
        <TmsButton id="open-modal-button" :class="buttonClass" :disabled="disabled" :text="label" @click="handleOpen" />
        <DashboardModal
            id="dashboard-modal"
            :uppy="uppy"
            :open="modalOpen"
            :props="{
                onRequestCloseModal: handleClose,
                showProgressDetails: true,
                proudlyDisplayPoweredByUppy: false,
            }"
        />
    </div>
</template>

<script lang="ts">
import AwsS3 from '@uppy/aws-s3';
import { Uppy } from '@uppy/core';
import { DashboardModal } from '@uppy/vue';
import { defineComponent } from 'vue';

import TmsButton from '../TmsButton/TmsButton.vue';

import {
    abortMultipartUpload,
    completeMultipartUpload,
    createMultipartUpload,
    getUploadParameters,
    listParts,
    signPart,
} from './uppyS3Upload.service';

import '@uppy/core/dist/style.min.css';
import '@uppy/dashboard/dist/style.min.css';

const MiB = 1_048_576;

export default defineComponent({
    components: { TmsButton, DashboardModal },
    props: {
        buttonClass: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            default: 'Choose files',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        options: {
            type: Object,
            default: () => ({}),
        },
        uploadKey: {
            type: String,
            default: '',
        },
    },
    emits: ['files-picked'],
    data() {
        return {
            defaultOptions: {
                maxNumberOfFiles: 20,
                minNumberOfFiles: 1,
                maxTotalFileSize: 3 * 1024 * 1024 * 1024, // default: around 3.2gb
                allowedFileTypes: ['.csv', '.tsv', '.txt'],
            },
            modalOpen: false,
        };
    },
    computed: {
        pickerOptions() {
            return {
                ...this.defaultOptions,
                ...this.options,
            };
        },
        uppy() {
            return new Uppy({ restrictions: this.defaultOptions })
                .use(AwsS3, {
                    id: 's3Uppy',
                    limit: 5,
                    shouldUseMultipart: (file) => file.size > 100 * MiB,

                    // TODO: when uppy fixes their types, remove the ts-expect-error
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    getUploadParameters,
                    // Multipart upload initiation
                    createMultipartUpload,
                    abortMultipartUpload,
                    signPart,
                    listParts,
                    completeMultipartUpload,
                })
                .on('complete', (result) => {
                    const successfulFiles = result.successful.map((f) => {
                        // Uppy types are not great so just doing this for now
                        const file = f as unknown as { key: string; s3Multipart: { key: string } };
                        // if multipart, use the key from the multipart upload
                        if (file.s3Multipart) {
                            file.key = file.s3Multipart.key;
                        }
                        return file;
                    });

                    this.$emit('files-picked', successfulFiles, result.failed);
                    this.handleClose();
                })
                .on('upload-success', (file) => {
                    if (file) {
                        console.info(`Upload success! We've uploaded this file:`, file.meta['name']);
                    }
                });
        },
    },
    created() {
        this.uppy.setOptions({
            restrictions: this.pickerOptions,
        });
    },
    methods: {
        handleOpen() {
            this.modalOpen = true;
        },
        handleClose() {
            this.modalOpen = false;
            this.uppy.cancelAll();
        },
        beforeUnmount() {
            this.uppy.close({ reason: 'unmount' });
        },
    },
});
</script>

<style lang="scss" scoped>
#file-picker {
    display: contents;

    #dashboard-modal {
        // 'or' for backwards compatibility since
        // Uppy is not fully Vue3 compliant
        &:deep() {
            .uppy-Dashboard-overlay,
            .uppy-Dashboard-inner {
                z-index: 1031;
            }
        }
    }
}
</style>
