<template>
    <nav class="tms-pagination">
        <div>
            <ul v-if="numberOfPages > 1" class="page">
                <li :class="{ disabled: currentPage === 1 }" class="arrow">
                    <a href="#" class="left" @click.prevent="prevPage">&lsaquo;</a>
                </li>
                <li>Page {{ currentPage }} of {{ numberOfPages }}</li>
                <li class="arrow" :class="{ disabled: currentPage === numberOfPages }">
                    <a class="right" href="#" @click.prevent="nextPage">&rsaquo;</a>
                </li>
            </ul>

            <ul v-if="showItemsPerPage" class="view">
                <li>View:</li>
                <li><a href="#" :class="{ active: itemsPerPage === 25 }" @click.prevent="maxPageResults(25)">25</a></li>
                <li><a href="#" :class="{ active: itemsPerPage === 50 }" @click.prevent="maxPageResults(50)">50</a></li>
                <li>
                    <a href="#" :class="{ active: itemsPerPage === 100 }" @click.prevent="maxPageResults(100)">100</a>
                </li>
            </ul>
        </div>
    </nav>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        currentPage: {
            type: Number,
            default: null,
            required: true,
        },
        itemsPerPage: {
            type: Number,
            default: 25,
        },
        totalItems: {
            type: Number,
            default: null,
        },
        showItemsPerPage: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['current-page-change', 'page-size-change'],
    computed: {
        numberOfPages() {
            return Math.ceil(this.totalItems / this.itemsPerPage) || 1;
        },
    },
    watch: {
        numberOfPages(newVal) {
            if (this.currentPage > newVal) {
                this.$emit('current-page-change', { currentPage: newVal });
            }
        },
    },
    methods: {
        prevPage() {
            if (this.currentPage !== 1) {
                this.$emit('current-page-change', { currentPage: this.currentPage - 1 });
            }
        },
        nextPage() {
            if (this.currentPage !== this.numberOfPages) {
                this.$emit('current-page-change', { currentPage: this.currentPage + 1 });
            }
        },
        maxPageResults(itemsPerPage) {
            this.$emit('page-size-change', { itemsPerPage: itemsPerPage });
        },
    },
});
</script>

<style lang="scss" scoped>
@import '../styles/design-language-variables';

.tms-pagination {
    color: $text-color-details;
    font-weight: 200;
    margin-top: 10px;

    .arrow {
        position: relative;
        .left {
            left: -13px;
        }
        .left,
        .right {
            font-size: 28px;
            position: absolute;
            top: -13px;
        }

        &.disabled {
            a {
                cursor: not-allowed;
            }
        }
    }

    ul {
        list-style: none;
        margin-right: 10px;
        float: right;
        li {
            float: left;
            margin-right: 10px;
        }
    }
    a {
        color: $text-color-details;
        &.active {
            color: $text-color-link;
        }
    }
}
</style>
