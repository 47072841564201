import { default as dayjs } from 'dayjs';
import { default as utc } from 'dayjs/plugin/utc';

dayjs.extend(utc);

// Equivalent to AngularJS short
export function shortDateTime(dateValue) {
    return dayjs(dateValue).format('M/D/YY h:mm A');
}

export function shortDate(dateValue) {
    return dayjs(dateValue).format('M/D/YY');
}

export function mediumDate(dateValue) {
    return dayjs(dateValue).format('MMM D, YYYY');
}

export function shortTime(dateValue) {
    return dayjs(dateValue).format('h:mm A');
}

export function mmDDYYYYUTC(dateValue) {
    return dayjs.utc(dateValue).format('MM/DD/YYYY');
}
