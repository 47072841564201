import type { Icons, Icon } from './icon-types';

export class IconRegistry {
    // Just incase someone decides to create a new instance this will be a singleton
    private static INSTANCE: IconRegistry;

    private static registry = new Map<string, string>();

    constructor() {
        if (IconRegistry.INSTANCE) {
            return IconRegistry.INSTANCE;
        } else {
            IconRegistry.INSTANCE = this;
        }
    }

    public static add(...icons: Icon[]) {
        icons.forEach((icon: Icon) => IconRegistry.registry.set(icon.name, icon.data));
    }

    public static get(iconName: Icons): string | undefined {
        if (!IconRegistry.registry.has(iconName)) {
            const availableIcons = Array.from(IconRegistry.registry.keys())
                .map((k) => `* "${k}"`)
                .join('\n');
            console.warn(
                `We could not find the Icon wth the name "${iconName}", did you add it to the Icon registry? The currently registered icons are:\n${availableIcons}`,
            );
        }
        return IconRegistry.registry.get(iconName);
    }
}
