import axios from 'axios';

export async function listShares() {
    try {
        const { data } = await axios.get('/api/organization/shares');
        return data;
    } catch (e) {
        throw new Error('There was an error listing shares.');
    }
}

export async function attemptNewPermissionViaEmail(email) {
    try {
        const { data } = await axios.post('/api/organization/addShareViaEmail', { email: email });
        return data;
    } catch (e) {
        throw new Error('There was an error sending new permission for share.');
    }
}
