import type { RouteRecordRaw } from 'vue-router';

import Attribute from '../report/views/Attribute.vue';
import Audience from '../report/views/Audience.vue';
import Historical from '../report/views/Historical.vue';

export const reportRoutes: RouteRecordRaw[] = [
    {
        path: '',
        redirect: { name: 'ReportAttribute' },
    },
    {
        path: 'attribute',
        name: 'ReportAttribute',
        component: Attribute,
    },
    {
        path: 'audience',
        name: 'ReportAudience',
        component: Audience,
    },
    {
        path: 'historical',
        name: 'ReportHistorical',
        component: Historical,
    },
];
