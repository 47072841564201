import { Modal } from 'bootstrap';
import { defineComponent } from 'vue';
import './Modal.scss';

// Using Bootstrap modal here for now
export default defineComponent({
    methods: {
        // Default show when nothing special is needed
        show() {
            return this._showModal();
        },
        // Call this to mount the VueJS modal and insert it
        // into the DOM.
        _showModal: function (parent = undefined) {
            return new Promise((resolve) => {
                this.close = this._closeModal.bind(this, resolve);

                window.addEventListener('popstate', this._popstateHandler);

                // Waiting on the Bootstrap modal callback before
                // destroying instances.
                this.$el.addEventListener(
                    'hidden.bs.modal',
                    function () {
                        // If this isn't set then the dialog was closed by Bootstrap,
                        // e.g. clicking outside of the modal, and we need to inform
                        // the caller.
                        if (!this.explicitClose) {
                            resolve();
                        }
                        // Eliminate duplicate popstate callbacks
                        window.removeEventListener('popstate', this._popstateHandler);
                        // Destroy the VueJS component in memory

                        // remove el from dom
                        this.$el.remove();
                    }.bind(this),
                );

                const parentNode = parent ?? document.getElementById('modal-root');
                // Using DOM functions to mount the elements
                parentNode.appendChild(this.$el);
                this.modal = new Modal(this.$el);
                this.modal.show();
            });
        },
        _closeModal: function (resolve, args) {
            // Needs to be before .hide()
            this.explicitClose = true;

            this.modal.hide();

            // Call the close callback with the passed in arguments
            resolve(args);
        },
        _popstateHandler: function () {
            // Hide modal if back button pressed
            this.modal.hide();
        },
    },
});
