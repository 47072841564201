import { BaseConfigService } from '@pushspring/core/ui';

import { environment } from '../environments/environment';

export class ConfigService extends BaseConfigService {
    get exploratronPrefix(): string {
        return environment.exploratronBaseUrl || this.get<string>('exploratronPrefix');
    }
    get eventotronPrefix(): string {
        return environment.eventotronBaseUrl || this.get<string>('eventotronPrefix');
    }
}
