/**
 * Creates a singleton instance of a Storage Class to store Auth related values
 * Pass the type of storage object you want to use store must implement the standard Web Storage API
 */
export class AuthStorage {
    static #INSTANCE: any;
    store: any;
    /**
     * Create a singleton of the AuthStorage provide the type of store you want to use
     * @param {{store:Storage}} [options]
     */
    constructor({ store } = { store: window.sessionStorage }) {
        // It would be confusing if the storage mechanism was changed after initial instance
        // so detect if we have an existing instance and return that ignoring options
        if (AuthStorage.#INSTANCE) {
            return AuthStorage.#INSTANCE;
        } else {
            this.store = store;
            AuthStorage.#INSTANCE = this;
        }
    }

    get token(): string | null {
        return this.store.getItem('token');
    }
    set token(val: string | null) {
        if (val === null) {
            this.store.removeItem('token');
        } else {
            this.store.setItem('token', val);
        }
    }

    get tokenExpiration(): string | null {
        return this.store.getItem('tokenExpiration');
    }
    set tokenExpiration(val: string | null) {
        if (val === null) {
            this.store.removeItem('tokenExpiration');
        } else {
            this.store.setItem('tokenExpiration', val);
        }
    }

    get userProfile(): any {
        const userProfile = this.store.getItem('userProfile');
        return userProfile ? JSON.parse(userProfile) : undefined;
    }
    set userProfile(val: any) {
        if (val === undefined) {
            this.store.removeItem('userProfile');
        } else {
            this.store.setItem('userProfile', JSON.stringify(val));
        }
    }

    setAll({
        token,
        userProfile,
        tokenExpiration,
    }: {
        token: string;
        userProfile: any;
        tokenExpiration: string;
    }): void {
        this.token = token;
        this.userProfile = userProfile;
        this.tokenExpiration = tokenExpiration;
    }

    remove(key: string): void {
        this.store.removeItem(key);
    }

    removeAll(): void {
        this.remove('token');
        this.remove('tokenExpiration');
        this.remove('userProfile');
    }
}
