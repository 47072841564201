<!--
    Top level sticky, horizontal header at the top of every page after login.
    There should only be one instance of this at any point as it updates global
    CSS variables with the dynamic height of the header.

    It includes:
    - Impersonation row
    - Page title row
    - Flash messages

-->
<template>
    <div class="horizontal-nav-bar">
        <div v-if="impersonatedBy" id="nav-bar-impersonation-row" :class="{ 'sans-default-row': hideDefaultRow }">
            <span>{{ impersonatedBy }} is impersonating {{ impersonatedUser }}</span>
        </div>

        <div v-if="showPageTitle" id="nav-bar-title-row" :class="{ 'sans-default-row': hideDefaultRow }">
            {{ pageTitle }}
        </div>

        <!--
            Need to hide message row, rather than not rendering it, otherwise
            reinitializing tms-flash-messages clears the messages before the
            user can see them.

            We also need this transition, otherwise the message row disappears
            immediately when the last message is deleted, instead of animating
            away.
        -->
        <transition name="message-row" @after-leave="messageRowHidden">
            <div v-show="showMessagesRow" id="nav-bar-messages-row">
                <TmsFlashMessages :in-header="true" @tms-flash-messages-deleted="flashMessageDeleted" />
            </div>
        </transition>
    </div>
</template>

<script>
import debounce from 'lodash/debounce';
import { defineComponent } from 'vue';

import TmsFlashMessages from '../TmsFlashMessages/TmsFlashMessages.vue';
import { useAssetServer } from '../utils/assetUtils';

export default defineComponent({
    name: 'TmsHorizontalNavBar',
    components: {
        TmsFlashMessages,
    },
    props: {
        homeUrl: {
            type: String,
            default: '/',
        },
        hideDefaultRow: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            debouncedOnResizeHandler: null,
            magentaLogoLink: {
                id: 'topNavMagentaLogoLink',
                route: this.homeUrl,
            },
        };
    },
    computed: {
        fullname() {
            return this.$store.state.login.profile?.first_name + ' ' + this.$store.state.login.profile?.last_name;
        },
        impersonatedUser() {
            const profile = this.$store.state.login.profile;
            if (!profile) {
                return null;
            }

            let impersonatedUser = this.fullname;

            if (impersonatedUser !== profile.companyName) {
                impersonatedUser += ' - ' + profile.companyName;
            }

            return impersonatedUser;
        },
        impersonatedBy() {
            return this.$store.state.login?.profile?.impersonatedBy;
        },
        pageTitle() {
            return this.$store.state.pageTitle;
        },
        showPageTitle() {
            return !this.$store.state.hideInNavBar && this.$store.state.pageTitle;
        },
        showMessagesRow() {
            // Updates to the messages will trigger the updated lifecycle method
            // to recalculate the header height.
            return this.$store.state.flashMessages.messages?.length > 0;
        },
    },
    mounted() {
        this.calcHeights();

        // Even without reflowing into different grid rows, the height of the
        // default row could still change as the navLinks and right hand controls
        // wrap so we need to recalc on resize.
        this.debouncedOnResizeHandler = debounce(this.calcHeights.bind(this), 25);
        window.addEventListener('resize', this.debouncedOnResizeHandler);
    },
    updated() {
        this.calcHeights();
    },
    unmounted() {
        if (this.debouncedOnResizeHandler) {
            // Not managed by VueJS so remove manually
            window.removeEventListener('resize', this.debouncedOnResizeHandler);
        }
    },
    methods: {
        calcHeights() {
            this.calcFullHeight();

            let heightWithoutTitle = 0;

            if (this.impersonatedBy) {
                const impersonationBar = this.$el.querySelector('#nav-bar-impersonation-row');
                if (impersonationBar) {
                    heightWithoutTitle += parseInt(impersonationBar.offsetHeight);
                }
            }

            const heightUnitPixels = `${heightWithoutTitle}px`;
            if (
                heightUnitPixels !==
                document.body.style.getPropertyValue('--default-horizontal-nav-bar-height-without-title')
            ) {
                document.body.style.setProperty(
                    '--default-horizontal-nav-bar-height-without-title',
                    `${heightWithoutTitle}px`,
                );
            }
        },
        // Calculates the full height including the border
        calcFullHeight() {
            const height = this.$el.offsetHeight;
            document.body.style.setProperty('--default-horizontal-nav-bar-height', `${height}px`);
            return height;
        },
        // Called when the animation for removing a message starts to keep the
        // sticky content below the header in sync with the animation of the
        // deleted messages.
        flashMessageDeleted(durationInSeconds) {
            let start;

            // Adding 100ms to catch any last CSS changes, e.g. margin between elements
            const durationInMs = durationInSeconds * 1000 + 100;

            function step(timestamp) {
                if (start === undefined) {
                    start = timestamp;
                }
                const elapsed = timestamp - start;

                // Only need to calculate the full height because the messages are
                // below the title.
                this.calcFullHeight();

                if (elapsed < durationInMs) {
                    window.requestAnimationFrame(step.bind(this));
                }
            }

            window.requestAnimationFrame(step.bind(this));
        },
        // Called after all messages have been deleted
        messageRowHidden() {
            this.calcHeights();
        },
        useAssetServer,
    },
});
</script>

<style lang="scss" scoped>
@import '../styles/design-language-variables';

.horizontal-nav-bar {
    background-color: $background-color-default-content;
    position: sticky;
    top: 0;
    // To allow the drop shadow to overlap the next element, which is commonly the header controls
    z-index: 1030;
    width: 100%;

    // Every row by default should use flex box.
    & > div {
        width: 100%;
        display: flex;
        align-items: center;
        font-family: $default-font-stack;
    }

    #nav-bar-impersonation-row {
        justify-content: center;
        height: 37px;
        background-color: #ffe100;
        padding: 0 $browser-side-offset;

        &.sans-default-row {
            height: 60px; // Lines up with a TmsLeftNavLayout logo section
        }

        span {
            font-size: 14px;
            font-weight: $font-weight-semi-bold;
            color: rgba(0, 0, 0, 0.5);
        }
    }

    #nav-bar-title-row {
        width: 100%;
        height: 76px;
        background-color: $background-color-default-content;
        border-bottom: 1px solid $grey-mid;
        font-weight: $font-weight-medium;
        font-size: $font-size-extra-large;
        color: $black;
        padding: 0 $browser-side-offset;
        margin-top: 2px; // to allow for the box shadow
        &.sans-default-row {
            margin-top: 0px; // No box shadow from default row
            height: 60px; // Lines up with a TmsLeftNavLayout logo section
        }
    }

    // Deliberately don't have a bottom border here as it causes
    // a 1px hitch in the animation for keeping the sticky content
    // in sync. The border of the last message acts as the border
    // for the row.
    #nav-bar-messages-row {
        & > div {
            width: 100%;
        }
    }

    // Synced to the animation of the last message deleted
    // to give it time to fade away.
    .message-row-leave-active {
        transition: opacity 1s;
        opacity: 0;
    }
}
</style>
