<template>
    <form class="contact-form" name="contact" novalidate @submit.prevent="submit">
        <div>
            <label for="email"
                >Email
                <span v-if="submitStatus === 'error' && v$.form.email.$invalid" class="error">
                    - Please enter a valid email.</span
                >
            </label>
        </div>
        <div class="email-field">
            <input id="email" v-model="form.email" type="email" class="form-control" placeholder="email@example.com" />
        </div>
        <div>
            <label for="topic"
                >Request Topic
                <span v-if="submitStatus === 'error' && v$.form.selectedTopic.$invalid" class="error">
                    - Please pick a topic.</span
                >
            </label>
        </div>
        <TmsDropdown
            id="contact-dropdown"
            v-model="form.selectedTopic"
            :options="topicOptions"
            :title="dropDownTitle"
        />
        <div>
            <label for="request"
                >Request
                <span v-if="submitStatus === 'error' && v$.form.content.$invalid" class="error">
                    - Please tell us what is going on.</span
                >
            </label>
        </div>
        <textarea v-model="form.content" />
        <!-- <pre>{{ { $attrs, topics, email, selectedTopic, form } }}</pre> -->
    </form>
</template>

<script lang="ts">
import type { PropType } from 'vue';

import { useVuelidate } from '@vuelidate/core';
import { email, required } from '@vuelidate/validators';
import { defineComponent } from 'vue';

import TmsDropdown from '../TmsDropdown/TmsDropdown.vue';

interface Option {
    id: number;
    title: string;
    disabled?: boolean;
}

interface Form {
    email: string;
    selectedTopic: Option[];
    content: string;
}

const placeHolderTopic = { id: -1, title: 'Please select a topic', disabled: true };

export default defineComponent({
    name: 'TmsContactForm',
    components: {
        TmsDropdown,
    },
    props: {
        topics: {
            type: Array as PropType<Option[]>,
            required: true,
        },
        email: {
            type: String,
            required: false,
            default: '',
        },
        selectedTopic: {
            type: Array as PropType<Option[]>,
            required: false,
            default: () => [placeHolderTopic],
        },
    },
    emits: ['submit'],
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        const form: Form = {
            email: this.email,
            selectedTopic: this.selectedTopic,
            content: '',
        };
        const submitStatus = '';

        return {
            form,
            submitStatus,
        };
    },
    validations() {
        return {
            form: {
                email: { required, email },
                content: { required },
                selectedTopic: {
                    required,
                    notDisabled: (value: Option[]) => !value[0].disabled,
                },
            },
        };
    },
    computed: {
        dropDownTitle() {
            return this.form.selectedTopic && this.form.selectedTopic.length > 0
                ? this.form.selectedTopic[0].title
                : placeHolderTopic.title;
        },
        topicOptions() {
            return [placeHolderTopic, ...this.topics];
        },
    },
    methods: {
        submit() {
            if (this.v$.form.$invalid) {
                this.submitStatus = 'error';
            } else {
                this.$emit('submit', this.form);
            }
        },
    },
});
</script>

<style lang="scss" scoped>
@import '../styles/design-language-variables';
.contact-form {
    form {
        margin-top: -10px;
        margin-bottom: 20px;
    }
    textarea {
        width: 100%;
        height: 150px;
        padding: 10px 10px;
        box-sizing: border-box;
        border: 0px;
        border-radius: 4px;
        background-color: $grey-light;
        resize: none;
    }
    textarea:focus {
        box-shadow: 0 0 0 1px $grey-20;
    }
    label {
        padding-top: 24px !important;
        padding-right: 12px;
        padding-bottom: 5px;
        padding-left: 0px;
    }
    .error {
        color: $magenta-100;
    }
    &:deep() {
        .tms-button .btn {
            justify-content: left;
            span {
                flex-grow: 2;
            }
        }
        .tms-dropdown .show {
            width: 100%;
            max-height: 200px !important;
        }
    }
}
</style>
