/**
 * Check if the user has access to the resource.
 * @param usersRoles - The roles of the user.
 * @param authorizedRoles - The roles that are authorized to access the resource.
 * @returns
 */
export function hasAccess(usersRoles: string[] | string, authorizedRoles: string[]): boolean {
    // ? is there any instance where usersRoles is not an array?
    const rolesArray = Array.isArray(usersRoles) ? usersRoles : [usersRoles];

    // Short-circuit if no authorization is required.
    if (authorizedRoles.length === 0) {
        return true;
    }

    // Convert the authorizedRoles to a Set for efficient lookups.
    const authorizedRolesSet = new Set(authorizedRoles);

    // Check if at least one userRole is in the authorizedRolesSet.
    return rolesArray.some((userRole) => authorizedRolesSet.has(userRole));
}
