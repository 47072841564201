<template>
    <div class="historical-list">
        <!-- Historical List table -->
        <table class="table full-width">
            <thead>
                <tr>
                    <th scope="col">
                        <TmsColumnHeader :column-settings="colSettings" column-name="name" @column-change="columnChange"
                            >Name</TmsColumnHeader
                        >
                    </th>
                    <th scope="col">
                        <TmsColumnHeader :column-settings="colSettings" column-name="type" @column-change="columnChange"
                            >Report Type</TmsColumnHeader
                        >
                    </th>
                    <th scope="col">
                        <TmsColumnHeader
                            :column-settings="colSettings"
                            column-name="created_at"
                            @column-change="columnChange"
                            >Created At</TmsColumnHeader
                        >
                    </th>
                    <th scope="col">
                        <TmsColumnHeader
                            :column-settings="colSettings"
                            column-name="start_date"
                            @column-change="columnChange"
                            >Start Date</TmsColumnHeader
                        >
                    </th>
                    <th scope="col">
                        <TmsColumnHeader
                            :column-settings="colSettings"
                            column-name="end_date"
                            @column-change="columnChange"
                            >End Date</TmsColumnHeader
                        >
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(report, index) in reportList" :key="index">
                    <td>
                        <a href="#" @click="showReport(report.location)">{{ report.name }}</a>
                    </td>
                    <td>
                        {{ mapReportType(report.type) }}
                    </td>
                    <td>
                        {{ shortDateTimeDisplay(report.created_at) }}
                    </td>
                    <td>
                        {{ shortDateTimeDisplay(report.start_date) }}
                    </td>
                    <td>
                        {{ shortDateTimeDisplay(report.end_date) }}
                    </td>
                </tr>
            </tbody>
        </table>
        <TmsPagination
            :current-page="currentPage"
            :total-items="reports.length"
            :items-per-page="itemsPerPage"
            :show-items-per-page="showItemsPerPage"
            @current-page-change="currentPageChange"
            @page-size-change="pageSizeChange"
        />
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import { Marketplace } from '@pushspring/ps-common/lib/constants-public';
import { shortDateTime } from '@pushspring/tms-client-common';

export default defineComponent({
    name: 'HistoricalList',
    props: {
        /**
         * @type {{new (): Report[]}}
         */
        reports: {
            type: Array,
            required: true,
        },
        /**
         * @type {{{ column: string; order: string; }}
         */
        colSettings: {
            type: Object,
            required: true,
        },
    },
    emits: ['sort-changed', 'report-location'],
    data() {
        return {
            offset: 0,
            itemsPerPage: 25,
        };
    },
    computed: {
        currentPage() {
            return Math.ceil(this.offset / this.itemsPerPage) + 1;
        },
        showItemsPerPage() {
            return this.reports.length > 24;
        },
        /** @returns { Report[] } */
        reportList() {
            const start = this.currentPage * this.itemsPerPage - this.itemsPerPage;
            const end = this.currentPage * this.itemsPerPage;
            const filteredReports = this.reports.filter((item, index) => index >= start && index <= end);
            return filteredReports;
        },
    },
    methods: {
        /**
         * @param {{ order: string; column: string; }} e
         */
        columnChange(e) {
            this.$emit('sort-changed', e);
        },
        //set offset
        currentPageChange({ currentPage }) {
            this.offset = (currentPage - 1) * this.itemsPerPage;
        },
        //set limit when page size change
        pageSizeChange({ itemsPerPage }) {
            this.itemsPerPage = itemsPerPage;
        },
        //shortdate time display
        shortDateTimeDisplay(value) {
            return value ? shortDateTime(value) : '';
        },
        //map report type
        mapReportType(type) {
            const report_type = Marketplace.PartnerReports.Type.find((report_type) => {
                return report_type.id === type;
            });
            if (report_type != undefined) {
                return report_type.name;
            } else {
                return 'Unknown';
            }
        },
        /**
         * @param {{ location:string; }} e
         */
        showReport(e) {
            this.$emit('report-location', e);
        },
    },
});
</script>
<style lang="scss" scoped>
.historical-list {
    thead,
    tbody,
    tr,
    td,
    tr {
        vertical-align: middle;
    }
}
</style>
