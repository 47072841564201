import axios from 'axios';

import { ConfigService } from '../../utils/ConfigService';

const clientConfig = new ConfigService();

export async function getAttributes() {
    try {
        const { data } = await axios.get(clientConfig.eventotronPrefix + '/v1/marketplace/attribute');
        return data;
    } catch (e) {
        throw new Error('There was an error getting the attributes.');
    }
}

export async function importTaxonomy(filePath) {
    try {
        const { data } = await axios.post('/api/attribute/import', { filePath: filePath });
        return data;
    } catch (e) {
        throw new Error('There was an error importing the attributes.');
    }
}

export async function create(attribute) {
    try {
        const { data } = await axios.post(clientConfig.eventotronPrefix + '/v1/marketplace/attribute', attribute);
        return data;
    } catch (e) {
        throw new Error('There was an error creating new attribute.');
    }
}

export async function update(attribute) {
    try {
        const attributeId = attribute.attribute_id;
        const { data } = await axios.patch(
            clientConfig.eventotronPrefix + '/v1/marketplace/attribute/' + attributeId,
            attribute,
        );
        return data;
    } catch (e) {
        throw new Error('There was an error updating the attribute.');
    }
}
