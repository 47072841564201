<template>
    <div class="share-list">
        <!-- Share List table -->
        <table class="table full-width">
            <thead>
                <tr>
                    <th scope="col">
                        <TmsColumnHeader
                            :column-settings="colSettings"
                            column-name="company_name"
                            @column-change="columnChange"
                            >Company</TmsColumnHeader
                        >
                    </th>
                    <th scope="col">
                        <TmsColumnHeader
                            :column-settings="colSettings"
                            column-name="attribute_count"
                            @column-change="columnChange"
                            >Shared Attributes</TmsColumnHeader
                        >
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(share, index) in shareList" :key="index">
                    <td>
                        {{ share.company_name }}
                    </td>
                    <td>
                        {{ mapShareCount(share) }}
                    </td>
                </tr>
            </tbody>
        </table>
        <TmsPagination
            :current-page="currentPage"
            :total-items="shares.length"
            :items-per-page="itemsPerPage"
            :show-items-per-page="showItemsPerPage"
            @current-page-change="currentPageChange"
            @page-size-change="pageSizeChange"
        />
    </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'ShareList',
    props: {
        /**
         * @type {{new (): Share[]}}
         */
        shares: {
            type: Array,
            required: true,
        },
        /**
         * @type {{new (): Attribute[]}}
         */
        attributes: {
            type: Array,
            required: true,
        },
        /**
         * @type {{{ column: string; order: string; }}
         */
        colSettings: {
            type: Object,
            required: true,
        },
    },
    emits: ['sort-changed'],
    data() {
        return {
            offset: 0,
            itemsPerPage: 25,
        };
    },
    computed: {
        currentPage() {
            return Math.ceil(this.offset / this.itemsPerPage) + 1;
        },

        showItemsPerPage() {
            return this.shares.length > 24;
        },
        /** @returns { Share[] } */
        shareList() {
            const start = this.currentPage * this.itemsPerPage - this.itemsPerPage;
            const end = this.currentPage * this.itemsPerPage;
            const filteredShares = this.shares.filter((item, index) => index >= start && index <= end);
            return filteredShares;
        },
    },
    methods: {
        /**
         * @param {{ order: string; column: string; }} e
         */
        columnChange(e) {
            this.$emit('sort-changed', e);
        },
        //set offset
        currentPageChange({ currentPage }) {
            this.offset = (currentPage - 1) * this.itemsPerPage;
        },
        //set limit when page size change
        pageSizeChange({ itemsPerPage }) {
            this.itemsPerPage = itemsPerPage;
        },
        //map share count
        mapShareCount(share) {
            const shareCountArr = this.attributes
                .filter((attribute) => attribute.visibility_whitelist.includes(share.org_id))
                .map((obj) => obj.name);
            return shareCountArr?.length;
        },
    },
});
</script>
<style lang="scss" scoped>
.share-list {
    thead,
    tbody,
    tr,
    td,
    tr {
        vertical-align: middle;
    }
}
</style>
