<template>
    <div class="tms-date-range-picker">
        <div class="range-picker-wrapper d-flex align-items-center gap-2">
            <DatePicker
                v-model="rangeStartProxy"
                timezone="UTC"
                :first-day-of-week="2"
                :model-config="startModelConfig"
                :min-date="startMinDate"
                :max-date="startMaxDate"
                :select-attribute="{
                    highlight: { fillMode: 'solid', style: { background: '#000000' } },
                }"
            >
                <template #default="{ inputValue, inputEvents, togglePopover }">
                    <div class="input-wrapper prepend" v-on="!disabled ? { hover: togglePopover } : {}">
                        <TmsIcon name="fthr-calendar" :class="{ disabled: disabled }" />
                        <input
                            :class="{ disabled: disabled }"
                            :disabled="disabled"
                            :value="inputValue"
                            placeholder="From"
                            v-on="inputEvents"
                            @keydown.enter.prevent
                        />
                    </div>
                </template>
            </DatePicker>

            <TmsIcon name="fthr-arrow-right" :class="{ disabled: disabled }" />

            <DatePicker
                v-model="rangeEndProxy"
                timezone="UTC"
                :first-day-of-week="2"
                :model-config="endModelConfig"
                :min-date="endMinDate"
                :max-date="endMaxDate"
                :select-attribute="{
                    highlight: { fillMode: 'solid', style: { background: '#000000' } },
                }"
            >
                <template #default="{ inputValue, inputEvents, togglePopover }">
                    <div class="input-wrapper prepend" v-on="!disabled ? { hover: togglePopover } : {}">
                        <TmsIcon name="fthr-calendar" :class="{ disabled: disabled }" />
                        <input
                            :class="{ disabled: disabled }"
                            :disabled="disabled"
                            :value="inputValue"
                            placeholder="To"
                            v-on="inputEvents"
                            @keydown.enter.prevent
                        />
                    </div>
                </template>
            </DatePicker>
        </div>
    </div>
</template>

<script>
// TODO: DateRange Picker should just use our DatePicker
import { DatePicker } from 'v-calendar';
import { defineComponent } from 'vue';

import { IconRegistry, TmsIcon } from '@pushspring/common-ui/icons-core';
import { fthrArrowRight, fthrCalendar } from '@pushspring/common-ui/icons-feather';

import 'v-calendar/dist/style.css';

IconRegistry.add(fthrArrowRight, fthrCalendar);

export default defineComponent({
    components: {
        DatePicker,
        TmsIcon,
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        startMinDate: {
            type: Date,
            default: null,
        },
        endMaxDate: {
            type: Date,
            default: null,
        },
        rangeStart: {
            type: Date,
            default: null,
        },
        rangeEnd: {
            type: Date,
            default: null,
        },
    },
    emits: ['range-start-change', 'range-end-change'],
    data() {
        return {
            startModelConfig: {
                timeAdjust: '00:00:00',
            },
            endModelConfig: {
                timeAdjust: '23:59:59', // Picker fails to display selection if the final .999 is used
            },
        };
    },
    computed: {
        rangeStartProxy: {
            get() {
                return this.rangeStart;
            },
            set(newStart) {
                this.$emit('range-start-change', newStart);
            },
        },
        rangeEndProxy: {
            get() {
                return this.rangeEnd;
            },
            set(newEnd) {
                this.$emit('range-end-change', newEnd);
            },
        },
        startMaxDate() {
            return this.rangeEnd || this.endMaxDate;
        },
        endMinDate() {
            return this.rangeStart || this.startMinDate || this.startMaxDate;
        },
    },
});
</script>

<style lang="scss" scoped>
@import '../styles/_design-language-variables.scss';

.tms-date-range-picker {
    svg {
        height: 16px;
        width: 16px;
    }
    .range-picker-wrapper {
        svg {
            min-width: 16px;
            min-height: 16px;

            &.disabled {
                color: $grey-dark;
            }
        }
    }
    .input-wrapper {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;

        svg {
            position: absolute;

            z-index: 1;
            pointer-events: none;

            &.disabled {
                stroke: $grey-dark;
            }
        }

        &.prepend {
            svg {
                left: 8px;
            }

            input {
                padding-left: 32px !important;
                width: 125px;
            }
        }

        input {
            background-color: $grey-light;
            border-radius: 5px;
            height: 32px;
            padding: 8px;
            font-weight: $font-weight-medium;
            font-family: $default-font-stack;
            color: $black;
            font-size: $font-size-small !important; // important needed if using VueDatePicker
            letter-spacing: 0px;
            line-height: 1;
            border: none;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: 100%;

            &:focus {
                outline: none;
            }

            &.dragging {
                color: $grey-dark;
            }

            &.disabled {
                color: $grey-dark;
                cursor: not-allowed;
            }
        }
    }

    ::placeholder {
        color: $grey-46;
    }
}
</style>
