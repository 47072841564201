<!--
    TmsDropdown is a single or multi-select TMS styled dropdown
    that will be the internal implementation for other variant
    dropdowns or used directly.

    options should be an array of objects of the form:
    {
        id: Unique ID across items.
        title: What the user sees.
        disabled: Optional boolean property.
    }

    OR

    options can be grouped as follows
    {
        group: Unique ID across groups and items
        title: Show a group header.
        options: [
            {
                id: Unique ID across items.
                title: What the user sees.
                disabled: Optional boolean property.
            }
        ]
    }

    v-model should and will be set to a single element array
    in the single select case or a multiple element array in
    the multi-select case. If using groups it should be an
    object mirroring the grouped options and will be returned
    in the same way.

    Current theme based on Bootstrap 5.
-->
<template>
    <div class="dropdown tms-dropdown">
        <TmsButton
            class="toggle icon-right"
            :disabled="$attrs.disabled"
            data-bs-toggle="dropdown"
            data-bs-display="static"
            aria-haspopup="true"
            aria-expanded="false"
        >
            {{ title }} <template #icon><TmsIcon name="fthr-chevron-down" /></template>
        </TmsButton>

        <ul
            class="dropdown-menu"
            :class="{
                'multi-select': multiSelect,
                'dropdown-menu-end': alignment === 'right',
                'showing-headers': showingHeaders,
            }"
            aria-labelledby="dropdownMenu1"
        >
            <template v-for="(group, groupIndex) in groups">
                <!-- Group Divider -->
                <li
                    v-if="groups.length > 1 && groupIndex > 0"
                    :key="'divider-' + group.id"
                    role="separator"
                    class="dropdown-divider"
                />

                <!-- Group Title plus Select/Clear All -->
                <li
                    v-if="group.title || (multiSelect && showSelectAll)"
                    :key="'group-title-' + group.id"
                    class="dropdown-header"
                    :class="{ 'select-all-toggle': multiSelect && showSelectAll }"
                    @click="toggleGroupSelectedStates(group, $event)"
                >
                    {{ groupTitle(group) }}
                </li>

                <!-- Group Items -->
                <li
                    v-for="item in group.options"
                    :key="trackerId(group, item)"
                    :disabled="item.disabled ? true : null"
                    @click.capture="selectItem(group, item, $event)"
                >
                    <TmsCheckbox
                        v-if="multiSelect"
                        v-model="selectionTracker[trackerId(group, item)].selected"
                        :label="item.title"
                        :disabled="item.disabled"
                    />

                    <div v-if="!multiSelect">{{ item.title }}</div>
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

import { IconRegistry, TmsIcon } from '@pushspring/common-ui/icons-core';
import { fthrChevronDown } from '@pushspring/common-ui/icons-feather';

import TmsButton from '../TmsButton/TmsButton.vue';
import TmsCheckbox from '../TmsCheckbox/TmsCheckbox.vue';

IconRegistry.add(fthrChevronDown);

export default defineComponent({
    name: 'TmsDropdown',
    components: { TmsIcon, TmsButton, TmsCheckbox },
    props: {
        alignment: {
            type: String,
            default: 'left',
        },
        multiSelect: {
            type: Boolean,
            default: false,
        },
        options: {
            type: [Array, Object],
            required: true,
        },
        modelValue: {
            type: [Array, Object],
            required: false,
            default: () => [{}],
        },
        showSelectAll: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            required: true,
        },
    },
    emits: ['update:modelValue', 'change'],
    data() {
        return {
            originalMaxHeight: null,
        };
    },
    computed: {
        // Collect all the selected options across all groups in a form that
        // can be returned as the model value.
        allSelectedOptions: function () {
            return this.calcAllSelectedOptions();
        },
        // Encapsulating in case the structure changes
        isGrouped: function () {
            return !Array.isArray(this.options);
        },
        // Normalizes the Array or Object versions of options into an array of groups
        // so that the template can deal with one structure.
        groups: function () {
            let groups = [];

            if (!this.isGrouped) {
                groups = [{ id: '', options: this.options }];
            } else {
                // Just to remind me that I can rely on the order of properties now:
                // https://2ality.com/2015/10/property-traversal-order-es6.html
                for (const groupId in this.options) {
                    const group = this.options[groupId];
                    groups.push({ id: groupId, ...group });
                }
            }

            return groups;
        },
        showingHeaders() {
            return (
                (this.multiSelect && this.showSelectAll) ||
                (this.isGrouped && Object.values(this.options).some((o) => o.title))
            );
        },
        // Creates an internal object that tracks the selected state of each item
        selectionTracker: function () {
            const tracker = {};

            // Add an entry for each option
            for (const group of this.groups) {
                for (const item of group.options) {
                    tracker[this.trackerId(group, item)] = {
                        selected: false,
                        disabled: item.disabled,
                    };
                }
            }

            // Now select all the ones that are in the model
            if (!this.isGrouped) {
                for (const item of this.modelValue) {
                    const trackedItem = tracker[item.id];
                    if (trackedItem) {
                        trackedItem.selected = true;
                    }
                }
            } else {
                for (const groupId in this.modelValue) {
                    const group = this.modelValue[groupId];
                    for (const item of group.options) {
                        const trackedItem = tracker[`${groupId}${item.id}`];
                        if (trackedItem) {
                            trackedItem.selected = true;
                        }
                    }
                }
            }
            return tracker;
        },
    },
    mounted: function () {
        if (Array.isArray(this.options) !== Array.isArray(this.modelValue)) {
            throw new Error('The options and v-model types should match: either both Array or both Object');
        }

        if (this.showSelectAll && !this.multiSelect) {
            throw new Error("Using showSelectAll with a single select dropdown doesn't do anything");
        }

        // Hook into the event sent before showing so we can set the style
        this.$el.addEventListener('show.bs.dropdown', this.dropdownEventListener);
    },
    unmounted() {
        this.$el.removeEventListener('show.bs.dropdown', this.dropdownEventListener);
    },
    methods: {
        // Is every item selected in the group excluding the disabled items
        areAllSelectedInGroup: function (group) {
            const options = !this.isGrouped ? this.options : this.options[group.id].options;

            return options.every(
                (item) => this.selectionTracker[this.trackerId(group, item)].selected || item.disabled,
            );
        },
        calcAllSelectedOptions() {
            let selected = null;

            if (!this.isGrouped) {
                const group = this.groups[0];
                selected = group.options.filter(
                    (item) => this.selectionTracker[this.trackerId(group, item)].selected === true,
                );
            } else {
                selected = {};
                for (const group of this.groups) {
                    const filtered = group.options.filter(
                        (item) => this.selectionTracker[this.trackerId(group, item)].selected === true,
                    );
                    if (filtered.length > 0) {
                        selected[group.id] = {
                            id: group.id,
                            options: filtered,
                        };
                    }
                }
            }

            return selected;
        },
        calcDropdownMaxHeight(dropdownRoot) {
            const matches = dropdownRoot.getElementsByClassName('dropdown-menu');
            if (matches && matches.length === 1) {
                const dropdownMenu = matches[0];
                const scrollableParent = getScrollParent(this.$el);

                // Find any existing max-height set in the style sheet and use it as a cap
                // on the calculated max-height. Once we set the maxHeight once the original
                // is lost so save it on the component.
                if (!this.originalMaxHeight) {
                    let originalMaxHeight = window.getComputedStyle(dropdownMenu)['max-height'];
                    if (originalMaxHeight) {
                        originalMaxHeight = parseInt(originalMaxHeight);
                    }
                    this.originalMaxHeight = originalMaxHeight || -1;
                }

                // estimate top of dropdown menu since it isn't shown yet
                const rect = this.$el.getBoundingClientRect();
                const offsetTop = scrollableParent.scrollTop + rect.top + rect.height;

                let maxHeight = scrollableParent.scrollHeight - offsetTop - 20; // stop short of bottom of the page
                if (this.originalMaxHeight > 0) {
                    // Honor the original max-height in the stylesheet
                    // if it is less than the calculated max height.
                    maxHeight = Math.min(maxHeight, this.originalMaxHeight);
                }
                // Do not get smaller than this even if it pushes out the page
                maxHeight = Math.max(maxHeight, 100);

                dropdownMenu.style.overflowY = 'auto';
                dropdownMenu.style.maxHeight = maxHeight + 'px';
            } else {
                console.error('Could not find dropdown menu element in TmsDropdown');
            }
        },
        dropdownEventListener(event) {
            this.calcDropdownMaxHeight(event.currentTarget);
        },
        // The title can optionally include a given group title and/or the 'Select/Clear All' action
        groupTitle: function (group) {
            let groupTitle = group.title || '';

            if (this.multiSelect && this.showSelectAll) {
                if (groupTitle.length > 0) {
                    groupTitle += ' - ';
                }
                groupTitle += this.areAllSelectedInGroup(group) ? 'Clear All' : 'Select All';
            }

            return groupTitle;
        },
        selectItem: function (group, item, event) {
            if (item.disabled) {
                event.stopImmediatePropagation();
                return;
            }

            if (this.multiSelect) {
                // Keep the dropdown open so the user can select multiple
                // items without needing to reopen the dropdown each time.
                event.stopImmediatePropagation();

                if (event.target.type !== 'checkbox') {
                    event.preventDefault();
                }

                this.selectionTracker[this.trackerId(group, item)].selected =
                    !this.selectionTracker[this.trackerId(group, item)].selected;
            } else {
                // Since only one item is selected at a time we need to unselect the previous
                // selected item if there was one before selecting the new one.
                let prevSelected = null;
                let prevSelectedGroupId = null;
                if (!this.isGrouped) {
                    prevSelected =
                        this.modelValue.length === 1 && this.modelValue[0].id !== undefined ? this.modelValue[0] : null;
                    prevSelectedGroupId = group.id;
                } else {
                    const keys = Object.keys(this.modelValue);
                    if (keys.length === 1) {
                        prevSelectedGroupId = keys[0];
                        prevSelected = this.modelValue[prevSelectedGroupId].options[0];
                    }
                }

                if (prevSelected) {
                    this.selectionTracker[`${prevSelectedGroupId}${prevSelected.id}`].selected = false;
                }
                this.selectionTracker[this.trackerId(group, item)].selected = true;
            }

            this.$emit('update:modelValue', this.calcAllSelectedOptions());
            /*
             * 'change' needs to be emitted after 'update:modelValue' becuase there are some places where the dropdown
             * has be used incorrectly and the 'change' event is used to call a method that expects the model value updated
             * if the change is fired first the model value will not be updated yet.
             *
             * The correct way to use the dropdown is to just use the modelValue and there is no need to listen to the 'change' event.
             * you can just use a computed property or a watcher on the model value to do whatever you need to do when the value changes.
             * or if you use the change even you should use the value emitted by the change event and not the combination of both
             */
            this.$emit('change', this.calcAllSelectedOptions());
        },
        toggleGroupSelectedStates: function (group, event) {
            if (!(this.multiSelect && this.showSelectAll)) {
                // Should never be called but short-circuiting as a precaution
                return;
            }

            const allWereSelected = this.areAllSelectedInGroup(group);

            if (!this.isGrouped) {
                for (const item of this.options) {
                    if (!item.disabled) {
                        this.selectionTracker[item.id].selected = !allWereSelected;
                    }
                }
            } else {
                for (const item of this.options[group.id].options) {
                    if (!item.disabled) {
                        this.selectionTracker[this.trackerId(group, item)].selected = !allWereSelected;
                    }
                }
            }
            const val = this.calcAllSelectedOptions();
            this.$emit('update:modelValue', val);
            /*
             * 'change' needs to be emitted after 'update:modelValue' becuase there are some places where the dropdown
             * has be used incorrectly and the 'change' event is used to call a method that expects the model value updated
             * if the change is fired first the model value will not be updated yet.
             *
             * The correct way to use the dropdown is to just use the modelValue and there is no need to listen to the 'change' event.
             * you can just use a computed property or a watcher on the model value to do whatever you need to do when the value changes.
             * or if you use the change even you should use the value emitted by the change event and not the combination of both
             */
            this.$emit('change', val);

            event.stopImmediatePropagation();
            event.preventDefault();
        },
        // Generates an ID for the selectionTracker object.
        // Encapsulating in one place in case it needs to change.
        trackerId: function (group, item) {
            return `${group.id}${item.id}`;
        },
    },
});

// From: https://stackoverflow.com/a/49186677
// With some tweaks to fall through on the document.body element.
function getScrollParent(node) {
    const regex = /(auto|scroll)/;
    const parents = (_node, ps) => {
        if (_node.parentNode === null) {
            return ps;
        }
        return parents(_node.parentNode, ps.concat([_node]));
    };

    const style = (_node, prop) => getComputedStyle(_node, null).getPropertyValue(prop);
    const overflow = (_node) => style(_node, 'overflow') + style(_node, 'overflow-y') + style(_node, 'overflow-x');
    const scroll = (_node) => regex.test(overflow(_node));

    /* eslint-disable consistent-return */
    const scrollParent = (_node) => {
        if (!(_node instanceof HTMLElement || _node instanceof SVGElement)) {
            return;
        }

        const ps = parents(_node.parentNode, []);

        for (let i = 0; i < ps.length; i += 1) {
            if (scroll(ps[i]) && ps[i] !== document.body) {
                return ps[i];
            }
        }

        return document.scrollingElement || document.documentElement;
    };

    return scrollParent(node);
    /* eslint-enable consistent-return */
}
</script>

<style lang="scss">
@import '../styles/design-language-variables';

.tms-dropdown {
    .tms-button {
        width: 100%;
        button {
            justify-content: space-between;
            // Style the chevron
            svg {
                stroke: $black;
                display: inline-block;
                width: 14px;
                height: 14px;
                vertical-align: middle;
            }
        }
    }
    .dropdown-menu {
        top: auto;
        overflow-y: auto;
        overflow-x: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;

        li {
            white-space: nowrap;

            &[disabled] {
                cursor: not-allowed;

                .tms-checkbox label {
                    cursor: not-allowed;
                }
            }
        }
    }

    .dropdown-menu {
        padding: 8px;
        box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
        border: none;
        border-radius: 10px;
        background-color: $white;
        width: auto;

        &.showing-headers {
            padding: 10px;
        }

        li {
            line-height: 19.25px; // trying to match the line height in Exploratron

            div {
                display: inline-block;
                width: 100%;
                color: $black;
                margin: 2px 0;
                padding: 6px 10px;
                font-size: $font-size-small;
                font-weight: $font-weight-regular;
                border-radius: 5px;
                transition: background-color 0.15s linear;

                img,
                svg {
                    vertical-align: sub;
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    margin-right: 10px;
                }
            }

            .tms-checkbox {
                height: auto;
                padding: 6px 10px;

                label {
                    color: $black;
                    text-shadow: none !important;
                    letter-spacing: 0.5px;
                }
            }

            &:not([disabled]) {
                div:hover {
                    color: $black;
                    background-color: $grey-light;
                    cursor: pointer;
                }
            }

            &[disabled] {
                .tms-checkbox label {
                    color: #aaaaaa; // combined with opacity it should roughly match color of div
                }
                div {
                    color: #cccccc;
                }
            }

            &.dropdown-header {
                min-height: 16px;
                padding: 0;
                background-color: transparent !important;

                &:hover {
                    color: $black;
                }

                &.select-all-toggle {
                    padding-left: 10px;
                }

                &:not(.select-all-toggle) {
                    color: #6c757d;
                    &:hover {
                        cursor: default;
                    }
                }
            }

            &.dropdown-divider {
                margin: 5px -8px;
            }
        }

        // Make a little more vertical space when showing
        // headers.
        &.showing-headers .dropdown-divider {
            margin: 8px -10px;
        }

        // Removing the extra padding on the top of the dropdown menu introduced by bootstrap 5
        &[data-bs-popper] {
            margin-top: 0;
        }
    }

    &.compact .dropdown-menu li div {
        margin: 0;
        padding: 2px 10px;
    }
}
</style>
