import type { RootState } from './store.types';

import { createStore } from 'vuex';

import { login } from '@pushspring/auth/ui';
import { TmsFlashMessageUtils } from '@pushspring/tms-client-common';

export default createStore<RootState>({
    state: {
        clientVersion: 0,
        hidePageTitle: false,
        hideInNavBar: false,
        needsReloadOnNavigation: false,
        pageTitle: '',
    },
    mutations: {
        setClientVersion(state, clientVersion) {
            state.clientVersion = clientVersion;
        },
        setNeedsReloadOnNavigation(state, needsReload) {
            state.needsReloadOnNavigation = needsReload;
        },
        setPageTitle(state, payload) {
            if (payload instanceof Object) {
                state.pageTitle = payload.title;
                state.hidePageTitle = payload.hide;
                state.hideInNavBar = payload.hide;
            } else {
                state.pageTitle = payload;
                state.hidePageTitle = false;
                state.hideInNavBar = false;
            }
        },
    },
    actions: {},
    modules: { login },
    plugins: [TmsFlashMessageUtils.storePlugin],
    strict: !!import.meta.env.PROD,
});
