<template>
    <div class="audience-list">
        <!-- Audience List table -->
        <table class="table full-width">
            <thead>
                <tr>
                    <th scope="col">
                        <TmsColumnHeader
                            :column-settings="colSettings"
                            column-name="created_at"
                            @column-change="columnChange"
                            >Created At</TmsColumnHeader
                        >
                    </th>
                    <th scope="col">
                        <TmsColumnHeader :column-settings="colSettings" column-name="name" @column-change="columnChange"
                            >Name</TmsColumnHeader
                        >
                    </th>
                    <th scope="col">
                        <TmsColumnHeader
                            :column-settings="colSettings"
                            column-name="company_name"
                            @column-change="columnChange"
                            >Organization</TmsColumnHeader
                        >
                    </th>
                    <th scope="col">
                        <TmsColumnHeader
                            :column-settings="colSettings"
                            column-name="device_count"
                            @column-change="columnChange"
                            >Device Count</TmsColumnHeader
                        >
                    </th>
                    <th scope="col">
                        <TmsColumnHeader :column-settings="colSettings" column-name="sent" @column-change="columnChange"
                            >Exported</TmsColumnHeader
                        >
                    </th>
                    <th scope="col">
                        <TmsColumnHeader
                            :column-settings="colSettings"
                            column-name="lookalike"
                            @column-change="columnChange"
                            >Lookalike</TmsColumnHeader
                        >
                    </th>
                    <th scope="col">
                        <TmsColumnHeader
                            :column-settings="colSettings"
                            column-name="partnerAttributeNames"
                            @column-change="columnChange"
                            >Included Attributes</TmsColumnHeader
                        >
                    </th>
                    <th scope="col">
                        <TmsColumnHeader
                            :column-settings="colSettings"
                            column-name="contribution_name"
                            @column-change="columnChange"
                            >Contributing Partners</TmsColumnHeader
                        >
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(audience, index) in reportList" :key="index">
                    <td>
                        {{ shortDateTimeDisplay(audience.created_at) }}
                    </td>
                    <td>
                        {{ audience.name }}
                    </td>
                    <td>
                        {{ audience.company_name }}
                    </td>
                    <td>
                        {{ audience.device_count }}
                    </td>
                    <td>
                        {{ audience.sent ? 'Yes' : 'No' }}
                    </td>
                    <td>
                        {{ audience.lookalike ? 'Yes' : 'No' }}
                    </td>
                    <td>
                        {{ audience.partnerAttributeNames.join(', ') }}
                    </td>
                    <td>
                        {{ audience.contribution_name }}
                    </td>
                </tr>
            </tbody>
        </table>
        <TmsPagination
            :current-page="currentPage"
            :total-items="reports.length"
            :items-per-page="itemsPerPage"
            :show-items-per-page="showItemsPerPage"
            @current-page-change="currentPageChange"
            @page-size-change="pageSizeChange"
        />
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import { shortDateTime } from '@pushspring/tms-client-common';

export default defineComponent({
    name: 'AudienceList',
    props: {
        /**
         * @type {{new (): Report[]}}
         */
        reports: {
            type: Array,
            required: true,
        },
        /**
         * @type {{{ column: string; order: string; }}
         */
        colSettings: {
            type: Object,
            required: true,
        },
    },
    emits: ['sort-changed'],
    data() {
        return {
            offset: 0,
            itemsPerPage: 25,
        };
    },
    computed: {
        currentPage() {
            return Math.ceil(this.offset / this.itemsPerPage) + 1;
        },
        showItemsPerPage() {
            return this.reports.length > 24;
        },
        /** @returns { Report[] } */
        reportList() {
            const start = this.currentPage * this.itemsPerPage - this.itemsPerPage;
            const end = this.currentPage * this.itemsPerPage;
            const filteredReports = this.reports.filter((item, index) => index >= start && index <= end);
            return filteredReports;
        },
    },
    methods: {
        /**
         * @param {{ order: string; column: string; }} e
         */
        columnChange(e) {
            this.$emit('sort-changed', e);
        },
        //set offset
        currentPageChange({ currentPage }) {
            this.offset = (currentPage - 1) * this.itemsPerPage;
        },
        //set limit when page size change
        pageSizeChange({ itemsPerPage }) {
            this.itemsPerPage = itemsPerPage;
        },
        //shortdate time display
        shortDateTimeDisplay(value) {
            return value ? shortDateTime(value) : '';
        },
    },
});
</script>
<style lang="scss" scoped>
.audience-list {
    thead,
    tbody,
    tr,
    td,
    tr {
        vertical-align: middle;
    }
}
</style>
