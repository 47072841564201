import axios from 'axios';

/**
 * Creates a singleton instance of a Config Service Class to get and retrieve config and merge local configs
 * into a single service. Potentially configure using the factory pattern to allow multiple config service singleton instances
 */

export class BaseConfigService {
    private static instance: BaseConfigService;
    loaded = false;
    private config: Record<string | symbol, unknown> = {};

    constructor() {
        if (BaseConfigService.instance) {
            return BaseConfigService.instance;
        } else {
            const proxyBaseConfigService = new Proxy(this, {
                get(target: BaseConfigService, prop: string | symbol) {
                    if (prop in target) {
                        return target[prop as keyof BaseConfigService];
                    } else if (prop in target.config) {
                        return target.config[prop];
                    } else {
                        console.warn(`Property "${prop.toString()}" not found in config`);
                        return undefined;
                    }
                },
            });

            BaseConfigService.instance = proxyBaseConfigService;
            return proxyBaseConfigService;
        }
    }

    async load<RT extends Record<string, unknown>, T extends Record<string, unknown>>(
        configUrl?: string,
        localConfig: T = {} as T,
    ): Promise<RT & T> {
        if (!this.loaded && configUrl) {
            const { data } = await axios.get<RT>(configUrl);
            this.config = Object.assign({}, localConfig, data);
            this.loaded = true;
        }
        return this.config as RT & T;
    }

    get<T = unknown>(val: string): T {
        // should we check if loaded before trying to access, and load if not loaded?
        const res = val.split('.').reduce((a: any, b: string) => a[b], this.config);
        return res;
    }
}

/**
 * Proxy of BaseConfiguration that allows accessing the elements of config as properties
 */
