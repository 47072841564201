<template>
    <div class="tms-search-field" :class="$attrs.class">
        <TmsIcon class="svg-icon" name="tms-search" />
        <input
            v-bind="$attrs"
            ref="searchInput"
            v-tms-focus
            type="text"
            :value="modelValue"
            :placeholder="placeholder"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="off"
            :spellcheck="false"
            @input="onInput"
        />
        <button v-if="clearable && hasText" class="clear-text-button" type="button" @click="onClearInput">
            <TmsIcon class="svg-icon" name="fthr-x" />
        </button>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

import { IconRegistry, TmsIcon } from '@pushspring/common-ui/icons-core';
import { fthrX } from '@pushspring/common-ui/icons-feather';
import { tmsSearch } from '@pushspring/common-ui/icons-tms';

import { vTmsFocus } from '../directives';

IconRegistry.add(fthrX, tmsSearch);

export default defineComponent({
    components: {
        TmsIcon,
    },
    directives: {
        tmsFocus: vTmsFocus,
    },
    inheritAttrs: false,
    props: {
        placeholder: {
            type: String,
            default: '',
        },
        clearable: {
            type: Boolean,
            default: true,
        },
        modelValue: {
            type: String,
            default: '',
        },
    },
    emits: ['update:modelValue', 'input'],
    data() {
        return {
            hasText: false,
        };
    },
    methods: {
        onInput(e) {
            const input = e.target.value;
            this.$emit('update:modelValue', input);
            this.$emit('input', input);
            this.hasText = Boolean(input);
        },
        onClearInput() {
            this.$refs.searchInput.value = '';
            this.$emit('update:modelValue', '');
            this.$emit('input', '');
            this.hasText = false;
        },
    },
});
</script>

<style lang="scss" scoped>
@import '../styles/design-language-variables';

.tms-search-field {
    display: flex;
    align-items: center;
    background-color: $background-color-lightest;
    input[type='text'] {
        background-color: transparent;
        width: 100%;
        height: 40px;
        font-size: 16px;
        font-weight: 200;
        margin-left: 10px;
        border-style: none;
        border-radius: 0;
        outline: none;
        box-shadow: none;
    }

    .svg-icon {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: auto;
        height: 22px;
        width: 22px;
        color: #565657;
    }

    .clear-text-button {
        background-color: transparent;
        border: none;
    }
}
</style>
