import axios from 'axios';
export async function listReports() {
    try {
        const { data } = await axios.get('/api/report/listHistoricalReports', {});
        return data;
    } catch (e) {
        throw new Error('There was an error getting the historical reports.');
    }
}

export async function getReportLink(location) {
    try {
        const { data } = await axios.get('/api/report/link', { params: { location: location } });
        return data;
    } catch (e) {
        throw new Error('There was an error getting the report location.');
    }
}
