<!--
    Shows a user provided info message
    color-coded for a level:  error, warning, loading, info, success
-->
<template>
    <div>
        <transition-group
            id="tms-flash-msgs"
            :name="animate ? 'messages' : ''"
            tag="ul"
            :class="{ 'in-header': inHeader }"
            @leave="removeMessageAnimationStarted"
        >
            <li v-for="m in messages" :id="'message-' + m.id" :key="m.id" :class="[m.level]">
                <div v-if="m.level === 'loading'" v-tms-spinner="{ scale: 0.5, width: 2 }" class="loading-spinner" />

                <TmsIcon v-if="m.level !== 'loading'" :name="levelToComponentMapping[m.level]" class="message-icon" />

                <span class="message-text">{{ m.text }}</span>

                <div class="dismiss-message-button" aria-label="Close" @click="dismiss(m.id)">
                    <TmsIcon name="fthr-x" size="24" aria-hidden="true" />
                </div>
            </li>
        </transition-group>
    </div>
</template>

<script>
import JQuery from 'jquery';
import { defineComponent } from 'vue';

import { IconRegistry, TmsIcon } from '@pushspring/common-ui/icons-core';
import {
    fthrCheckCircle,
    fthrCircle,
    fthrInfo,
    fthrLoader,
    fthrX,
    fthrXOctagon,
} from '@pushspring/common-ui/icons-feather';

import { vTmsSpinner } from '../directives';
import TmsFlashMessages from '../TmsFlashMessages/TmsFlashMessageUtils';

IconRegistry.add(fthrCircle, fthrInfo, fthrLoader, fthrX, fthrXOctagon, fthrCheckCircle);

const RemoveMessageDuration = 0.5;

export default defineComponent({
    name: 'TmsFlashMessages',
    components: {
        TmsIcon,
    },
    directives: {
        tmsSpinner: vTmsSpinner,
    },
    props: {
        inHeader: { type: Boolean, default: false },
    },
    emits: ['tms-flash-messages-deleted'],
    data() {
        return {
            transientMessageIds: [],
            animate: true, // Use this to control when to animate the removal of messages
            unsubscribeStoreSubscription: null,
            levelToComponentMapping: {
                [TmsFlashMessages.Level.Error]: fthrXOctagon.name,
                [TmsFlashMessages.Level.Info]: fthrInfo.name,
                [TmsFlashMessages.Level.Warning]: fthrInfo.name,
                [TmsFlashMessages.Level.Success]: fthrCheckCircle.name,
                [TmsFlashMessages.Level.Loading]: fthrLoader.name,
            },
        };
    },
    computed: {
        messages() {
            return this.$store.state.flashMessages.messages;
        },
    },
    watch: {
        '$store.state.flashMessages.messages': {
            handler(newVal) {
                if (newVal && newVal.length > 0) {
                    const transientIds = newVal
                        .filter((m) => m.fadeOut)
                        .map((m) => m.id)
                        .filter((id) => !this.transientMessageIds.includes(id));

                    if (transientIds.length > 0) {
                        this.transientMessageIds.push(...transientIds);

                        setTimeout(() => {
                            transientIds.forEach((id) => {
                                this.setHeightOnMessage(id);
                                this.$store.commit('flashMessages/delete', id);
                            });
                        }, 5000);
                    }
                }
            },
            deep: true,
        },
    },
    mounted: function () {
        this.$store.commit('flashMessages/clear'); // cleanup any messages that were not dismissed

        // Turn off animation until the next update when we clear all messages
        // so the messages are cleared immediately and not animated away.
        this.unsubscribeStoreSubscription = this.$store.subscribe((mutation) => {
            if (mutation.type === 'flashMessages/clear') {
                this.animate = false;
            }
        });

        // Turns out we CAN have multiple on a page if the second one is in a modal.
        // If we introduce modal scopes then this should look for others with the same
        // scope.
        // if (document.querySelectorAll("#tms-flash-msgs").length > 1) {
        //     throw new Error("There should only be one instance of TmsFlashMessages on the page");
        // }
    },
    updated() {
        // Always set to true to revert back to animation after any update.
        this.animate = true;
    },
    unmounted() {
        if (this.unsubscribeStoreSubscription) {
            this.unsubscribeStoreSubscription();
            this.unsubscribeStoreSubscription = null;
        }
    },
    methods: {
        dismiss(btnId) {
            this.setHeightOnMessage(btnId);
            this.$store.commit('flashMessages/delete', btnId);
        },
        // This needs to be done before the start of the dismiss animation
        // to smoothly animate the height transition. It would normally need
        // to be set back to auto but the element is being removed so we can
        // skip that.
        setHeightOnMessage(id) {
            const el = this.$el.querySelector(`#message-${id}`);
            if (el) {
                el.style.height = JQuery(el).outerHeight() + 'px';
            }
        },
        removeMessageAnimationStarted() {
            this.$emit('tms-flash-messages-deleted', RemoveMessageDuration);
        },
    },
});
</script>

<style lang="scss">
@import '../styles/design-language-variables';

#tms-flash-msgs .error .message-icon,
.next-previous-step .error .message-icon {
    stroke: #ff3333;
}

#tms-flash-msgs .warning .message-icon {
    stroke: #ffaa00;
}

#tms-flash-msgs .loading .message-icon {
    stroke: $black;
}

#tms-flash-msgs .info .message-icon {
    stroke: #1199ee;
}

#tms-flash-msgs .success .message-icon {
    stroke: #20d964;
}

#tms-flash-msgs {
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: relative;

    * {
        box-sizing: border-box;
    }

    li {
        font-family: $default-font-stack;
        font-weight: $font-weight-medium;
        font-size: $font-size-regular;
        color: $black;
        display: flex;
        align-items: center;
        padding: 10px 0;
        overflow: hidden;

        .message-icon {
            width: 20px;
            height: 20px;
            stroke-width: 2;
            stroke-linecap: round;
            stroke-linejoin: round;
            fill: none;
            flex-shrink: 0;
        }

        .loading-spinner {
            position: relative;
            width: 20px;
            height: 20px;
            flex-shrink: 0;
        }

        .message-text {
            flex-grow: 2;
            margin-left: 16px;
            margin-right: 16px;
            text-align: left;
        }

        .dismiss-message-button {
            cursor: pointer;
            height: 24px;
            width: 24px;
            border-radius: 5px;
            transition: background-color 0.2s ease-in-out;

            svg {
                width: 16px;
                height: 16px;
                stroke: $grey-dark;
                stroke-width: 3;
                transition: stroke 0.2s ease-in-out;
                vertical-align: top;
            }

            &:hover {
                background-color: $grey-light;
                width: 24px;
                height: 24px;

                svg {
                    stroke: $black;
                }
            }
        }
    }

    // When in the header, each message has a bottom border
    // so that the last one acts as the border for the entire
    // messages component.
    &.in-header {
        li {
            padding: 10px $browser-side-offset;
            border-bottom: 1px solid $grey-mid;
        }
    }

    // When embedded in the page, each message following the
    // first has a top border to act as a separator. The page
    // is free to add borders to the entire component if it
    // wishes.
    &:not(.in-header) {
        li + li {
            border-top: 1px solid $grey-mid;
        }
    }
}

@keyframes hide-message {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        height: 0;
        padding-top: 0;
        padding-bottom: 0;
        margin-top: 0;
        margin-bottom: 0;
    }
}

.messages-leave-active {
    animation: hide-message 0.5s;
}
</style>
