<template>
    <div class="reports">
        <div class="page-header-controls">
            <span class="flex-grow-1" />
            <!-- Report Type Dropdown -->
            <TmsDropdown
                v-model="typeSelection"
                :options="typeOptions"
                :disabled="isApiKeyError"
                :title="safeTypeDropdownTitle(typeSelection)"
                @change="typeChange(typeSelection[0].id)"
            />
        </div>

        <!-- Used when has loading state -->
        <div v-if="status.reportsLoading" v-tms-spinner="{ width: 3, scale: 2 }" />
        <!-- Report List -->
        <div v-else-if="isResults && !status.reportsLoading">
            <HistoricalList
                :reports="reports"
                :col-settings="colSettings"
                @report-location="getReportLocation"
                @sort-changed="columnChange"
            />
        </div>

        <!-- Else No Reports-->
        <div v-else>
            <h6>No reports found!</h6>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import { Marketplace } from '@pushspring/ps-common/lib/constants-public';
import { TmsFlashMessageUtils } from '@pushspring/tms-client-common';

import { getApiKey } from '../../manage/utils/apikeys';
import HistoricalList from '../components/HistoricalList.vue';
import { getReportLink, listReports } from '../utils/historical';

export default defineComponent({
    name: 'HistoricalPage',
    components: { HistoricalList },
    data() {
        return {
            reports: [],
            apiKey: '',
            originalReports: [],
            typeSelection: [],
            typeSelected: '',
            status: {
                reportsLoading: false,
            },
            colSettings: { column: 'name', order: 'asc' },
        };
    },
    computed: {
        //report type dropdown options
        typeOptions() {
            const dropdownoptions = Marketplace.PartnerReports.Type.map((type) => {
                return { id: type.id, title: 'Type: ' + type.name };
            });
            dropdownoptions.push({ id: 6, title: 'Type: All' });
            return dropdownoptions;
        },
        //check if show results
        isResults() {
            return this.reports.length > 0;
        },
        //check if apikey has error
        isApiKeyError() {
            return this.apiKey === 'error';
        },
    },
    async created() {
        //set key for accessing api
        try {
            TmsFlashMessageUtils.clear();
            await getApiKey();
        } catch (e) {
            this.apiKey = 'error';
            TmsFlashMessageUtils.replace('error', 'There was an error accessing an api key.');
            return;
        }
        //load all reports
        this.getReports();
    },
    methods: {
        //type dropdown change
        typeChange(type) {
            if (this.$route.query.reportType !== type) {
                this.$router.replace({ query: { reportType: type } });
            }
            this.reports = this.filterTypeSelection(type);
        },

        //filter report type
        filterTypeSelection(type) {
            if (type !== 6) {
                return this.originalReports.filter((report) => report.type === parseInt(type));
            } else {
                return this.originalReports;
            }
        },

        //get all reports
        async getReports() {
            this.status.reportsLoading = true;
            TmsFlashMessageUtils.clear();
            try {
                this.reports = await listReports();
                this.originalReports = this.reports;
                //if query params reportType has value then set dropdown selection and filter reports
                //otherwise set to '6 : all'
                if (Object.keys(this.$route.query).length === 0) {
                    this.$router.push({
                        query: {
                            reportType: 6,
                        },
                    });
                } else {
                    const reportType = parseInt(this.$route.query?.reportType);
                    if (reportType === 6) {
                        this.typeSelected = 'Type: All';
                    } else {
                        this.typeSelected =
                            'Type: ' +
                            Marketplace.PartnerReports.Type.find((report) => {
                                return report.id === reportType;
                            }).name;
                    }
                    this.reports = this.filterTypeSelection(reportType);
                }
            } catch (e) {
                TmsFlashMessageUtils.replace('error', e);
            } finally {
                this.status.reportsLoading = false;
            }
        },

        //get report location
        async getReportLocation(location) {
            TmsFlashMessageUtils.clear();
            try {
                const response = await getReportLink(location);
                window.open(response, '_blank');
            } catch (e) {
                TmsFlashMessageUtils.replace('error', e);
            }
        },

        //set type dropdown title
        safeTypeDropdownTitle(selection) {
            return selection[0]?.title ? selection[0]?.title : this.typeSelected || 'Type: All';
        },

        //filter results based on column settings
        filtered({ column, order }) {
            let filtered = this.reports;
            const compare = (a, b) => (a[column] ?? '')?.toString().localeCompare((b[column] ?? '')?.toString());
            if (order === 'desc') {
                filtered = filtered.sort((a, b) => compare(b, a));
            } else {
                filtered = filtered.sort(compare);
            }
            return filtered;
        },

        //if column order change then filter results
        columnChange(settings) {
            this.colSettings.order = settings.order;
            this.colSettings.column = settings.column;
            this.reports = this.filtered(this.colSettings);
        },
    },
});
</script>
<style lang="scss" scoped>
.reports {
    .tms-button {
        margin-left: 8px;
    }

    h6 {
        text-align: center;
        font-size: 22px;
        padding: 10px;
    }
    .dropdown {
        &:deep() {
            .dropdown-menu {
                right: 0;
                left: auto;
            }
        }
    }
}
</style>
