import axios from 'axios';

export async function checkGdprLocation() {
    try {
        const { data } = await axios.get('https://api.magentamarketing.com/gdpr');
        return data;
    } catch (e) {
        throw 'There was an error getting gdpr location.';
    }
}
