<template>
    <div class="imports">
        <div class="page-header-controls align-items-center">
            <!-- Search Imports -->
            <TmsSearchField
                v-model.trim="search"
                placeholder="Search file path "
                aria-label="search"
                class="flex-grow-1"
                @input="onSearchInputChange"
            />
            <!-- New Import-->
            <TmsButton class="teritary" type="submit" :disabled="isApiKeyError" @click="openImportModal">
                New Import</TmsButton
            >
        </div>

        <!-- Used when has loading state -->
        <div v-if="status.importsLoading" v-tms-spinner="{ width: 3, scale: 2 }" />
        <!-- Import List -->
        <div v-else-if="isResults && !status.importsLoading">
            <ImportList :imports="imports" :col-settings="colSettings" @sort-changed="columnChange" />
        </div>

        <!-- Else No Imports-->
        <div v-else>
            <h6>No Imports found!</h6>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import { PartnerIngestion, PartnerImport } from '@pushspring/ps-common/lib/constants-public';
import { TmsFlashMessageUtils } from '@pushspring/tms-client-common';

import ImportList from '../components/ImportList.vue';
import NewImportModal from '../components/NewImportModal.vue';
import { getApiKey } from '../utils/apikeys';
import { listImports } from '../utils/imports';

export default defineComponent({
    name: 'ImportsPage',
    components: { ImportList },
    data() {
        return {
            search: '',
            imports: [],
            apiKey: '',
            status: {
                importsLoading: false,
            },
            colSettings: { column: 'name', order: 'asc' },
            originalImports: [],
        };
    },
    computed: {
        //check if show results
        isResults() {
            return this.imports.length > 0;
        },
        //check if apikey has error
        isApiKeyError() {
            return this.apiKey === 'error';
        },
    },
    async created() {
        //set key for accessing api
        try {
            TmsFlashMessageUtils.clear();
            await getApiKey();
        } catch (e) {
            this.apiKey = 'error';
            TmsFlashMessageUtils.replace('error', 'There was an error accessing an api key.');
            return;
        }

        //load all imports
        this.getImports();
        //remove after and before space on search input / set search params
        this.search = this.$route.query?.term ? this.$route.query?.term : this.search.replace(/\s/g, '');

        //check if query params has column and order then set it
        if (this.$route.query?.column) {
            this.colSettings.column = this.$route.query?.column;
            this.colSettings.order = this.$route.query?.order;
        }
    },
    methods: {
        //get all imports
        async getImports() {
            this.status.importsLoading = true;
            TmsFlashMessageUtils.clear();
            try {
                const imports = await listImports();
                //map status and keytype on page load
                this.imports = imports.map((row) => {
                    const importData = { ...row };
                    importData.status = this.mapStatus(row.status);
                    importData.key_type = this.mapKeyType(row.key_type);
                    return importData;
                });
                this.originalImports = this.imports;

                //if query params term has value then filter imports
                if (this.$route.query?.term) {
                    this.imports = this.filtered(this.colSettings, this.search);
                }
            } catch (e) {
                TmsFlashMessageUtils.replace('error', e);
            } finally {
                this.status.importsLoading = false;
            }
        },
        //map key type
        mapKeyType(keyTypeId) {
            for (const [key, value] of Object.entries(PartnerIngestion.KeyType)) {
                if (value === keyTypeId) return key || 'PushSpring';
            }
        },
        //map status
        mapStatus(statusId) {
            for (const [key, value] of Object.entries(PartnerImport.Status)) {
                if (value === statusId) return key || 'Unknown';
            }
        },
        //open new import modal
        async openImportModal() {
            const response = await this.tmsShowModal(NewImportModal);
            //if has status success then load all imports with new import
            if (response) {
                this.getImports();
                TmsFlashMessageUtils.add('success', 'New Import added successfully.');
            }
        },

        //search input change then set router query params and filter results
        onSearchInputChange(value) {
            if (this.$route.query.term !== value && value) {
                this.imports = this.filtered(this.colSettings, value);
            } else if (value.length === 0) {
                this.imports = this.originalImports;
            }
            this.$router.replace({ query: { term: value, ...this.colSettings } });
        },

        //filter results based on column settings and search value
        filtered({ column, order }, search) {
            let filtered = this.originalImports;
            const compare = (a, b) => (a[column] ?? '')?.toString().localeCompare((b[column] ?? '')?.toString());
            if (search) {
                filtered = this.originalImports.filter((log) =>
                    log.file_path.toLowerCase().includes(search.toLowerCase()),
                );
            }
            if (order === 'desc') {
                filtered = filtered.sort((a, b) => compare(b, a));
            } else {
                filtered = filtered.sort(compare);
            }
            return filtered;
        },

        //if column order change then filter results
        columnChange(settings) {
            this.colSettings.order = settings.order;
            this.colSettings.column = settings.column;
            //set query params as well
            this.$router.replace({ query: { term: this.search, ...this.colSettings } });
            this.imports = this.filtered(this.colSettings, this.search);
        },
    },
});
</script>
<style lang="scss" scoped>
.imports {
    .tms-button {
        margin-left: 8px;
    }

    h6 {
        text-align: center;
        font-size: 22px;
        padding: 10px;
    }
}
</style>
