// Register global components here.
// Only the really common components should be registered globally, the other can
// be registered locally where necessary.
import vSelect from 'vue-select';

import {
    TmsButton,
    TmsCheckbox,
    TmsColumnHeader,
    TmsDropdown,
    TmsSimpleDropdown,
    TmsPagination,
    TmsS3Upload,
    TmsSearchField,
    TmsToggleSwitch,
} from '@pushspring/tms-client-common';

import TmsSimpleFooter from './TmsSimpleFooter.vue';

export function registerGlobalComponents(app) {
    // eslint-disable-next-line vue/component-definition-name-casing
    app.component('v-select', vSelect);

    app.component('TmsButton', TmsButton);
    app.component('TmsCheckbox', TmsCheckbox);
    app.component('TmsToggleSwitch', TmsToggleSwitch);
    app.component('TmsColumnHeader', TmsColumnHeader);
    app.component('TmsDropdown', TmsDropdown);
    app.component('TmsS3Upload', TmsS3Upload);
    app.component('TmsSimpleFooter', TmsSimpleFooter);
    app.component('TmsSearchField', TmsSearchField);
    app.component('TmsSimpleDropdown', TmsSimpleDropdown);
    app.component('TmsPagination', TmsPagination);
}
