// Register global directives here.
// Only the really common directives should be registered globally, the other can
// be registered locally where necessary.
import { authorizedRolesDirective } from '@pushspring/auth/ui';
import { vTmsSpinner, vTmsFocus } from '@pushspring/tms-client-common';

export function registerGlobalDirectives(app) {
    app.directive('TmsSpinner', vTmsSpinner);
    app.directive('TmsFocus', vTmsFocus);
    authorizedRolesDirective(app);
}
