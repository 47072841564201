import { addNamespace } from './addNamespace';

const Mutations = {
    SET_USER_PROFILE: 'SET_USER_PROFILE',
    SET_TOKEN: 'SET_TOKEN',
    SET_TOKEN_EXPIRATION: 'SET_TOKEN_EXPIRATION',
    CLEAR_TOKEN: 'CLEAR_TOKEN',
    CLEAR_USER_PROFILE: 'CLEAR_USER_PROFILE',
    CLEAR_TOKEN_EXPIRATION: 'CLEAR_TOKEN_EXPIRATION',
};

export const {
    SET_USER_PROFILE: _SET_USER_PROFILE,
    SET_TOKEN: _SET_TOKEN,
    SET_TOKEN_EXPIRATION: _SET_TOKEN_EXPIRATION,
    CLEAR_TOKEN: _CLEAR_TOKEN,
    CLEAR_USER_PROFILE: _CLEAR_USER_PROFILE,
    CLEAR_TOKEN_EXPIRATION: _CLEAR_TOKEN_EXPIRATION,
} = Mutations;

export const {
    SET_USER_PROFILE,
    SET_TOKEN,
    SET_TOKEN_EXPIRATION,
    CLEAR_TOKEN,
    CLEAR_USER_PROFILE,
    CLEAR_TOKEN_EXPIRATION,
} = addNamespace(Mutations);
