<template>
    <div id="app" class="new-design" :class="[`${rootClass}-page-root`, { 'left-nav-showing': !isFullScreen }]">
        <PartnerLeftNavLayout v-if="!isFullScreen" />
        <TmsHorizontalNavBar
            v-if="!isFullScreen"
            id="the-horizontal-nav-bar"
            home-url="/developertest"
            :hide-default-row="true"
        />
        <RouterView class="page-content" />
        <div id="modal-root" />
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { USER_PROFILE, FULL_NAME } from '@pushspring/auth/ui';
import { IconRegistry } from '@pushspring/common-ui/icons-core';
import { fthrLogOut, fthrMenu } from '@pushspring/common-ui/icons-feather';
import { TmsHorizontalNavBar, TmsFlashMessageUtils, requiresAuth } from '@pushspring/tms-client-common';

import PartnerLeftNavLayout from './components/PartnerLeftNavLayout.vue';
import ApiKeyModal from './manage/components/ApiKeyModal.vue';
import { navLinks } from './navLinks';
import { ConfigService } from './utils/ConfigService';
import { checkGdprLocation } from './utils/gdpr';

IconRegistry.add(fthrLogOut, fthrMenu);

const clientConfig = new ConfigService();

export default defineComponent({
    components: {
        TmsHorizontalNavBar,
        PartnerLeftNavLayout,
    },
    data() {
        return {
            navLinks,
        };
    },
    computed: {
        loginProfile() {
            return this.$store.getters[FULL_NAME] ?? 'No name';
        },
        rootClass() {
            return this.$route.path.split('/').find((item) => item.length > 0);
        },
        companyName() {
            return this.$store.getters[USER_PROFILE]?.companyName ?? 'No company name';
        },
        isFullScreen() {
            // For the moment isFullScreen matches with whether authentication
            // is required. This could change in the future.
            return !requiresAuth(this.$route);
        },
    },
    watch: {
        // Monitor for changes to the pageTitle and update the browser's page title.
        '$store.state.pageTitle': {
            handler: function (newTitle, oldTitle) {
                if (newTitle && newTitle !== oldTitle) {
                    document.title = 'MMP - ' + newTitle;
                }
            },
            immediate: true,
        },
    },
    async created() {
        //check gdpr location and throw message for non-us users
        try {
            const response = await checkGdprLocation();
            if (response.gdpr === true) {
                TmsFlashMessageUtils.add(
                    'error',
                    "Your region has limited access to some features due to T-Mobile Advertising Solutions' GDPR compliance. If you need assistance, please contact DataPartnerSupport@T-Mobile.com",
                );
            }
        } catch (e) {
            TmsFlashMessageUtils.add('error', e);
        }
    },
    methods: {
        logoutProfile() {
            delete window.sessionStorage.token;
            delete window.sessionStorage.apikey;
            if (!window.sessionStorage.token && !window.sessionStorage.apikey) {
                window.location.href =
                    clientConfig.exploratronPrefix +
                    '/logout?redirectUrl=' +
                    window.location.protocol +
                    '//' +
                    window.location.host +
                    '/';
            }
        },
        //when click on api modal
        async openKeyModal() {
            await this.tmsShowModal(ApiKeyModal);
        },
    },
});
</script>

<style lang="scss">
@import '@pushspring/tms-client-common/styles/_page-layout.scss';

//header dropdown css
@import './styles/headerdropdown.scss';

/* This should be replaced by a global normalize stylesheet */
body {
    padding: 0;
    margin: 0;
}

#app .logout-button {
    margin-left: 10px;
}

#app {
    font-family: $default-font-stack;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $black;

    .nav {
        padding: 16px 0;
        border-bottom: 1px solid $grey-mid;

        a {
            padding: 4px;
        }
    }

    &.left-nav-showing {
        padding-left: $left-nav-width;
    }

    .router-view {
        position: relative;
    }

    .horizontal-nav-bar {
        z-index: 1021;
    }
}
</style>
