import { addNamespace } from './addNamespace';

const Getters = {
    USER_PROFILE: 'USER_PROFILE',
    TOKEN: 'TOKEN',
    TOKEN_EXPIRATION: 'TOKEN_EXPIRATION',
    FULL_NAME: 'FULL_NAME',
};

// for internal store use only
export const {
    USER_PROFILE: _USER_PROFILE,
    TOKEN: _TOKEN,
    TOKEN_EXPIRATION: _TOKEN_EXPIRATION,
    FULL_NAME: _FULL_NAME,
} = Getters;

export const { USER_PROFILE, TOKEN, TOKEN_EXPIRATION, FULL_NAME } = addNamespace(Getters);
