<template>
    <nav class="nav-pane">
        <div class="nav-group">
            <RouterLink
                :id="navHeaderLogo.id"
                class="nav-header-logo"
                :name="navHeaderLogo.id"
                :to="navHeaderLogo.route"
            >
                <TmsIcon name="tms-map-logo" alt="Magenta Advertising Platform" />
            </RouterLink>
        </div>

        <div v-if="$slots.default" id="userMetadataSlot" class="nav-group">
            <slot />
        </div>

        <ul class="nav-group nav-item-list list-unstyled">
            <RouterLink
                v-for="(primaryLink, idx) of filteredNavLinks"
                :key="primaryLink.id"
                v-slot="{ navigate: primaryNavigate, href: primaryHref, isActive: primaryIsActive }"
                custom
                :to="primaryLink.route"
            >
                <li ref="primaryLinkRefs" class="nav-item">
                    <a
                        :id="primaryLink.id"
                        :href="primaryHref"
                        class="nav-link primary"
                        :class="{ active: primaryIsActive || hasActiveSecondary(idx) }"
                        @click="primaryNavigate"
                    >
                        <TmsIcon v-if="primaryLink.iconName" :name="primaryLink.iconName" /><span>{{
                            primaryLink.title
                        }}</span>
                    </a>

                    <ul
                        v-if="primaryLink?.children?.length && primaryLink.children.length > 0"
                        class="secondary-group list-unstyled"
                        :class="{ active: primaryIsActive || hasActiveSecondary(idx) }"
                    >
                        <RouterLink
                            v-for="secondaryLink of primaryLink.children"
                            :key="secondaryLink.id"
                            v-slot="{ navigate: secondaryNavigate, href: secondaryHref, isActive: secondaryIsActive }"
                            custom
                            :to="secondaryLink.route"
                        >
                            <li>
                                <a
                                    :id="secondaryLink.id"
                                    :href="secondaryHref"
                                    class="nav-link secondary"
                                    :class="{ active: secondaryIsActive }"
                                    @click="secondaryNavigate"
                                    >{{ secondaryLink.title }}
                                </a>
                            </li>
                        </RouterLink>
                    </ul>
                </li>
            </RouterLink>
        </ul>

        <ul class="nav-group list-unstyled">
            <li class="nav-item">
                <a
                    v-if="contact"
                    href="javascript:void(0)"
                    class="nav-link fw-medium"
                    @click.prevent.stop="openContactModal()"
                >
                    <TmsIcon name="fthr-send" /> Contact Us
                </a>
            </li>
            <li v-if="docsUrl" class="nav-item">
                <a :href="docsUrl" target="_blank" class="nav-link fw-medium"
                    ><TmsIcon name="fthr-book-open" /> Documentation
                </a>
            </li>
        </ul>
    </nav>
</template>

<script lang="ts">
import type { NavLink } from './typedefs';
import type { PropType } from 'vue';

import { computed, defineComponent, nextTick, ref, toValue, unref, watch } from 'vue';
import { useRoute } from 'vue-router';

import { IconRegistry, TmsIcon } from '@pushspring/common-ui/icons-core';
import { fthrBookOpen, fthrSend } from '@pushspring/common-ui/icons-feather';
import { tmsMapLogo } from '@pushspring/common-ui/icons-tms';

import TmsContactUsModal from '../TmsContactUs/TmsContactUsModal.vue';
import { useAssetServer } from '../utils/assetUtils';

IconRegistry.add(tmsMapLogo, fthrBookOpen, fthrSend);

// if show is not set default value for show will be true
const shouldShowLink = ({ show = true }: NavLink) => unref(toValue(show));

const mapLink = ({ route, title, children, id, iconName }: NavLink): NavLink => ({
    id,
    title,
    route,
    iconName,
    children: children ? children.filter(shouldShowLink).map(mapLink) : undefined,
});

export default defineComponent({
    name: 'TmsLeftNavLayout',
    components: {
        TmsIcon,
    },
    props: {
        homeUrl: {
            type: String,
            default: '/',
        },

        navLinks: {
            type: Array as PropType<NavLink[]>,
            required: true,
        },
        docsUrl: {
            type: String,
            default: null,
        },
        contact: {
            type: String,
            default: null,
        },
        email: {
            type: String,
            default: null,
        },
    },
    setup(props) {
        const primaryLinkRefs = ref<HTMLElement[]>([]);
        const activePrimaryIndex = ref(-1);
        const route = useRoute();

        const navHeaderLogo = computed(() => ({
            id: 'leftNavLogoLink',
            route: props.homeUrl,
        }));

        const filteredNavLinks = computed(() => props.navLinks.filter(shouldShowLink).map(mapLink));

        const hasActiveSecondary = (index: number) => activePrimaryIndex.value === index;

        const setActivePrimaryIndex = async () => {
            activePrimaryIndex.value = -1; // we need to set to -1 so that the active class is removed from the previous active link
            await nextTick(); // we need to wait for the dom to update after the index value changes
            // use the querySelectorAll to find the current primary link to decouple the router from the navlink config
            activePrimaryIndex.value = primaryLinkRefs.value.findIndex(
                (c) => c.querySelectorAll('ul .active').length > 0,
            );
        };

        // watch the for route changes and if the route changes update the primary index
        watch(route, async () => await setActivePrimaryIndex(), { immediate: true });

        return {
            primaryLinkRefs,
            navHeaderLogo,
            filteredNavLinks,
            hasActiveSecondary,
            useAssetServer,
        };
    },

    methods: {
        // we need to setup a composable for tmsShowModal to completely transform to setup
        openContactModal() {
            const navNames = this.navLinks.map((item, index) => ({ id: index, title: item.title }));
            const topics = [...navNames, { id: navNames.length, title: 'Other' }];
            // FIXME: when we change the implementation of TmsModal we should be able to fix this
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            this.tmsShowModal(TmsContactUsModal, { topics, email: this.email });
        },
    },
});
</script>

<style lang="scss" scoped>
@import '../styles/design-language-variables';

.nav-pane {
    background-color: $white;
    border-right: 1px solid $border-grey;
    width: $left-nav-width;
    min-width: $left-nav-width;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    z-index: 1031; // Gets the menu dropdown above a possible horizontal nav bar

    #userMetadataSlot {
        min-height: 60px;
    }

    .nav-item-list {
        flex-grow: 1;
        overflow-y: auto;
    }

    .nav-group {
        padding: 12px;

        &:not(:last-child) {
            border-bottom: 1px solid $border-grey;
        }

        .nav-header-logo {
            width: 128px;
            height: 35px;
            display: block;
            margin: auto;
        }

        &.nav-item-list {
            list-style: none;
            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }

        .nav-item {
            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }

        .secondary-group {
            display: none;
            &:last-child {
                margin-bottom: 6px;
            }
            &.active {
                display: inline-block;
            }
        }
        .nav-link {
            color: $grey-59;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            line-height: 1;
            text-decoration: none;

            svg {
                width: 16px;
                margin-right: 12px;
                stroke: $grey-59;
            }

            &:hover {
                color: $black;
                svg {
                    stroke: $black;
                }
            }

            &.active {
                svg {
                    stroke: $magenta-100;
                }
                color: $black;
            }

            &.primary {
                padding: 6px 6px 6px 0px;
                font-weight: $font-weight-bold;
                font-size: $font-size-regular;
            }

            &.secondary {
                padding: 5px 17px;
                border-left: 2px solid $grey-11;
                margin-left: 7px;
                font-weight: $font-weight-regular;
                font-size: $font-size-small;

                &.active {
                    border-left-color: $magenta-100;
                    color: $black;
                    font-weight: $font-weight-medium;
                }
            }
        }
    }
}
</style>
./TmsLeftNavLayout.vue
