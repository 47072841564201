import { commonConfig } from './commonConfig';

// Prepend the asset server to assets in production
function urlToAsset(localUrl: string, assetServer?: string) {
    const httpIndex = localUrl?.indexOf('http');
    if (assetServer && localUrl && (httpIndex === -1 || httpIndex > 0)) {
        return assetServer + localUrl;
    } else {
        return localUrl;
    }
}

/**
 * Takes a path to a local asset and returns it with the clientConfig.assetServer prepended
 *
 * @param {string} localUrl path to local asset
 * @requires commonConfig.assetServer
 * @returns {string}
 */
export function useAssetServer(localUrl: string) {
    return urlToAsset(localUrl, commonConfig.assetServer);
}
