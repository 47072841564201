import { createRouter, createWebHistory } from 'vue-router';

import { USER_PROFILE, TOKEN, SET_USER_PROFILE } from '@pushspring/auth/ui';
import { OrgRoles } from '@pushspring/ps-common/lib/constants-public';

import store from '../store';
import { ConfigService } from '../utils/ConfigService';
import DeveloperTestPage from '../views/DeveloperTestPage.vue';

import { manageRoutes } from './manage.routes';
import { reportRoutes } from './report.routes';

const clientConfig = new ConfigService();

const _redirectBase = '/login?redirectUrl=';

/** @type { import('vue-router').RouteRecordRaw[] } */
const routes = [
    {
        path: '/',
        redirect: '/manage',
    },
    {
        path: '/manage',
        children: manageRoutes,
    },
    {
        path: '/report',
        children: reportRoutes,
    },
    {
        path: '/developertest',
        name: 'DeveloperTest',
        component: DeveloperTestPage,
    },

    // Catch all route
    {
        path: '/:pathMatch(.*)*',
        redirect: () => {
            window.location.href = '/';
        },
    },
];

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
});

let _initializedVuexFromSession = false;

router.beforeEach(function (to, from, next) {
    if (!_initializedVuexFromSession) {
        if (window.sessionStorage.userProfile) {
            store.commit(SET_USER_PROFILE, JSON.parse(window.sessionStorage.userProfile));
        }

        if (!store.getters[TOKEN]) {
            const { protocol, host, pathname, hash, search } = location;
            const encodedHash = encodeURIComponent(hash);
            const encodedSearch = encodeURIComponent(search);
            window.location = `${clientConfig.exploratronPrefix}${_redirectBase}${protocol}//${host}/${pathname}${encodedHash}${encodedSearch}`;
            return;
        }

        // Check for role access for site
        // debugger;
        if (!store.getters[USER_PROFILE].orgRoles.includes(OrgRoles.Partnertron)) {
            window.location = '/404.html';
            return;
        }

        _initializedVuexFromSession = true;
    }
    next();
});

router.afterEach(function (to) {
    // Update pageTitle in store with pageTitle from route
    if (to && to.meta && to.meta.pageTitle) {
        store.commit('setPageTitle', to.meta.pageTitle);
    }
});

export default router;
