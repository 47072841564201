<template>
    <div id="dev-test-page">
        <div class="page-header-controls">Header controls go here</div>

        <div class="page-content-container">
            <section>
                Testing that the axiosInterceptors work when calling a server endpoint:
                <button @click="getOrgs">Load Orgs</button><br />

                <div v-if="loading" v-tms-spinner="{ width: 3, scale: 0.5 }" class="loading" />
                <div v-if="orgs && orgs.length > 0">Number of Orgs: {{ orgs.length }}</div>
            </section>

            <section>
                This should fail and take you back to the login page:
                <button @click="logoutGetOrgs">LogOut then Load Orgs</button>
            </section>

            <section>
                Listing Datasources to demo use of ps-common file:<br />
                <select multiple class="datasources">
                    <option v-for="datasource in datasources" :key="datasource.id">{{ datasource.name }}</option>
                </select>
            </section>

            <section>
                Just checking that TmsCheckbox from the library is working
                <TmsCheckbox label="Testing a checkbox" />
            </section>
            <section>
                Just checking that TmsToggleSwitch from the library is working
                <TmsToggleSwitch class="d-flex">Testing toggle switch</TmsToggleSwitch>
            </section>

            <section>
                Just checking that TmsDropdown from the library is working
                <TmsDropdown v-model="selectedDropdownOption" :options="dropdownOptions" title="Choose Option:" />
                {{ JSON.stringify(selectedDropdownOption) }}
            </section>

            <section>
                Just checking that TmsSimpleDropdown from the library is working
                <TmsSimpleDropdown
                    v-model="selectedSimpleDropdownOption"
                    :options="dropdownOptions"
                    title="Choose Option:"
                />
                {{ JSON.stringify(selectedSimpleDropdownOption) }}
            </section>

            <section>
                Just checking that Message Modal works
                <TmsButton text="Show Modal" @click="showMessageModal" />
                {{ messageModalResponse }}
            </section>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { defineComponent } from 'vue';

import { Datasources } from '@pushspring/ps-common/lib/marketplace/marketplace.constants';
import { TmsMessageModal } from '@pushspring/tms-client-common';

import clientConfig from '../utils/clientConfig';

const dropdownOptions = [
    { id: 1, title: 'Item 1' },
    { id: 2, title: 'Item 2' },
];

export default defineComponent({
    name: 'DeveloperTest',
    data() {
        return {
            dropdownOptions,
            loading: false,
            orgs: null,
            selectedDropdownOption: [dropdownOptions[1]],
            selectedSimpleDropdownOption: dropdownOptions[1],
            messageModalResponse: '',
        };
    },
    computed: {
        datasources() {
            return Datasources;
        },
    },
    methods: {
        async getOrgs() {
            this.loading = true;
            try {
                const response = await axios.get('/api/organizations');
                if (response) {
                    this.orgs = response.data;
                }
            } catch (e) {
                console.error(e);
            } finally {
                this.loading = false;
            }
        },
        logoutGetOrgs() {
            delete window.sessionStorage.token;
            delete window.sessionStorage.apikey;
            if (!window.sessionStorage.token && !window.sessionStorage.apikey) {
                window.location.href =
                    clientConfig.exploratronPrefix +
                    '/logout?redirectUrl=' +
                    window.location.protocol +
                    '//' +
                    window.location.host +
                    '/';
            }
            this.getOrgs();
        },
        async showMessageModal() {
            const params = {
                title: 'Archive audiences',
                message:
                    'Are you sure you want to archive these audiences? This will stop any existing partner transmissions.',
                cta: 'cta: Do you want to proceed?',
            };

            const response = await this.tmsShowModal(TmsMessageModal, params);
            this.messageModalResponse = response;
        },
    },
});
</script>

<style lang="scss" scoped>
#dev-test-page {
    section {
        padding: 8px 0;
        position: relative;
    }

    .loading {
        position: relative;
        height: 30px;
    }

    .datasources {
        margin-top: 8px;
        width: 300px;
        height: 100px;
    }

    .tms-dropdown {
        max-width: 150px;
    }
}
</style>
