<template>
    <DefaultModalLayout id="contact-modal" :cancel-modal="cancelModal">
        <template #header>
            <span class="modal-title">Contact Us</span>
        </template>
        <TmsContactForm id="contact-us" :topics="topics" :email="email" @submit="submit" />
        <template #footer>
            <TmsButton class="tertiary" text="Cancel" @click="closeModal('cancel')" />
            <TmsButton class="primary" text="Submit Request" type="submit" form="contact-us" />
        </template>
    </DefaultModalLayout>
</template>

<script>
import { defineComponent } from 'vue';

import TmsButton from '../TmsButton/TmsButton.vue';
import DefaultModalLayout from '../TmsModal/DefaultModalLayout.vue';
import modalMixin from '../TmsModal/TmsModalMixin';

import { sendContactUsEmail } from './contact-us.service';
import TmsContactForm from './TmsContactUsForm.vue';

export default defineComponent({
    name: 'TmsContactUsModal',
    components: {
        DefaultModalLayout,
        TmsContactForm,
        TmsButton,
    },
    mixins: [modalMixin],
    props: {
        topics: {
            type: Array,
            required: true,
        },
        email: {
            type: String,
            required: false,
            default: undefined,
        },
    },

    methods: {
        cancelModal() {
            this.closeModal('cancel');
        },
        async submit(e) {
            try {
                await sendContactUsEmail(e);
                this.closeModal('success');
            } catch (error) {
                // todo add some sort of error message to the UI
                console.error(error);
            }
        },
        closeModal(action) {
            this.close({
                status: action,
            });
        },
    },
});
</script>
