<template>
    <TmsLeftNavLayout
        :nav-links="navLinks"
        home-url="/attributes"
        docs-url="https://developer.magentamarketing.com/docs/ingestion-api-overview"
        class="partner-left-nav"
    >
        <div class="user-metadata">
            <div class="company-name">{{ loginProfile }}</div>

            <div class="header-menu-button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                <TmsIcon id="navbar-dropdown-menu" name="fthr-menu" />
            </div>

            <ul
                id="nav-bar-system-menu"
                class="dropdown-menu dropdown-menu-right rounded-panel"
                role="menu"
                aria-labelledby="navbar-dropdown-menu"
            >
                <div class="current-user">
                    <div class="fullname">{{ loginProfile }}</div>
                    <div>{{ companyName }}</div>
                </div>
                <li role="separator" class="dropdown-divider" />
                <li><a href="#" @click.prevent="openKeyModal">Key</a></li>
                <template v-if="!isSSO">
                    <li role="separator" class="dropdown-divider" />
                    <li>
                        <a href="#" @click="logoutProfile()"><TmsIcon name="fthr-log-out" />Sign out</a>
                    </li>
                </template>
            </ul>
        </div>
    </TmsLeftNavLayout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { USER_PROFILE, FULL_NAME } from '@pushspring/auth/ui';
import { IconRegistry, TmsIcon } from '@pushspring/common-ui/icons-core';
import { fthrLogOut, fthrMenu } from '@pushspring/common-ui/icons-feather';
import { TmsLeftNavLayout, requiresAuth } from '@pushspring/tms-client-common';

import ApiKeyModal from '../manage/components/ApiKeyModal.vue';
import { navLinks } from '../navLinks';
import { ConfigService } from '../utils/ConfigService';

IconRegistry.add(fthrLogOut, fthrMenu);

const clientConfig = new ConfigService();

export default defineComponent({
    components: {
        TmsLeftNavLayout,
        TmsIcon,
    },
    data() {
        return {
            navLinks,
            isSSO: false,
        };
    },
    computed: {
        loginProfile() {
            return this.$store.getters[FULL_NAME] ?? 'No name';
        },
        companyName() {
            return this.$store.getters[USER_PROFILE]?.companyName ?? 'No company name';
        },
        isFullScreen() {
            // For the moment isFullScreen matches with whether authentication
            // is required. This could change in the future.
            return !requiresAuth(this.$route);
        },
    },
    mounted() {
        if (this.$store.getters[USER_PROFILE]) {
            const email = this.$store.getters[USER_PROFILE].email;
            this.isSSO = email.endsWith('t-mobile.com');
        }
    },
    methods: {
        logoutProfile() {
            delete window.sessionStorage.token;
            delete window.sessionStorage.apikey;
            if (!window.sessionStorage.token && !window.sessionStorage.apikey) {
                window.location.href =
                    clientConfig.exploratronPrefix +
                    '/logout?redirectUrl=' +
                    window.location.protocol +
                    '//' +
                    window.location.host +
                    '/';
            }
        },
        //when click on api modal
        async openKeyModal() {
            await this.tmsShowModal(ApiKeyModal);
        },
    },
});
</script>

<style lang="scss" scoped>
$left-nav-top-two-sections-height: 117px; // logo height + user-metadata height

.partner-left-nav {
    .user-metadata {
        display: inline-flex;
        align-items: center;
        width: 100%;

        .company-name {
            flex-grow: 1;
            margin-right: 12px;
            letter-spacing: 0px;
            line-height: 1.2;
        }

        .header-menu-button {
            width: 32px;
            height: 32px;
            padding: 8px;
            background-color: $grey-light;
            border-radius: 5px;
            cursor: pointer;
            // margin-left used to make the size of this element the same as the logo on the left
            // so that the nav links are centered correctly between them in the flexbox
            //margin-left: calc(#{$logo-width} - 32px);

            svg {
                display: block;
                width: 16px;
                height: 16px;
            }
        }

        #nav-bar-system-menu {
            font-size: 14px;
            font-family: $default-font-stack;
            min-width: 216px; // Following the design. It looks too narrow without it.

            // Scrolls vertically so the menu isn't cutoff by short viewports
            max-height: calc(100vh - $left-nav-top-two-sections-height);
            overflow-y: auto;
            z-index: 1001; // So that it sits above page headers

            .current-user {
                margin: 0 9px 10px 9px;
                padding: 10px 0;

                letter-spacing: 0;
                line-height: 1.2;
                color: #999999;

                & > div {
                    margin: 2px 0;
                }

                .fullname {
                    color: $black;
                    font-weight: $font-weight-medium;
                }
            }

            .dropdown-divider {
                margin-left: -10px;
                margin-right: -10px;
            }

            li {
                a {
                    display: block;
                    color: $black;
                    margin: 2px 0;
                    padding: 6px 10px;
                    font-size: 14px;
                    font-weight: $font-weight-regular;
                    border-radius: 5px;
                    transition: background-color 0.25s ease-in;
                    text-decoration: none;

                    &:hover {
                        color: $black;
                        background-color: $grey-light;
                    }

                    img,
                    svg {
                        vertical-align: sub;
                        display: inline-block;
                        width: 16px;
                        height: 16px;
                        margin-right: 10px;
                    }
                }
            }
        }

        // Intended to be used on multiple of these menus
        // TODO: Move this somewhere
        .rounded-panel {
            padding: 10px;
            box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.25);
            border: none;
            border-radius: 10px;
            background-color: $white;
        }
    }
}
</style>
