<template>
    <div class="shares">
        <div class="page-header-controls align-items-center">
            <!-- Search Shares -->
            <TmsSearchField
                v-model.trim="search"
                placeholder="Search company name"
                aria-label="search"
                class="flex-grow-1"
                @input="onSearchInputChange"
            />
            <!-- New Share-->
            <TmsButton class="teritary" type="submit" :disabled="isApiKeyError" @click="openShareModal">
                New Share</TmsButton
            >
        </div>

        <!-- Used when has loading state -->
        <div v-if="status.sharesLoading" v-tms-spinner="{ width: 3, scale: 2 }" />
        <!-- Share List -->
        <div v-else-if="isResults && !status.sharesLoading">
            <ShareList
                :shares="shares"
                :attributes="attributes"
                :col-settings="colSettings"
                @sort-changed="columnChange"
            />
        </div>

        <!-- Else No Shares-->
        <div v-else>
            <h6>No private sharing found!</h6>
        </div>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import { USER_PROFILE } from '@pushspring/auth/ui';
import { TmsFlashMessageUtils } from '@pushspring/tms-client-common';

import NewShareModal from '../components/NewShareModal.vue';
import ShareList from '../components/ShareList.vue';
import { getApiKey } from '../utils/apikeys';
import { getAttributes } from '../utils/attributes';
import { listShares } from '../utils/shares';

export default defineComponent({
    name: 'SharesPage',
    components: { ShareList },
    data() {
        return {
            search: '',
            shares: [],
            attributes: [],
            orgId: '',
            apiKey: '',
            status: {
                sharesLoading: false,
                attributesLoading: false,
            },
            colSettings: { column: 'name', order: 'asc' },
            originalShares: [],
        };
    },
    computed: {
        //check if show results
        isResults() {
            return this.shares.length > 0;
        },
        //check if apikey has error
        isApiKeyError() {
            return this.apiKey === 'error';
        },
    },
    async created() {
        //set key for accessing api
        try {
            TmsFlashMessageUtils.clear();
            await getApiKey();
        } catch (e) {
            this.apiKey = 'error';
            TmsFlashMessageUtils.replace('error', 'There was an error accessing an api key.');
            return;
        }
        //load all shares and attributes

        this.orgId = this.$store.getters[USER_PROFILE].orgId;
        this.getAllAttributes();
        this.getShares();

        //remove after and before space on search input / set search params
        this.search = this.$route.query?.term ? this.$route.query?.term : this.search.replace(/\s/g, '');

        //check if query params has column and order then set it
        if (this.$route.query?.column) {
            this.colSettings.column = this.$route.query?.column;
            this.colSettings.order = this.$route.query?.order;
        }
    },
    methods: {
        //get all shares
        async getShares() {
            this.status.sharesLoading = true;
            TmsFlashMessageUtils.clear();
            try {
                this.shares = await listShares(this.orgId);
                this.originalShares = this.shares;
                //if query params term has value then filter shares
                if (this.$route.query?.term) {
                    this.shares = this.filtered(this.colSettings, this.search);
                }
            } catch (e) {
                TmsFlashMessageUtils.replace('error', e);
            } finally {
                this.status.sharesLoading = false;
            }
        },
        //get all attributes
        async getAllAttributes() {
            this.status.attributesLoading = true;
            TmsFlashMessageUtils.clear();
            try {
                //getting all attributes
                this.attributes = await getAttributes();
            } catch (e) {
                TmsFlashMessageUtils.replace('error', e);
            } finally {
                this.status.attributesLoading = false;
            }
        },

        //open new share modal
        async openShareModal() {
            const response = await this.tmsShowModal(NewShareModal);
            //if has status success then load all shares with new share
            if (response) {
                this.getShares();
                TmsFlashMessageUtils.add('success', 'New Share added successfully.');
            }
        },

        //search input change then set router query params and filter results
        onSearchInputChange(value) {
            if (this.$route.query.term !== value && value) {
                this.shares = this.filtered(this.colSettings, value);
            } else if (value.length === 0) {
                this.shares = this.originalShares;
            }
            this.$router.replace({ query: { term: value, ...this.colSettings } });
        },

        //filter results based on column settings and search value
        filtered({ column, order }, search) {
            let filtered = this.originalShares;
            const compare = (a, b) => (a[column] ?? '')?.toString().localeCompare((b[column] ?? '')?.toString());
            if (search) {
                filtered = this.originalShares.filter((share) =>
                    share.company_name.toLowerCase().includes(search.toLowerCase()),
                );
            }
            if (order === 'desc') {
                filtered = filtered.sort((a, b) => compare(b, a));
            } else {
                filtered = filtered.sort(compare);
            }
            return filtered;
        },

        //if column order change then filter results
        columnChange(settings) {
            this.colSettings.order = settings.order;
            this.colSettings.column = settings.column;
            //set query params as well
            this.$router.replace({ query: { term: this.search, ...this.colSettings } });
            this.shares = this.filtered(this.colSettings, this.search);
        },
    },
});
</script>
<style lang="scss" scoped>
.shares {
    .tms-button {
        margin-left: 8px;
    }

    h6 {
        text-align: center;
        font-size: 22px;
        padding: 10px;
    }
}
</style>
