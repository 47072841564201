import axios from 'axios';

// create an axios instance that doesn't use the default instance interceptors
const azureADAxios = axios.create();

export interface AppToken {
    token: string;
    profile: {
        id: number;
        email: string;
        first_name: string;
        last_name: string;
        verified: boolean;
        appRoles: string[];
        orgRoles: number[];
        gdpr_dpa_state: string;
    };
    expires: string;
}

export async function getAppToken(idpAccessToken: string): Promise<AppToken> {
    try {
        const { data } = await azureADAxios.post('/auth/token', undefined, {
            headers: {
                Authorization: `Bearer ${idpAccessToken}`,
            },
        });
        return data;
    } catch (err: any) {
        if (err.isAxiosError) {
            const { status, data } = err.response;
            switch (status) {
                case 403:
                    throw 'Your user was not found in the group that authorizes you to login to the application. Please contact the platform engineering team to get added to the group.';
                default:
                    throw data.message;
            }
        } else {
            throw err;
        }
    }
}
