<!--
    A simpler dropdown than TmsDropdown that only supports
    single selection with a single value v-model.

    Also supports the disabled state.

    See TmsDropdown for more details.
-->
<template>
    <TmsDropdown
        v-model="wrappedSelected"
        :options="options"
        :title="buttonLabel"
        :disabled="$attrs.disabled"
        :alignment="alignment"
    />
</template>

<script>
import { defineComponent } from 'vue';

import TmsDropdown from './TmsDropdown.vue';

export default defineComponent({
    name: 'TmsSimpleDropdown',
    components: {
        TmsDropdown,
    },
    props: {
        alignment: {
            type: String,
            default: 'left',
        },
        options: {
            type: Array,
            required: true,
        },
        modelValue: {
            type: Object,
            default: null,
        },
        title: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        },
    },
    emits: ['update:modelValue', 'change'],
    computed: {
        buttonLabel: function () {
            return this.title || this.modelValue?.title || this.placeholder;
        },
        wrappedSelected: {
            get: function () {
                return this.modelValue ? [this.modelValue] : [];
            },
            set: function (newValue) {
                const val = newValue?.length === 1 ? newValue[0] : null;
                this.$emit('update:modelValue', val);
                this.$emit('change', val);
            },
        },
    },
});
</script>
