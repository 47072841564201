<template>
    <div class="attribute-list">
        <!-- Attribute List table -->
        <table class="table full-width">
            <thead>
                <tr>
                    <th scope="col">
                        <TmsColumnHeader
                            :column-settings="colSettings"
                            column-name="attribute_id"
                            @column-change="columnChange"
                            >Id</TmsColumnHeader
                        >
                    </th>
                    <th scope="col">
                        <TmsColumnHeader :column-settings="colSettings" column-name="name" @column-change="columnChange"
                            >Attribute</TmsColumnHeader
                        >
                    </th>
                    <th scope="col">
                        <TmsColumnHeader :column-settings="colSettings" column-name="used" @column-change="columnChange"
                            >Built</TmsColumnHeader
                        >
                    </th>
                    <th scope="col">
                        <TmsColumnHeader :column-settings="colSettings" column-name="sent" @column-change="columnChange"
                            >Exported</TmsColumnHeader
                        >
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(attribute, index) in reportList" :key="index">
                    <td>
                        {{ attribute.attributeId }}
                    </td>
                    <td>
                        {{ attribute.name }}
                    </td>
                    <td>
                        {{ attribute.used }}
                    </td>
                    <td>
                        {{ attribute.sent }}
                    </td>
                </tr>
            </tbody>
        </table>
        <TmsPagination
            :current-page="currentPage"
            :total-items="reports.length"
            :items-per-page="itemsPerPage"
            :show-items-per-page="showItemsPerPage"
            @current-page-change="currentPageChange"
            @page-size-change="pageSizeChange"
        />
    </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'AttributeList',
    props: {
        /**
         * @type {{new (): Report[]}}
         */
        reports: {
            type: Array,
            required: true,
        },
        /**
         * @type {{{ column: string; order: string; }}
         */
        colSettings: {
            type: Object,
            required: true,
        },
    },
    emits: ['sort-changed'],
    data() {
        return {
            offset: 0,
            itemsPerPage: 25,
        };
    },
    computed: {
        currentPage() {
            return Math.ceil(this.offset / this.itemsPerPage) + 1;
        },
        showItemsPerPage() {
            return this.reports.length > 24;
        },
        /** @returns { Report[] } */
        reportList() {
            const start = this.currentPage * this.itemsPerPage - this.itemsPerPage;
            const end = this.currentPage * this.itemsPerPage;
            const filteredReports = this.reports.filter((item, index) => index >= start && index <= end);
            return filteredReports;
        },
    },
    methods: {
        /**
         * @param {{ order: string; column: string; }} e
         */
        columnChange(e) {
            this.$emit('sort-changed', e);
        },
        //set offset
        currentPageChange({ currentPage }) {
            this.offset = (currentPage - 1) * this.itemsPerPage;
        },
        //set limit when page size change
        pageSizeChange({ itemsPerPage }) {
            this.itemsPerPage = itemsPerPage;
        },
    },
});
</script>
<style lang="scss" scoped>
.attribute-list {
    thead,
    tbody,
    tr,
    td,
    tr {
        vertical-align: middle;
    }
}
</style>
