import axios from 'axios';

import { TOKEN } from '@pushspring/auth/ui';

import store from '../store';

import { ConfigService } from './ConfigService';

const clientConfig = new ConfigService();

// Add a request interceptor
axios.interceptors.request.use(
    function (config) {
        config.headers = config.headers || {};
        const token = store.getters[TOKEN];
        const apikey = window.sessionStorage?.apikey;
        if (config.url.indexOf(clientConfig.eventotronPrefix) === 0) {
            if (apikey) {
                config.headers.Authorization = 'Bearer ' + apikey;
            }
            return config;
        } else {
            if (token) {
                config.headers.Authorization = 'Bearer ' + token;
            }
            return config;
        }
    },

    function (error) {
        // Do something with request error
        return Promise.reject(error);
    },
);
