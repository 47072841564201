const FlashMessages = {};

let store = null;

/**
 * @typedef   {"error"|"info"|"warning"|"success"|"loading"} Levels
 *
 */

/**
 * Message Levels
 * @readonly
 * @enum {string}
 */
const Level = {
    Error: 'error',
    Info: 'info',
    Warning: 'warning',
    Success: 'success',
    Loading: 'loading',
};

FlashMessages.Level = Level;

// This is a plugin that registers a store module for the flash messages.
// Call when you setup the store by adding it to the plugins array.
FlashMessages.storePlugin = function (rootStore) {
    store = rootStore;

    store.registerModule('flashMessages', {
        namespaced: true,
        state: {
            messages: [], // expected object elements: {level (str), fadeOut (bool), text (str)}
        },
        mutations: {
            add(state, message) {
                message.id = window.performance.now().toString().replace(/\./g, '');
                state.messages.push(message);
            },
            delete(state, messageId) {
                for (let i = 0; i < state.messages.length; i++) {
                    if (state.messages[i].id == messageId) {
                        state.messages.splice(i, 1);
                        break;
                    }
                }
            },
            clear(state) {
                state.messages = [];
            },
        },
    });
};

/**
 *  Clear all flash messages
 */
FlashMessages.clear = function clear() {
    store.commit('flashMessages/clear');
};

/**
 * Replace all messages in the flash message store with the one specified
 * @param {Levels} level
 * @param {string} message
 * @param {boolean=} fadeOut
 */
FlashMessages.replace = function replace(level, message, fadeOut) {
    if (!Object.values(Level).includes(level)) {
        throw new RangeError("Not a valid 'level'");
    }

    FlashMessages.clear();
    store.commit('flashMessages/add', { level: level, text: message, fadeOut: fadeOut });
};

/**
 * Add a new message to the flash message store
 * @param {Levels} level
 * @param {string} message
 * @param {boolean=} fadeOut
 */
FlashMessages.add = function add(level, message, fadeOut) {
    if (!Object.values(Level).includes(level)) {
        throw new RangeError("Not a valid 'level'");
    }

    store.commit('flashMessages/add', { level: level, text: message, fadeOut: fadeOut });
};

export default FlashMessages;
