import { nextTick } from 'vue';

// Creates a file from a blob, with a particular filename, and
// triggers a download.
// TODO: Figure out how to test this in a unit test
export function downloadFile(blob, filename) {
    if (window.navigator.msSaveBlob) {
        // Support for IE 11
        window.navigator.msSaveBlob(blob, filename);
    } else {
        // For other browsers. Need to add it to the document for it to work in Firefox.
        const url = window.URL.createObjectURL(blob);

        const a = window.document.createElement('a');
        a.download = filename;
        a.cssText = "display: 'none'";
        a.href = url;
        window.document.body.append(a);

        a.click();
        if (a.remove) a.remove();
        if (url) {
            nextTick(() => {
                window.URL.revokeObjectURL(url);
            });
        }
    }
}
