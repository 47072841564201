<template>
    <TmsDefaultModalLayout id="share-modal" :cancel-modal="cancelModal">
        <template #header>
            <h4 class="modal-title">Organization Sharing</h4>
        </template>
        <table v-if="isShares" class="table full-width">
            <thead>
                <tr>
                    <th scope="col">
                        <TmsColumnHeader :column-settings="colSettings" column-name="selected"
                            >Selected</TmsColumnHeader
                        >
                    </th>
                    <th scope="col">
                        <TmsColumnHeader :column-settings="colSettings" column-name="Company">Company</TmsColumnHeader>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(share, index) in shares" :key="index">
                    <td>
                        <TmsCheckbox v-model="share.isChecked" />
                    </td>
                    <td>
                        {{ share.company_name }}
                    </td>
                </tr>
            </tbody>
        </table>
        <div v-else>
            <h6>No Shares for this org. Please configure private shares in your organization settings.</h6>
        </div>
        <template #footer>
            <TmsButton class="primary" text="Share" :disabled="!isShares" @click="shareAttribute" />
            <TmsButton class="tertiary" text="Cancel" @click="cancelModal" />
        </template>
    </TmsDefaultModalLayout>
</template>

<script>
import { defineComponent } from 'vue';

import { TmsDefaultModalLayout, TmsModalMixin } from '@pushspring/tms-client-common';

export default defineComponent({
    components: { TmsDefaultModalLayout },
    mixins: [TmsModalMixin],
    data() {
        return {
            shares: [],
            currentAttribute: null,
            colSettings: { column: 'company_name', order: 'asc' },
        };
    },
    computed: {
        //check is has shares
        isShares() {
            return this.shares.length > 0;
        },
    },

    created() {
        //when page load then get props data for currentattribute and shares for specific orgs
        this.currentAttribute = this.$attrs.attribute;
        this.shares = this.$attrs.shares;
        //map org shares checkbox
        this.mapShareChecked();
    },
    methods: {
        mapShareChecked() {
            const selectedWhitelists = this.currentAttribute.visibility_whitelist;
            this.shares.forEach((share) => (share.isChecked = selectedWhitelists.includes(share.org_id)));
        },

        //when click on share attribute then filter selected shares and send response data back
        shareAttribute() {
            const whitelists = this.shares.filter((share) => share.isChecked).map((obj) => obj.org_id);
            const returnResponse = {
                visibility_whitelist: whitelists,
            };
            this.close(returnResponse);
        },

        //cancel button click
        cancelModal() {
            this.close();
        },
    },
});
</script>
<style lang="scss" scoped>
#share-modal {
    .tms-button {
        margin-left: 8px;
    }
}
</style>
