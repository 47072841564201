<template>
    <TmsDefaultModalLayout id="new-share-modal" :cancel-modal="cancelModal">
        <template #header>
            <h4 class="modal-title">New Share</h4>
        </template>
        <form name="shareForm" role="form">
            <div class="form-group">
                <label>Email</label>
                <input
                    id="name"
                    v-model="share.email"
                    v-tms-focus
                    class="form-control"
                    placeholder="email@example.com"
                    required
                />
            </div>
        </form>
        <template #footer>
            <TmsButton class="primary" text="Add" :disabled="v$.$invalid" @click="addShare(share)" />
            <TmsButton class="tertiary" text="Cancel" @click="cancelModal()" />
        </template>
    </TmsDefaultModalLayout>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { defineComponent } from 'vue';

import { TmsDefaultModalLayout, TmsModalMixin, TmsFlashMessageUtils } from '@pushspring/tms-client-common';

import { attemptNewPermissionViaEmail } from '../utils/shares';

export default defineComponent({
    components: { TmsDefaultModalLayout },
    mixins: [TmsModalMixin],
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            share: {
                email: '',
            },
        };
    },
    validations() {
        return {
            share: {
                email: {
                    required,
                },
            },
        };
    },
    methods: {
        //cancel button click
        cancelModal() {
            this.close();
        },
        //add share
        async addShare(share) {
            TmsFlashMessageUtils.clear();
            try {
                const email = share.email;
                if (await attemptNewPermissionViaEmail(email)) {
                    this.close({
                        status: 'success',
                    });
                }
            } catch (e) {
                TmsFlashMessageUtils.replace('error', e);
            } finally {
                this.close();
            }
        },
    },
});
</script>
<style lang="scss" scoped>
#new-share-modal {
    .form-group {
        display: flex;
        label {
            margin: 5px;
        }
    }
}
</style>
