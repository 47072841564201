<template>
    <TmsDefaultModalLayout id="apikey-modal" :cancel-modal="cancelModal">
        <template #header>
            <h4 class="modal-title">Key</h4>
        </template>
        <!-- Api Key Section -->
        <section>
            <div class="api-key">
                <h6>Key</h6>
                <div v-if="apiKeyVisible">
                    <TmsButton class="tertiary" @click="apiKeyVisible = !apiKeyVisible"
                        ><TmsIcon name="fthr-eye-off"
                    /></TmsButton>
                </div>
                <div v-if="!apiKeyVisible">
                    <TmsButton class="tertiary" @click="apiKeyVisible = !apiKeyVisible"
                        ><TmsIcon name="fthr-eye"
                    /></TmsButton>
                </div>
            </div>
            <div class="api-key">
                <p>
                    {{ apiKeyVisible ? key : 'xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx' }}
                </p>
            </div>
        </section>
        <!-- S3 Section -->
        <section v-if="isPolicies">
            <h6>S3</h6>
            <div v-for="policy in policies" :key="policy.name">
                <p v-if="policy.path">Path: s3://{{ policy.path }}</p>
            </div>
        </section>

        <!-- if permissions are not loading and has results -->
        <div v-if="!status.permissionsloading">
            <!-- Trusts Section -->
            <section v-if="isRoleDetails">
                <h6>Trusts</h6>
                <div>
                    <p>IAM Role Arn: {{ roleDetails.Arn }}</p>
                    <p>Your Arn: {{ roleDetails.PartnerArn }}</p>
                    <p>External ID: {{ roleDetails.ExternalId }}</p>
                </div>
            </section>
            <!-- Access Keys Section -->
            <section v-if="isAccessKeys">
                <h6>Access Keys</h6>
                <table class="table full-width">
                    <thead>
                        <tr>
                            <th scope="col">Key</th>
                            <th scope="col">Secret</th>
                            <th scope="col">Days Old</th>
                            <th scope="col">Last Used</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(accessKey, index) in accessKeys" :key="index">
                            <td>
                                {{ accessKey.AccessKeyId }}
                            </td>
                            <td class="secret-key">
                                <span v-if="secrets[accessKey.AccessKeyId]"
                                    >{{ secrets[accessKey.AccessKeyId] }}
                                    <TmsButton class="tertiary" @click="hideSecret(accessKey.AccessKeyId)"
                                        ><TmsIcon name="fthr-eye-off"
                                    /></TmsButton>
                                </span>
                                <span v-else>
                                    <samp>**********</samp>
                                    <TmsButton class="tertiary" @click="showSecret(accessKey.AccessKeyId)"
                                        ><TmsIcon name="fthr-eye"
                                    /></TmsButton>
                                </span>
                            </td>
                            <td>
                                {{ daysOld(accessKey.CreateDate) }}
                            </td>
                            <td>
                                {{ lastUsed(accessKey.LastUsedDate) }}
                            </td>
                            <td>
                                <TmsButton name="delete" @click="deleteAccessKey(accessKey.AccessKeyId)"
                                    ><TmsIcon name="fthr-trash-2"
                                /></TmsButton>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </section>
        </div>
        <!-- if permissions are loading -->
        <div
            v-if="status.permissionsloading"
            v-tms-spinner="{ width: 3, scale: 1, position: 'relative' }"
            class="spinner"
        />
    </TmsDefaultModalLayout>
</template>

<script>
import { default as dayjs } from 'dayjs';
import { default as relativeTime } from 'dayjs/plugin/relativeTime';
import { defineComponent } from 'vue';

import { IconRegistry, TmsIcon } from '@pushspring/common-ui/icons-core';
import { fthrEye, fthrEyeOff, fthrTrash2 } from '@pushspring/common-ui/icons-feather';
import { TmsDefaultModalLayout, TmsModalMixin, TmsFlashMessageUtils } from '@pushspring/tms-client-common';

import { deleteApiKey, getApiKey, getIamPermissions, getSecretAccessKey, extractIamResource } from '../utils/apikeys';

dayjs.extend(relativeTime);

IconRegistry.add(fthrEye, fthrEyeOff, fthrTrash2);

export default defineComponent({
    components: {
        TmsDefaultModalLayout,
        TmsIcon,
    },
    mixins: [TmsModalMixin],
    data() {
        return {
            status: {
                permissionsloading: false,
            },
            key: '',
            orgId: '',
            secrets: {},
            apiKeyVisible: false,
            roleDetails: [],
            accessKeys: [],
            policies: [],
        };
    },
    computed: {
        //check if has accesskeys
        isAccessKeys() {
            return Object.keys(this.accessKeys).length > 0;
        },
        //check if has roledetails
        isRoleDetails() {
            return this.roleDetails !== null;
        },
        //check if has policies
        isPolicies() {
            return this.policies.some((policy) => policy.enabled);
        },
    },
    async created() {
        //set key for accessing api
        try {
            TmsFlashMessageUtils.clear();
            await getApiKey();
            this.key = window.sessionStorage.apikey;
            this.orgId = window.sessionStorage.orgId;
        } catch (e) {
            this.key = 'error';
            TmsFlashMessageUtils.replace('error', 'There was an error accessing an api key.');
            this.close();
            return;
        }
        //get all permissions
        this.getPermissions();
    },
    methods: {
        //cancel button click
        cancelModal() {
            this.close();
        },
        //get last used time
        lastUsed(date) {
            return date ? dayjs(date).fromNow() : 'Never Used';
        },
        //get how many days old count
        daysOld(date) {
            return dayjs().diff(date, 'days');
        },
        //hide secret key
        hideSecret(key) {
            delete this.secrets[key];
        },
        //get all permissions
        async getPermissions() {
            this.status.permissionsloading = true;
            TmsFlashMessageUtils.clear();
            try {
                const iampermissions = await getIamPermissions();
                this.roleDetails = iampermissions.roleDetails;
                this.accessKeys = iampermissions.accessKeys;
                this.policies = Object.values(iampermissions.availablePolicies).map((v) => ({
                    name: v.type,
                    enabled: v.created,
                    path: iampermissions.policies[v.type]
                        ? extractIamResource(iampermissions.policies[v.type].PolicyVersion.Document)
                        : undefined,
                }));

                if (!this.roleDetails && !this.accessKeys) {
                    this.status.permissionsloading = false;
                }
            } catch (e) {
                TmsFlashMessageUtils.replace('error', e);
            } finally {
                this.status.permissionsloading = false;
            }
        },

        //delete access key
        async deleteAccessKey(key) {
            TmsFlashMessageUtils.clear();
            const ok = window.confirm('Are you sure you want to delete this key?');
            if (ok) {
                try {
                    await deleteApiKey(key);
                    this.getPermissions();
                    TmsFlashMessageUtils.replace('success', 'Key deleted successfully');
                } catch (e) {
                    TmsFlashMessageUtils.replace('error', e);
                }
            }
        },
        //show secret key
        async showSecret(key) {
            TmsFlashMessageUtils.clear();
            try {
                const { SecretString } = await getSecretAccessKey(key);
                this.secrets[key] = SecretString;
            } catch (e) {
                TmsFlashMessageUtils.replace('error', e);
            }
        },
    },
});
</script>
<style lang="scss" scoped>
#apikey-modal {
    svg {
        height: 1.5em;
        width: 1.5em;
    }

    section {
        border-bottom: 1px solid #dee2e6;

        h6 {
            margin-top: 10px;
            font-size: 14px;
        }

        .api-key {
            display: flex;

            .tms-button {
                margin-left: 20px;
            }

            p {
                margin-top: 10px;
                font-size: 12px;
                line-height: 1.2;
                line-break: anywhere;
            }
        }

        table {
            padding-left: 0px;

            th,
            td {
                padding-left: 0px;
            }

            td {
                vertical-align: middle;

                span {
                    line-break: anywhere;
                }
            }
        }
    }

    .spinner {
        margin: 30px;
    }
}
</style>
