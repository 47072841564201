import axios from 'axios';
export async function getApiKey() {
    try {
        if (!window.sessionStorage.apikey) {
            const response = await axios.get('/api/apikey');
            if (response.data.apiKey) {
                window.sessionStorage.apikey = response.data.apiKey;
                window.sessionStorage.orgId = response.data.orgId;
            } else {
                throw 'There was an error accessing an api key.';
            }
        }
    } catch (e) {
        throw 'Error having an access to API';
    }
}

export async function getIamPermissions() {
    try {
        const { data } = await axios.get('/api/iamPermissions');
        return data;
    } catch (e) {
        throw 'There was an error getting iam permissions.';
    }
}

export async function getSecretAccessKey(keyId) {
    try {
        const { data } = await axios.get('/api/iamPermissions/getSecretAccessKey', { params: { keyId: keyId } });
        return data;
    } catch (e) {
        throw 'There was an error getting secret access key.';
    }
}

export async function deleteApiKey(keyId) {
    try {
        const { data } = await axios.post('/api/iamPermissions/deleteKey', { keyId: keyId });
        return data;
    } catch (e) {
        throw 'There was an error deleting key.';
    }
}

export function extractIamResource(doc) {
    let resource;
    try {
        resource = JSON.parse(decodeURIComponent(doc))
            .Statement.find((statement) => statement.Sid === 'AllowAllS3ActionsInUserFolder')
            .Resource.split(':::')[1];
    } catch (err) {
        console.error('Could not extract resource for document');
        resource = 'Error parsing path';
    }
    return resource;
}
