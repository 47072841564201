<!--
    A default modal layout with a title (optional), body, and footer (optional).
    Use from inside your modal's template.
    Requires a cancelModal function to use when the X button is clicked.
    It uses the new modal design.
    The modal still needs to use ModalMixin for the programmatic behavior.
-->
<template>
    <div class="modal new-design" tabindex="-1" role="dialog">
        <div class="modal-dialog" :class="{ maximized: isMaximized }" role="document">
            <div class="modal-content">
                <div v-if="$slots.header" class="modal-header">
                    <div class="modal-close" @click="innerCancelModal">
                        <TmsIcon name="fthr-x" />
                    </div>
                    <slot name="header" />
                </div>
                <div v-if="$slots.control" class="modal-control">
                    <slot name="control" />
                </div>
                <div class="modal-body">
                    <slot name="default" />
                </div>
                <div v-if="$slots.footer" class="modal-footer">
                    <slot name="footer" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';

import { IconRegistry, TmsIcon } from '@pushspring/common-ui/icons-core';
import { fthrX } from '@pushspring/common-ui/icons-feather';
import './Modal.scss';

IconRegistry.add(fthrX);

export default defineComponent({
    name: 'DefaultModalLayout',
    components: {
        TmsIcon,
    },
    props: {
        cancelModal: {
            type: Function,
            required: true,
        },
        isMaximized: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        innerCancelModal() {
            this.cancelModal();
        },
    },
});
</script>

<style lang="scss">
@import './modal-variables';
@import '../styles/design-language-variables';

.modal-content {
    .modal-header {
        position: relative;
        $close-side-padding: 5px;

        .modal-close {
            display: none; // We hide it at small widths so it doesn't overlap the title
            position: absolute;
            left: calc(#{$modal-left-right-padding} - #{$close-side-padding});
            padding: 5px $close-side-padding 6px $close-side-padding;
            border-radius: 50%;
            background-color: $grey-light;
            max-height: 24px;

            svg {
                width: 14px;
                height: 14px;
                stroke: $grey-dark;
                transition: stroke 0.2s ease-in-out;
                vertical-align: top;
            }

            &:hover {
                cursor: pointer;

                svg {
                    stroke: $black;
                }
            }

            @media (min-width: 768px) {
                display: block;
            }
        }
    }

    .modal-control {
        border-bottom: 1px solid $grey-mid;
        padding: 0 $browser-side-offset;
    }
}
</style>
