<!--
    Shows an rounded info image that when hovered over will
    show a popover containing the content.

    The name of the CSS class has changed from .info-bubble
    to .tms-info-bubble to simplify the CSS here without conflicting
    with the AngularJS version.

    The CSS does not assume the layout context of the info-button. The
    container should position it correctly in the layout.
-->
<template>
    <div v-if="popupContent" v-tippy="tippyConfig" class="tms-info-bubble" tabindex="0">
        <TmsIcon name="fthr-info" />
    </div>
</template>

<script>
// since one of the points of this component is to wrap the use of the vue-tippy package in tms-component
// it makes more sense to import the directive directly here
import { defineComponent } from 'vue';
import { directive } from 'vue-tippy';

import { IconRegistry, TmsIcon } from '@pushspring/common-ui/icons-core';
import { fthrInfo } from '@pushspring/common-ui/icons-feather';

IconRegistry.add(fthrInfo);

export default defineComponent({
    name: 'TmsInfoBubble',
    components: {
        TmsIcon,
    },
    directives: {
        tippy: directive,
    },
    props: {
        popupContent: {
            type: String,
            default: null,
        },
        popupPlacement: {
            type: String,
            default: 'top',
        },
    },
    computed: {
        /** @returns { import('vue-tippy').TippyOptions} */
        tippyConfig() {
            return {
                arrow: true,
                placement: this.popupPlacement,
                content: this.popupContent,
                theme: 'light',
                allowHTML: true,
            };
        },
    },
});
</script>

<style lang="scss" scoped>
@import '../styles/design-language-variables';

.tms-info-bubble {
    display: inline-block;
    fill: $black;
    height: 18px;
    width: 18px;
    cursor: pointer;

    svg {
        width: 100%;
        height: 100%;
    }

    &:focus {
        outline: 0;
    }
}
</style>
