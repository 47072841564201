/**
 * TmsSpinner - A simple VueJS directive wrapper around spin.js
 * Pass an object of config overrides as the value of the directive,
 * e.g. v-tms-spinner="{ width: 6 }"
 */
import 'spin.js/spin.css';
import { Spinner } from 'spin.js';

/** @type {import('vue').Directive} */
export default {
    mounted(el, binding) {
        const config = binding.value || { width: 3 };
        new Spinner(config).spin(el);
    },
};
