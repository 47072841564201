<template>
    <TmsDefaultModalLayout id="new-attribute-modal" :cancel-modal="cancelModal">
        <template #header>
            <h4 class="modal-title">New Attribute</h4>
        </template>
        <form name="attributeForm" role="form">
            <div class="form-group">
                <label>Name</label>
                <input
                    id="name"
                    v-model="attribute.name"
                    v-tms-focus
                    class="form-control"
                    placeholder="Name of attribute"
                    required
                />
            </div>
            <div class="form-group">
                <label>Description</label>
                <input
                    id="description"
                    v-model="attribute.description"
                    class="form-control"
                    placeholder="Description of attribute"
                    required
                />
            </div>
            <div class="form-group">
                <label>Partner Key</label>
                <input
                    id="partner_foreign_key"
                    v-model="attribute.partner_foreign_key"
                    class="form-control"
                    placeholder="Partner foreign key"
                />
            </div>
            <div class="form-group">
                <TmsCheckbox id="visible" v-model="attribute.visible" label="Visible" />
            </div>
        </form>
        <template #footer>
            <TmsButton class="primary" text="Ok" :disabled="v$.$invalid" @click="addAttribute(attribute)" />
            <TmsButton class="tertiary" text="Cancel" @click="cancelModal()" />
        </template>
    </TmsDefaultModalLayout>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { defineComponent } from 'vue';

import { TmsDefaultModalLayout, TmsModalMixin, TmsFlashMessageUtils } from '@pushspring/tms-client-common';

import { create } from '../utils/attributes';

export default defineComponent({
    components: { TmsDefaultModalLayout },
    mixins: [TmsModalMixin],
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            attribute: {
                name: '',
                description: '',
                partner_foreign_key: '',
                visible: false,
            },
        };
    },
    validations() {
        return {
            attribute: {
                name: {
                    required,
                },
            },
        };
    },
    methods: {
        //cancel button click
        cancelModal() {
            this.close();
        },
        //add attribute
        async addAttribute(attribute) {
            TmsFlashMessageUtils.clear();
            try {
                const response = await create(attribute);
                // if it has response then send returnresponse with new attribute_id
                if (response) {
                    const returnResponse = {
                        status: 'success',
                        attributeId: response.attribute_id,
                    };
                    this.close(returnResponse);
                }
            } catch (e) {
                TmsFlashMessageUtils.replace('error', e);
            } finally {
                this.close();
            }
        },
    },
});
</script>
<style lang="scss" scoped>
#new-attribute-modal {
    .form-group {
        margin-bottom: 15px;
        label {
            color: $black;
            font-weight: $font-weight-regular;
            margin-bottom: 9px;
        }
        .tms-checkbox {
            line-height: 28px;
        }
    }
}
</style>
