<template>
    <div class="attribute-list">
        <!-- Attribute List table -->
        <table class="table full-width">
            <thead>
                <tr>
                    <th scope="col">
                        <TmsColumnHeader
                            :column-settings="colSettings"
                            column-name="attribute_id"
                            @column-change="columnChange"
                            >Id</TmsColumnHeader
                        >
                    </th>
                    <th scope="col">
                        <TmsColumnHeader :column-settings="colSettings" column-name="name" @column-change="columnChange"
                            >Name</TmsColumnHeader
                        >
                    </th>
                    <th scope="col">
                        <TmsColumnHeader
                            :column-settings="colSettings"
                            column-name="description"
                            @column-change="columnChange"
                            >Description</TmsColumnHeader
                        >
                    </th>
                    <th scope="col">
                        <TmsColumnHeader
                            :column-settings="colSettings"
                            column-name="partner_foreign_key"
                            @column-change="columnChange"
                            >Partner Key</TmsColumnHeader
                        >
                    </th>
                    <th scope="col">
                        <TmsColumnHeader
                            :column-settings="colSettings"
                            column-name="visible"
                            @column-change="columnChange"
                            >Visible</TmsColumnHeader
                        >
                    </th>
                    <th scope="col" class="align-right">
                        <TmsColumnHeader
                            :column-settings="colSettings"
                            column-name="device_count"
                            @column-change="columnChange"
                            >Device Count</TmsColumnHeader
                        >
                    </th>
                    <th scope="col">
                        <TmsColumnHeader
                            :column-settings="colSettings"
                            column-name="orgs_shared_with"
                            @column-change="columnChange"
                            >Shared With</TmsColumnHeader
                        >
                    </th>
                    <th scope="col">Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(attribute, index) in attributeList" :key="index">
                    <td>
                        {{ attribute.attribute_id }}
                    </td>
                    <td class="name">
                        <div v-if="attribute.editAttribute">
                            <textarea
                                v-model="attribute.editAttribute.name"
                                type="text"
                                class="form-control"
                                placeholder="Name"
                                rows="4"
                                cols="30"
                            />
                        </div>
                        <div v-if="!attribute.editAttribute">
                            {{ attribute.name }}
                        </div>
                    </td>
                    <td class="description">
                        <div v-if="attribute.editAttribute">
                            <textarea
                                v-model="attribute.editAttribute.description"
                                type="text"
                                class="form-control"
                                placeholder="Description"
                                rows="4"
                                cols="50"
                            />
                        </div>
                        <div v-if="!attribute.editAttribute">
                            {{ attribute.description }}
                        </div>
                    </td>
                    <td class="partner-key">
                        <div v-if="attribute.editAttribute">
                            <input
                                v-model="attribute.editAttribute.partner_foreign_key"
                                type="text"
                                class="form-control"
                                placeholder="Partner Key"
                            />
                        </div>
                        <div v-if="!attribute.editAttribute">
                            {{ attribute.partner_foreign_key }}
                        </div>
                    </td>
                    <td>
                        <div v-if="attribute.editAttribute">
                            <TmsCheckbox v-model="attribute.editAttribute.visible" />
                        </div>
                        <div v-if="!attribute.editAttribute">
                            <TmsCheckbox v-model="attribute.visible" :disabled="true" />
                        </div>
                    </td>
                    <td class="device-count">
                        {{ formattedNumber(attribute.device_count) }}
                    </td>
                    <td class="shared-with">
                        <div v-if="attribute.visibility_whitelist.length > 0">
                            {{ mapOrgName(attribute) }}
                        </div>
                        <div v-if="!attribute.visibility_whitelist.length">N/A</div>
                    </td>
                    <td class="actions">
                        <TmsButton v-if="!attribute.editAttribute" name="edit" @click="editAttribute(attribute)">
                            <TmsIcon name="fthr-edit" />
                        </TmsButton>
                        <TmsButton
                            v-if="attribute.editAttribute"
                            name="update"
                            class="primary"
                            @click="updateAttribute(attribute.editAttribute)"
                        >
                            <TmsIcon name="fthr-check" />
                        </TmsButton>
                        <TmsButton v-if="attribute.editAttribute" class="tertiary" name="cancel">
                            <TmsIcon name="fthr-x" @click="editCancel(attribute)" />
                        </TmsButton>
                        <TmsButton class="tertiary" name="share">
                            <TmsIcon name="fthr-share-2" @click="openShareModal(attribute)" />
                        </TmsButton>
                    </td>
                </tr>
            </tbody>
        </table>
        <TmsPagination
            :current-page="currentPage"
            :total-items="attributes.length"
            :items-per-page="itemsPerPage"
            :show-items-per-page="showItemsPerPage"
            @current-page-change="currentPageChange"
            @page-size-change="pageSizeChange"
        />
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import { IconRegistry, TmsIcon } from '@pushspring/common-ui/icons-core';
import { fthrCheck, fthrEdit, fthrShare2, fthrX } from '@pushspring/common-ui/icons-feather';
import { formattedNumber } from '@pushspring/tms-client-common';

import ShareAttributeModal from '../components/ShareAttributeModal.vue';

IconRegistry.add(fthrCheck, fthrEdit, fthrShare2, fthrX);

export default defineComponent({
    name: 'AttributeList',
    components: {
        TmsIcon,
    },
    props: {
        /**
         * @type {{new (): Attribute[]}}
         */
        attributes: {
            type: Array,
            required: true,
        },
        /**
         * @type {{new (): Share[]}}
         */
        shares: {
            type: Array,
            required: true,
        },
        /**
         * @type {{{ column: string; order: string; }}
         */
        colSettings: {
            type: Object,
            required: true,
        },
    },
    emits: ['sort-changed', 'edit-attribute', 'edit-cancel', 'update-attribute'],
    data() {
        return {
            offset: 0,
            itemsPerPage: 25,
        };
    },

    computed: {
        currentPage() {
            return Math.ceil(this.offset / this.itemsPerPage) + 1;
        },

        showItemsPerPage() {
            return this.attributes.length > 24;
        },
        /** @returns { Attribute[] } */
        attributeList() {
            const start = this.currentPage * this.itemsPerPage - this.itemsPerPage;
            const end = this.currentPage * this.itemsPerPage;
            const filteredAttributes = this.attributes.filter((item, index) => index >= start && index <= end);
            return filteredAttributes;
        },
    },
    methods: {
        /**
         * @param {{ order: string; column: string; }} e
         */
        columnChange(e) {
            this.$emit('sort-changed', e);
        },
        /**
         * @param {{ attribute: object; }} e
         */
        editAttribute(e) {
            this.$emit('edit-attribute', e);
        },
        /**
         * @param {{ attribute: object; }} e
         */
        editCancel(e) {
            this.$emit('edit-cancel', e);
        },
        /**
         * @param {{ attribute: object; }} e
         */
        updateAttribute(e) {
            this.$emit('update-attribute', e);
        },
        //set offset
        currentPageChange({ currentPage }) {
            this.offset = (currentPage - 1) * this.itemsPerPage;
        },
        //set limit when page size change
        pageSizeChange({ itemsPerPage }) {
            this.itemsPerPage = itemsPerPage;
        },
        //when click on share modal
        async openShareModal(attribute) {
            const params = {
                shares: this.shares,
                attribute: attribute,
            };
            //open share modal with params
            const response = await this.tmsShowModal(ShareAttributeModal, params);
            //if selected new shares or edited current shares then update attribute
            if (response) {
                attribute.visibility_whitelist = response.visibility_whitelist;
                attribute.editAttribute = { ...attribute };
                this.updateAttribute(attribute.editAttribute);
            }
        },

        //mapping org share names
        mapOrgName(attribute) {
            const org_shares_with = this.shares
                .filter((share) => attribute.visibility_whitelist.includes(share.org_id))
                .map((obj) => obj.company_name);
            if (org_shares_with.length > 0) {
                attribute.orgs_shared_with = org_shares_with;
                return org_shares_with.join(',');
            } else {
                attribute.orgs_shared_with = 'N/A';
                return '';
            }
        },
        formattedNumber,
    },
});
</script>
<style lang="scss" scoped>
.attribute-list {
    thead,
    tbody,
    tr,
    td,
    tr {
        vertical-align: middle;
    }

    input {
        border: 1px solid #ccc;
        border-radius: 4px;
        color: #555;
        padding: 4px 6px;
        height: 29px;
        width: 100%;
    }

    .device-count {
        text-align: right;
    }

    .align-right {
        width: 100px;
        min-width: 100px !important;
        text-align: right;
    }

    .name,
    .description {
        width: 28%;
    }

    .tms-button {
        margin-right: 8px;
    }

    .actions {
        white-space: nowrap;
        display: table-cell;
        min-width: 95px;
        text-align: right;
        margin-left: 7px;
        svg {
            width: 1.5em;
            height: 1.5em;
        }
    }

    .tms-checkbox {
        display: inline-block;
    }
}
</style>
