import axios from 'axios';

import { ConfigService } from '../../utils/ConfigService';

const clientConfig = new ConfigService();

export async function listImports() {
    try {
        const { data } = await axios.get(clientConfig.eventotronPrefix + '/v1/marketplace/import');
        return data;
    } catch (e) {
        throw new Error('There was an error getting the imports.');
    }
}

export async function createImport(log) {
    try {
        const { data } = await axios.post(clientConfig.eventotronPrefix + '/v1/marketplace/import', log);
        return data;
    } catch (e) {
        throw new Error('There was an error creating new import.');
    }
}
