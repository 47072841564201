import type { App, Plugin } from 'vue';

import { useAssetServer } from './utils/assetUtils';

// this makes it so the $tccUseAssetServer is available on this and in templates
declare module 'vue' {
    interface ComponentCustomProperties {
        $tccUseAssetServer: (localUrl: string) => string;
    }
}

/**
 * Vue plugin to handle library options and shared utility functions that need access to the current vue instance (app).
 *
 * DO NOT add all functions here only functions that absolutely need access to the vue instance, or methods that are
 * more globally used across most components. Every addition creates additional dependencies at the global level.
 * Methods used by only some components should import the method directly into the component.
 */
export const ClientCommonPlugin: Plugin = {
    /**
     * Install the ClientCommonPlugin to use the global functions

     */
    install: (app: App) => {
        // tcc = _t_ms-_c_lient-_c_ommon
        /**
         * Returns the asset path prepended by the asset server
         * @name $tccUseAssetServer
         * @function
         * @requires clientConfig.assetServer
         */
        app.config.globalProperties.$tccUseAssetServer = useAssetServer;
    },
};
