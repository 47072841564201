import axios from 'axios';

export async function sendContactUsEmail(body) {
    try {
        const response = await axios.post('/api/contact-us', { body });
        return response.data;
    } catch (e) {
        console.error('Error sending contact us emails.', e);
        throw e;
    }
}
