<!--
    Shows a checkbox with label, infoBubble and TMS specific styling.
-->
<template>
    <div class="tms-checkbox" :class="$attrs.class">
        <div class="form-check p-0 d-flex align-items-center">
            <input
                v-bind="$attrs"
                :id="id"
                v-model="proxyChecked"
                class="form-check-input m-0"
                type="checkbox"
                @click="$emit('click', $event)"
            />
            <label v-if="label || $slots.default" class="form-check-label ms-2" :for="id"
                ><slot>{{ label }}</slot></label
            ><TmsInfoBubble :popup-content="helpContent" class="ms-1" />
        </div>
    </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';

import { defineComponent } from 'vue';

import TmsInfoBubble from '../TmsInfoBubble/TmsInfoBubble.vue';

type CheckboxValueType = boolean | unknown[];

let numCheckBoxes = 0;

export default defineComponent({
    name: 'TmsCheckbox',
    components: { TmsInfoBubble },
    inheritAttrs: false,
    props: {
        id: {
            type: String,
            default: function () {
                return `checkbox_${numCheckBoxes++}`;
            },
        },
        modelValue: {
            type: [Array, Boolean] as PropType<CheckboxValueType>,
            default: false,
        },
        label: {
            type: String,
            default: null,
        },
        helpContent: {
            type: String,
            default: null,
        },
    },
    // click should be deprecates as it is being used incorrectly. Consumers should use the v-model
    // or we should convert to a `change` event
    emits: ['update:modelValue', 'change', 'click'],
    computed: {
        proxyChecked: {
            get(): CheckboxValueType {
                return this.modelValue;
            },
            set(val: CheckboxValueType) {
                this.$emit('update:modelValue', val);
                // change is for backwards compatibility
                this.$emit('change', val);
            },
        },
    },
});
</script>
