import type {
    CompleteMultipartUploadResponse,
    CreateMultipartUploadResponse,
    CreateUploadResponse,
    ListPartsResponse,
    SignPartResponse,
} from '@pushspring/s3-upload/utils';
import type { AwsS3UploadParameters } from '@uppy/aws-s3';
import type { UppyFile } from '@uppy/core';

import axios from 'axios';

// defining our own part that matches Uppy Part so we are not using their deprecated library
export interface Part {
    PartNumber?: number;
    Size?: number;
    ETag?: string;
}

export async function getUploadParameters(
    file: UppyFile & { key?: string },
    { signal }: { signal?: AbortSignal },
): Promise<AwsS3UploadParameters> {
    const metadata = getMetadata(file);
    const { data } = await axios.post<CreateUploadResponse>(
        '/api/s3upload',
        { fileName: file.name, type: file.type, metadata },
        { signal },
    );

    // since uppy doesn't seem to expose the key in single part uploads we are mutating the file object
    // to include the key so we can use it later.
    file.key = data.key;

    return {
        method: data.method,
        url: data.url,
        headers: {
            'Content-Type': file.type as string,
        },
    };
}

// S3 Multiparts Upload
// initiate a multipart upload

export async function createMultipartUpload(
    file: UppyFile,
    signal?: AbortSignal,
): Promise<{ uploadId: string; key: string }> {
    try {
        const metadata: { [key: string]: unknown } = getMetadata(file);
        const { data } = await axios.post<CreateMultipartUploadResponse>(
            '/api/s3upload/multipart',
            { fileName: file.name, type: file.type, metadata: metadata },
            { signal },
        );
        return { uploadId: data.uploadId, key: data.key };
    } catch (e) {
        throw new Error('There was an error creating multipart upload.');
    }
}

function getMetadata(file: UppyFile) {
    const metadata: { [key: string]: unknown } = {};

    Object.keys(file.meta || {}).forEach((key) => {
        if (file.meta[key] !== null) {
            metadata[key] = (file.meta[key] as string | number | boolean).toString();
        }
    });
    return metadata;
}

export async function abortMultipartUpload(
    _file: UppyFile,
    { key, uploadId, signal }: { key: string; uploadId: string; signal: AbortSignal },
) {
    const filename = encodeURIComponent(key);
    const uploadIdEnc = encodeURIComponent(uploadId);

    try {
        await axios.delete(`/api/s3upload/multipart/${uploadIdEnc}?key=${filename}`, {
            signal,
        });
    } catch (e) {
        throw new Error('There was an error aborting multipart upload.');
    }
}

export async function signPart(
    _file: UppyFile,
    { key, uploadId, partNumber, signal }: { key: string; uploadId: string; partNumber: number; signal: AbortSignal },
): Promise<SignPartResponse> {
    if (signal?.aborted) {
        const err = new DOMException('The operation was aborted', 'AbortError');
        Object.defineProperty(err, 'cause', {
            configurable: true,
            writable: true,
            value: signal.reason as string,
        });
        throw err;
    }

    if (uploadId == null || key == null || partNumber == null) {
        throw new Error('Cannot sign without a key, an uploadId, and a partNumber');
    }

    const filename = encodeURIComponent(key);
    try {
        const { data } = await axios.get<SignPartResponse>(
            `/api/s3upload/multipart/${uploadId}/${partNumber}?key=${filename}`,
            { signal },
        );
        return data;
    } catch (e) {
        throw new Error('There was an error signing the specified part number.');
    }
}

export async function listParts(
    _file: UppyFile,
    { key, uploadId, signal }: { key: string; uploadId: string; signal: AbortSignal },
): Promise<ListPartsResponse> {
    if (signal?.aborted) {
        throw new DOMException('The operation was aborted', 'AbortError');
    }

    const filename = encodeURIComponent(key);
    try {
        const { data } = await axios.get<ListPartsResponse>(`/api/s3upload/multipart/${uploadId}?key=${filename}`, {
            signal,
        });
        return data;
    } catch (e) {
        throw new Error('There was an error listing the parts.');
    }
}

// complete a multipart upload, combining all parts into a single object in S3 bucket
export async function completeMultipartUpload(
    _file: UppyFile,
    { key, uploadId, parts, signal }: { key: string; uploadId: string; parts: Part[]; signal: AbortSignal },
): Promise<{ location: string }> {
    if (signal?.aborted) {
        const err = new DOMException('The operation was aborted', 'AbortError');
        Object.defineProperty(err, 'cause', {
            configurable: true,
            writable: true,
            value: signal.reason as string,
        });
        throw err;
    }
    try {
        const { data } = await axios.post<CompleteMultipartUploadResponse>(
            `/api/s3upload/multipart/${uploadId}/complete`,
            {
                key,
                parts,
            },
        );
        return data;
    } catch (e) {
        throw new Error('There was an error completing multipart upload.');
    }
}
