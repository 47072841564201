/**
 *  A collection of display formatting functions (previously known as filters).
 *
 * This file should only contains pure functions and should not have depend on
 * the global scope or alter the global scope. If there is a method that depends
 * on the global or instance prototype setup in an external function
 *
 * Filters have been removed in VueJS 3 so we are not going to use them in
 * VueJS 2.
 */

/**
 *
 * @param {string|number} labelValue
 * @param {number} [places]
 * @returns {string}
 */
export function number(labelValue, places) {
    const val = parseFloat('' + labelValue);
    if (val === 0) {
        return '' + val;
    }

    return val.toFixed(places || 0);
}

/**
 * given a number it will return the number as a string with the abbreviation '', K, M, B, T to indicate the scale of
 * the number. The number will always include all significant digits left of the left most comma. An optional places
 * parameter can be sent to include additional places.
 * @param {string|number} labelValue value to shorten
 * @param {number} places number of places after the decimal
 * @returns {string}
 */
export function shortenNumber(labelValue = 0, places = 0) {
    // wrapped new function to provide a place to try and match old functionality
    const val = parseInt('' + labelValue);
    if (val === 0) {
        return '' + val;
    }

    // shift places by 1 to match original functionality
    places = places + 1;
    const { num, abrv } = getShortNumberAndAbbreviation(val, places);
    return num + abrv;
}

/**
 * given a number it will return an object with the shortened number as a string and an abbreviation '', K, M, B, T
 * to indicate the scale of the number. The number will always include all significant digits left of the left most
 * comma. An optional places parameter can be sent to include additional places.
 * @param {number} val
 * @param {number} places number of places to return
 * @returns an object with the short number  and the suffix
 */
function getShortNumberAndAbbreviation(val = 0, places = 1) {
    const suffixes = ['', 'K', 'M', 'B', 'T'];
    // get the number of digits
    let numberOfDigits = getNumberOfDigits(val);
    // number of digits after leftmost comma
    const digitsLefOfComma = numberOfDigits > 3 ? numberOfDigits % 3 || 3 : numberOfDigits;
    // always include all the digits left of the left most comma or decimal if digits greater
    const sigDigits = digitsLefOfComma > places ? digitsLefOfComma : places;
    // digits to include after the decimal
    const fractionDigits = sigDigits - digitsLefOfComma;
    // get a number with the correct number of significant digits
    const sigVal = Number(val.toPrecision(sigDigits));
    // based on precision, sigVal may have been rounded to the next suffix
    // recalculate numberOfDigits
    numberOfDigits = getNumberOfDigits(sigVal);
    // get suffixIdx based on number of digits
    const suffixIdx = Math.floor((numberOfDigits - 1) / 3);

    const shortVal = sigVal / Math.pow(10, suffixIdx * 3);
    return { num: shortVal.toFixed(fractionDigits), abrv: suffixes[suffixIdx] };
}

/**
 * gets the number of integer digits in a number value
 * @param {number} val integer value
 * @returns {number} the number of digits in the value
 */
function getNumberOfDigits(val) {
    return Math.max(Math.floor(Math.log10(Math.abs(val))), 0) + 1;
}

export function percentage(labelValue, places) {
    return number(labelValue * 100, places) + '%';
}

/**
 * Formats number represented as a number or string to a en-US formatted string.
 * If called with an undefined value it will be interpreted as 0
 * @param {string|number} [n=0]
 * @returns {string} number converted to number with commas
 */
export function formattedNumber(n = 0) {
    const enUS = new Intl.NumberFormat('en-US', { maximumFractionDigits: 4 });
    const temp = typeof n === 'string' ? Number(n.replace(',', '')) : n;
    return isNaN(temp) ? '' + n : enUS.format(temp);
}

export function currency(amount, symbol, places) {
    symbol = symbol ?? '$';
    places = places ?? 2;
    return `${symbol}${number(amount, places)}`;
}
