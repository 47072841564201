/**
 * This is a replacement for psClientConfig, which was essentially just an object to store
 * client configuration that was injected into AngularJS components by name.
 */

// importing commonConfig as clientConfig for clarity and so that we can assign values onto it and then export it as
// clientConfig
import { commonConfig as clientConfig } from '@pushspring/tms-client-common';

const appConfig = {
    appName: 'Magenta Partner',
};

Object.assign(clientConfig, appConfig);

export default clientConfig;
