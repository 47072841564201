<template>
    <div class="tms-column-header d-flex align-items-center" :class="{ active: isActive }">
        <span class="toggle-sort d-flex align-items-center" @click="toggleColumn"
            ><slot>{{ displayName }}</slot
            ><span v-if="columnSettings.column === columnName" class="ms-1"
                ><TmsIcon
                    name="fthr-chevron-down"
                    :title="columnSettings.order"
                    class="chevron"
                    :class="columnSettings.order"
                    border="0" /></span
        ></span>
        <div>
            <TmsInfoBubble :popup-content="popupContent" :popup-placement="popupPlacement" class="ms-1" />
        </div>
    </div>
</template>

<script setup lang="ts">
import type { ColumnSettings } from './ColumnSettings';

import { computed } from 'vue';

import { IconRegistry, TmsIcon } from '@pushspring/common-ui/icons-core';
import { fthrChevronDown } from '@pushspring/common-ui/icons-feather';

import TmsInfoBubble from '../TmsInfoBubble/TmsInfoBubble.vue';

IconRegistry.add(fthrChevronDown);

const props = withDefaults(
    defineProps<{
        columnSettings?: ColumnSettings;
        displayName?: string;
        popupPlacement?: string;
        popupContent?: string;
        columnName: string;
    }>(),
    {
        columnSettings: () => ({ order: 'asc', column: '' }),
        displayName: '',
        popupPlacement: 'top',
        popupContent: '',
    },
);

const emit = defineEmits<{
    'column-change': [event: ColumnSettings];
}>();

const isActive = computed(() => props.columnSettings.column === props.columnName);
const toggleColumn = () => {
    emit('column-change', {
        order: props.columnSettings.order === 'asc' ? 'desc' : 'asc',
        column: props.columnName,
    });
};
</script>

<style lang="scss" scoped>
.tms-column-header {
    .feather {
        // to be backward compatible forcing feather vertical-align here
        // it normally wouldn't be needed but vertical alignment is being
        // set for all feather classes by the app
        vertical-align: middle;
    }
    .toggle-sort {
        cursor: pointer;
        .chevron {
            height: 1em;
            width: 1em;
            &.asc {
                // flips down arrow for ascending
                transform: scaleY(-1);
            }
            &.desc {
                // adding for code clarity, not really needed as this is the default
                transform: scaleY(1);
            }
        }
    }
}
</style>
