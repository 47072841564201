/**
 * common configuration properties and their default values. The library should not reference a property from the
 * common config that is not listed in the object below.
 *
 * the configuration should be imported by the webapp and assign additional or override existing values into the same
 * instance.
 *
 * The library currently only uses the properties listed below.
 */
interface CommonConfig {
    assetServer?: string;
    [key: string]: unknown;
}

export const commonConfig: CommonConfig = {
    assetServer: '',
};
